.tippy-box[data-theme~='NKI'] {
    // background: #FFF3E8;
    // color: #121212;
    background: #493B55;
    color: #fff;
    padding: 28px 60px;
    width: 100vw;
    max-width: 565px!important;
    font-family: $font-light;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.24px;
    border-radius: 0;
    @media (max-width: 992px) {
        padding: 56px 24px 24px 24px;
        font-size: 13px;
        line-height: 20px;
    }
    @media (max-width: 575px) {
        width: calc(100vw - 58px);
        margin-left: calc(1vw + 20px);
    }
    .tippy-content{
        padding: 0;

        a {
            color: #ffffff;
            text-underline-offset: 3px;
        }
    }
    .tippy-arrow {
        width: 26px;
        height: 23px;
        @media (max-width: 992px) {
            font-size: 13px;
            line-height: 20px;
        }
    }
    &[data-placement^='top'] > .tippy-arrow::before {
        color: #493B55;
        bottom: -23px;
        border-width: 23px 13px 0;
        @media (max-width: 992px) {
            bottom: -16px;
        }
        @media (max-width: 575px) {
            transform: translateX(-12px);
        }
    }

    &[data-placement^='bottom'] > .tippy-arrow::before {
        color: #493B55;
        top: -23px;
        border-width: 0 13px 23px;
        @media (max-width: 992px) {
            top: -16px;
        }
        @media (max-width: 575px) {
            transform: translateX(-12px);
        }
    }
    .closeTooltip{
        width: 16px;
        height: 16px;
        position: absolute!important;
        top: -6px;
        right: -36px;
        display: block;
        @include background-image("./icons/close-tooltip-new.svg", 16, 16);
        cursor: pointer;
        @media (max-width: 992px) {
            top: -30px;
            right: 7px;
        }
    }
    &[data-placement="bottom"]{
        @media (max-width: 992px) {
            margin-top: -10px;
        }
    }
    &[data-placement="top"]{
        @media (max-width: 992px) {
            margin-bottom: -10px;
        }
    }
}

.helpTooltip{
    cursor: pointer;
}
