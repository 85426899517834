.employee-cards {
    margin-bottom: 120px;
    @media(max-width: 991px) {
        margin-bottom: 60px;
    }
    .item{
        margin-bottom: 80px;
        @media(max-width: 991px) {
            margin-bottom: 40px;
        }
        .img {
            margin-bottom: 24px;
            padding-top: calc((470 / 360) * 100%);
            @media(max-width: 991px) {
                margin-bottom: 15px;
            }
        }
        .name {
            word-break: break-word;
            font-family: $font-medium;
            font-size: 21px;
            line-height: 36px;
            color: $brandPurple;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            display: -webkit-box;
            display: -moz-box;
            @media(max-width: 991px) {
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 4px;
            }
        }
        .work {
            word-break: break-word;
            font-family: $font-light;
            font-size: 21px;
            line-height: 36px;
            color: $brandPurple;
            opacity: 0.8;
            margin-bottom: 12px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            display: -webkit-box;
            display: -moz-box;
            @media(max-width: 991px) {
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 8px;
            }
        }

        .email,
        .tel{
            word-break: break-word;
            a{
                font-family: $font-light;
                font-size: 21px;
                line-height: 36px;
                letter-spacing: 0.24px;
                color: #121212;
                text-decoration: none;
                @media(max-width: 991px) {
                    font-size: 13px;
                    line-height: 20px;
                }
            }
        }
        .tel{
            @media(max-width: 991px) {
                margin-top: 4px;
            }
        }

    }
}
