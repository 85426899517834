.blogPostPageHeader{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        display: block;
        background-color: $grey;
        height: 1px;
        width: calc(100% - 120px);
        top: -35px;
        left: 60px;
        @media(max-width: 992px) {
            display: none;
        }

    }
    &__category {
        font-family: $font-medium;
        color: $orange;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        margin-top: 89px;
        @media(max-width: 992px) {
            display: none;
            // font-size: 15px;
            // line-height: 24px;
        }
    }
    &__title{
        h1{
            font-family: $font-regular;
            // font-size: 124px;
            // line-height: 128px;
            text-align: center;
            // letter-spacing: -2.5px;
            color: $brandPurple;
            // padding-top: 60px;
            // margin-bottom: 80px;

            font-size: 68px;
            line-height: 88px;
            letter-spacing: -2.24px;
            padding-top: 28px;
            margin-bottom: 88px;

            @media (max-width:1680px) {
                font-size: 4.0516vw;
                line-height: 5.24333vw;
            }
            @media (max-width: 992px) {
                font-family: $font-book;
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -1px;
                margin-bottom: 40px;
                padding-top: 25px;
                letter-spacing: -1.25px;
            }
        }
    }
    &__image{
        margin-bottom: 80px;
        @media (max-width: 992px) {
            margin-bottom: 40px;
        }
        img{
            width: 100%;
        }
    }
    &__ingress{
        margin-bottom: 60px;
        @media (max-width: 992px) {
            margin-bottom: 40px;
        }
        h2{
            font-family: $font-light;
            font-weight: 300;
            font-size: 25px;
            line-height: 40px;
            letter-spacing: 0.24px;
            color: #121212;
            @media (max-width: 992px) {
                font-size: 19px;
                line-height: 32px;
            }
        }
    }
}
