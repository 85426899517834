.statementCarousel{
    width: 100%;
    margin-bottom: 200px;
    height: 100px;
    @media(max-width: 992px){
        margin-bottom: 88px;
        height: 46px;
    }
    *{
        outline: none;
    }
    &__wrapper{
        &--carousel{
            .slick-list{
                overflow: visible;
            }
            .item{
                position: relative;
                padding-right: 60px;
                padding-left: 60px;
                display: inline-block;
                @media (max-width:1680px) {
                    padding-right: 48px;
                    padding-left: 48px;
                }
                @media (max-width: 992px){
                    padding-right: 30px;
                    padding-left: 30px;
                }
                a,span{
                    font-family: $font-sporting-grotesque-regular;
                    font-size: 60px;
                    line-height: 99px;
                    letter-spacing: -1.88px;
                    color: $brandPurple;
                    display: inline-block;
                    text-decoration: none;
                    @media (max-width:1680px) {
                        font-size: 48px;
                        line-height: 79.3px;
                        letter-spacing: -2.12px;
                    }
                    @media (max-width: 992px){
                        font-size: 28px;
                        line-height: 46px;
                        letter-spacing: -0.6px;
                    }
                }
                &:before{
                    @include background-image("./icons/point.svg", 8, 8);
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -4px;
                    @media (max-width: 992px){
                        @include background-image("./icons/point.svg", 4, 4);
                        margin-top: -2px;
                    }
                }
                .imgWrapper{
                    width: 400px;
                    height: auto;
                    max-width: 400px;
                    position: absolute;
                    top: 50%;
                    left: 36%;
                    transform: translate(-10%, -50%);
                    z-index: -1;
                    transition: all 300ms;
                    opacity: 0;
                    pointer-events: none;
                }
                &:hover{
                    z-index: 2;
                    a,span{
                        color: $brandRed;
                    }
                    // &:before{
                    //     @include background-image("./icons/point-light.svg", 8, 8);
                    //     @media (max-width: 992px){
                    //         @include background-image("./icons/point-light.svg", 4, 4);
                    //         margin-top: -2px;
                    //     }
                    // }
                    // .imgWrapper{
                    //     opacity: 1;
                    //     transform: translate(0, -50%);
                    // }

                    @media(max-width: 992px){
                        a,span{
                            color: $brandPurple;
                        }
                        // &:before{
                        //     @include background-image("./icons/point.svg", 8, 8);
                        //     @media (max-width: 992px){
                        //         @include background-image("./icons/point.svg", 4, 4);
                        //         margin-top: -2px;
                        //     }
                        // }
                        .imgWrapper{
                            display: none;
                        }
                    }

                }
            }
        }
    }
}
