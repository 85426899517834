header{
    color: $purpleDark;
    position: relative;
    z-index: 3;
    // margin-bottom: 35px;
    @media (max-width: 992px){
      margin-bottom: 0;
    }

    .header__wrapper{
      display: flex;
      justify-content: flex-end;
      height: 149px;
      width: 100%;
      position: relative;
      &.transparent{
        background: transparent;
      }

      @media (max-width: 992px){
        height: 104px;
      }

      &--logo{
        position: absolute;
        top: 40px;
        left: 0;
        img{
          width: 74px;
          height: auto;
          @media (max-width: 992px) {
            width: 54px;
          }
        }
      }


      .main__menu{
        display: flex;
        align-items: center;

        &--desktop{
          display: block;
          @media (max-width: 992px){
            display: none;
          }
          .item{
            position: relative;
            color: $purpleDark;
            font-family: $font-regular;
            font-size: 21px;
            line-height: 100%;
            font-weight: 500;
            margin-left: 44px;
            text-decoration: none;
            display: inline-block;
            &.dropDown{
              padding-right: 26px;
              &::after{
                @include background-image("./icons/arrow-down-menu.svg", 16, 17);
                position: absolute;
                right: 0;
                top: 1px;
              }
            }

            &.shoppingCart{
              padding-right: 38px;
              &::after{
                @include background-image("./icons/shopping-bag.svg", 18, 22);
                position: absolute;
                right: 6px;
                top: 0px;
              }
              .shoppingCart__count{
                background: $purpleDark;
                color: #fff;
                position: absolute;
                left: calc(100% - 9px);
                top: -15px;
                z-index: 1;
                min-width: 17px;
                height: 17px;
                border-radius: 20px;
                font-size: 11px;
                line-height: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 5px;
                font-weight: 600;
                text-align: center;
              }
            }

            &.search{
              padding-right: 38px;
              &::after{
                @include background-image("./icons/search-icon-2.svg", 22, 22);
                position: absolute;
                right: 6px;
                top: 0px;
              }
            }

            &:before{
              transition: all 150ms;
              opacity: 0;
              content: "";
              width: 100%;
              height: 2px;
              background: $purpleDark;
              position: absolute;
              left: 0;
              bottom: -5px;
            }

            &:hover{
              &:before{
                opacity: 1;
              }
            }

          }

        }

        &--mobile{
          display: none;
          @media (max-width: 992px){
            display: block;
            margin-top: 20px;
          }

          .item{
            position: relative;
            margin-left: 28px;
            text-decoration: none;
            color: $purpleDark;

            &.shoppingCart{
              @include background-image("./icons/shopping-bag.svg", 18, 22);
              position: relative;

              .shoppingCart__count{
                background: $purpleDark;
                color: #fff;
                position: absolute;
                left: calc(100% - 5px);
                top: -15px;
                z-index: 1;
                min-width: 17px;
                height: 17px;
                border-radius: 20px;
                font-size: 11px;
                line-height: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 5px;
                font-weight: 600;
                text-align: center;
              }

            }

            &.search{
              @include background-image("./icons/search-icon-2.svg", 22, 22);
            }

            &.menu-hamburguer{
              @include background-image("./icons/menu-hamburguer.svg", 22, 18);
            }

          }

        }
      }
    }
    &.header--steps {
      margin-bottom: 0;
      .header__wrapper {
        height: 128px;
        justify-content: center;
        @media (max-width: 1200px){
          justify-content: flex-end;
        }
        @media (max-width: 992px){
          display: block;
          height: auto;
        }
        &--logo {
          @media (max-width: 992px){
            position: static;
            img {
              padding: 40px 0;
            }
          }
        }
      }

      .back-step-mobile{
        display: none;
        position: absolute;
        top: 50px;
        right: 0;
        @media (max-width: 992px){
          display: block;
        }
        a{
          text-decoration: none;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 18px;
          color: $purpleDark;
          position: relative;
          display: inline-block;
          padding-left: 18px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMSAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNDUyOTQxIDUuNDM3NUwtMi4xODU1N2UtMDcgNUwwLjQ1Mjk0MSA0LjU2MjVMNS4wNDcwNiAtMi42MDIxMWUtMDdMNS45NTI5NCAwLjkzNzVMMi40NTg4MiA0LjM3NUwxMSA0LjM3NUwxMSA1LjYyNUwyLjQ1ODgyIDUuNjI1TDUuOTUyOTQgOS4wNjI1TDUuMDQ3MDYgMTBMMC40NTI5NDEgNS40Mzc1WiIgZmlsbD0iIzEyMTIxMiIvPgo8L3N2Zz4K");
          background-repeat: no-repeat;
          background-size: 11px;
          background-position: left center;
        }

      }

      .main__menu {
        @media (max-width: 992px) {
          // flex-direction: column;
          // align-items: flex-end;
          // margin-bottom: 20px;
          flex-wrap: wrap;
        }
        .item {
            position: relative;
            color: $purpleDark;
            font-family: $font-regular;
            font-size: 21px;
            line-height: 40px;
            letter-spacing: 0.24px;
            margin-left: 40px;
            text-decoration: none;

            @media (max-width: 1200px) {
              font-size: 18px;
              line-height: 36px;
            }

            @media (max-width: 992px) {
              margin-left: 0;
              margin-right: 10px;
              font-size: 12px;
              line-height: 22px;
              margin-bottom: 5px;
            }

          &.done {
            color: $darkGreen;
            @media (max-width: 992px) {
              color: #473B54;
            }
          }
          &.active {
            border-bottom: 2px solid $link-blue;
            color: $purpleDark;
            @media (max-width: 992px) {
              color: $purpleDark;
              font-weight: 700;
            }
          }
          &.disabled {
            opacity: 0.3;
            @media (max-width: 992px) {
              opacity: 1;
            }
          }
        }
      }
    }

    .dropDownMenu{
      background: $blueLight;
      background-size: 1243px 1176px;
      background-position-x: 21vw;
      background-position-y: -60px;
      position: fixed;
      width: 100vw;
      height: 100%;
      top: calc(-100% - 200px);
      overflow-x: hidden;
      transition: all 350ms;
      z-index: 2;


      @media (max-width: 992px) {
        background-size: 809px 765px;
        background-position-x: 21vw;
        background-position-y: 90px;
      }
      &.active{
        top: 0;
        .btnClose{
          @media (max-width: 992px){
            position: fixed;
          }
        }
        @media (max-width: 992px){
          .btnBack {
            display: block;
          }
        }
      }

      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        width: none;
      }
      .btnBack {
        border-radius: 0;
        height: 80px;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 3;
        display: none;
      }
      .btnClose {
        margin-left: auto;
        // position: fixed;
        z-index: 1;
        // top: -100px;
        // right: 39px;
        // transition: all 350ms;
        // @media(max-width: 992px){
        //   right: 35px;
        // }
        @media(max-width: 992px) {
            font-size: 0;
            padding: 16px;
            top: 20px;
            right: 20px;
        }
        img{
          width: 16px;
          height: auto;
          // margin-right: 12px;
          margin-bottom: 2px;
        }
        // &.active{
        //   top: 39px;
        //   @media(max-width: 992px){
        //     top: 35px;
        //   }
        // }
      }
      &__top {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        padding-top: 20px;
        margin-bottom: 50px;

        @media(max-height: 780px) and (min-width: 993px) {
          margin-bottom: 6vh;
        }
        @media(max-width: 992px) {
          padding-top: 85px;
          margin-bottom: 0;
          height: auto;
        }
        .dropDownMenu__logo{
          @media(max-width: 992px) {
            display: none;
          }
          img {
            width: 74px;
            height: auto;
          }
        }
        a {
          position: relative;
          color: $purpleDark;
          font-family: "SuisseIntl-Regular", sans-serif;
          font-size: 20px;
          line-height: 1;
          font-weight: 500;
          text-decoration: none;
          display: inline-block;
          top: unset;
        }
        .search{
          margin-left: 28px;
          padding-right: 38px;
          @media(max-width: 992px) {
            display: none;
          }
          &::after {
            background-image: url(../images/./icons/search-icon.svg);
            background-repeat: no-repeat;
            background-position: center center;
            width: 21px;
            height: 21px;
            content: "";
            display: inline-block;
            background-size: cover;
            position: absolute;
            right: 6px;
            top: 0px;
          }
        }
      }
      &__col {

        @media(max-width: 992px) {
          position: absolute;
          width: calc(100% - 40px);
          margin-top: 0;

          &.active{
            display: block;
          }
        }

        @media(min-height: 900px) {
          margin-top: 50px;
        }

      }
      &__desktop{
        z-index: 0;
        display: block;

        .item--more {
          padding-right: 50px;
          span{
            position: relative;
            &:after{
              opacity: 0;
              @include background-image("./icons/link-menu-arrow.svg", 30, 20);
              position: absolute;
              right: -46px;
              top: 50%;
              margin-top: -10px;
              transform: translateX(-30px);
              pointer-events: none;
              // @media (hover: none) {
              //   opacity: 1;
              //   transform: translateX(0);
              // }
            }

          }
          a:hover{
            span:after{
              opacity: 1;
              transition: all 0.1s;
              @media (min-width: 993px){
                transform: translateX(0);
              }
            }
          }
          @media (hover: none) {
            a {
              span:after{
                opacity: 1;
                transition: all 0.1s;
                @media (min-width: 993px){
                  transform: translateX(0);
                }
              }
            }

          }
        }
        &--itemsLine {
          display: none;
        }
        &--items{
          .item{
            margin-bottom: 32px;
            @media(max-height: 780px) and (min-width: 993px){
              margin-bottom: 3.6vh;
            }
            a{
              font-family: $font-book;
              text-decoration: none;
              font-size: 32px;
              line-height: 44px;
              letter-spacing: -0.16px;
              color: $purpleDark;
              position: relative;
              overflow-wrap: break-word;
              word-wrap: break-word;
              -webkit-hyphens: auto;
              -moz-hyphens: auto;
              -ms-hyphens: auto;
              hyphens: auto;
              border-bottom: 2px solid transparent;
              @media (max-width:1680px) {
                font-size: 1.9062vw;
                line-height: 2.621vw;
              }

                &:hover{
                  border-bottom: 2px solid #000000;
                }

              @media (min-width:993px) {
                &.active{
                  border-bottom: 2px solid #000000;
                  &:after{
                    border-bottom: 2px solid #000000;
                  }
                }
              }

            }
            &--sm {
              margin-bottom: 12px;
              a {
                font-size: 21px;
                line-height: 36px;
                @media (max-width:1680px) {
                  font-size: 1.251vw;
                  line-height: 2.1445vw;
                }
              }
            }
          }
        }
        &--subItems, &--sub-subItems{
          pointer-events: none;
          opacity: 0;
          transform: translateX(-100px);
          position: absolute;
          top: 0;
          left: 20;
          .thisItem,
          .item{
            margin-bottom: 22px;
            @media(max-height: 780px) and (min-width: 993px) {
              margin-bottom: 3.05vh;
            }
            a{
              font-family: $font-light;
              text-decoration: none;
              font-size: 21px;
              line-height: 36px;
              color: $purpleDark;
              position: relative;
              overflow-wrap: break-word;
              word-wrap: break-word;
              -webkit-hyphens: auto;
              -moz-hyphens: auto;
              -ms-hyphens: auto;
              hyphens: auto;
              border-bottom: 2px solid $purpleDark;
              @media(min-width: 993px) {
                &:hover, &.active{
                  border-bottom: 2px solid $purpleDark;
                }
              }

            }
          }
          &.active{
            pointer-events: all;
            transition: all 350ms;
            opacity: 1;
            transform: translateX(0);
          }
        }
        @media (max-width: 992px){
          position: relative;

          &--itemsLine{
            margin-bottom: 46px;
            display: block;
            a{
              font-family: $font-regular;
              font-size: 16px;
              line-height: 24px;
              color: $purpleDark;
              text-decoration: none;
              margin-right: 24px;
              display: inline-block;
              position: relative;
              border-bottom: 2px solid $purpleDark;

              // &:after{
              //   transition: all 150ms;
              //   content: "";
              //   width: 100%;
              //   height: 2px;
              //   background: #F68D6F;
              //   opacity: 0.4;
              //   position: absolute;
              //   bottom: -1px;
              //   left: 0;
              // }

              &:hover{
                border-bottom: 2px solid $purpleDark;
              }
            }
          }

          &--items{
            // background-color: aqua;
            margin-bottom: 120px;
            display: none;
            &.active {
              display: block;
            }
            .item{
              margin-bottom: 24px;
              > a{
                font-family: $font-regular;
                font-size: 22px;
                line-height: 32px;
                color: $purpleDark;
                text-decoration: none;
                position: relative;
                border-bottom: 2px solid $purpleDark;

                // &:after{
                //   transition: all 150ms;
                //   content: "";
                //   width: 100%;
                //   height: 2px;
                //   background: #F68D6F;
                //   opacity: 0.4;
                //   position: absolute;
                //   bottom: -2px;
                //   left: 0;
                // }

                span{
                  position: relative;
                }

                &:hover,
                &.active{
                  border-bottom: 2px solid $purpleDark;
                }


              }
            }
          }
          .item--more{
            padding-right: 0px;
            >a{
              span{
                &:after{
                  opacity: 1;
                  transition: all 150ms;
                  @include background-image("./icons/arrow-down-item-menu.svg", 15, 17);
                  position: absolute;
                  right: -27px;
                  top: 50%;
                  margin-top: -7.5px;
                  pointer-events: none;
                  transform: rotate(-90deg);
                }
              }
            }
          }
          &--subItems, &--sub-subItems{
            // background-color: aquamarine;
            margin-bottom: 120px;
            transform: translateX(100px);
            &.active {
              position: static;
            }
            // .thisItem{
            //   color: $textDark1;
            //   margin-bottom: 24px;
            //   font-size: 22px;
            //   line-height: 32px;
            // }
            .thisItem,
            .item{
              margin-bottom: 20px;
              a {
                font-size: 17px;
                line-height: 28px;
              }
            }
            .item--more{
              >a{
                span{
                  &:after{
                    width: 14px;
                    height: 16px;
                  }
                }
              }
            }
          }


        }
      }
      &__mobile{

        padding: 85px 0 85px 0;
        position: relative;

        display: none;
        // @media(max-width: 992px){
        //   display: block;
        // }
        // &:after{
          //   z-index: -1;
          //   content: "";
          //   @include background-image("./form-header.svg", 809, 765);
          //   position: absolute;
          //   left: 17%;
          //   top: 90px;
          // }

        &__btn-back{
          border-radius: 0;
          height: 80px;
          width: 100%;
          position: fixed;
          left: 0;
          bottom: 0;
          right: 0;
        }

        &--itemsLine{
          margin-bottom: 46px;
          a{
            font-family: $font-regular;
            font-size: 16px;
            line-height: 24px;
            color: $purpleDark;
            text-decoration: none;
            margin-right: 24px;
            display: inline-block;
            position: relative;
            border-bottom: 2px solid $purpleDark;

            // &:after{
            //   transition: all 150ms;
            //   content: "";
            //   width: 100%;
            //   height: 2px;
            //   background: #F68D6F;
            //   opacity: 0.4;
            //   position: absolute;
            //   bottom: -1px;
            //   left: 0;
            // }

            &:hover{
              border-bottom: 2px solid $purpleDark;
            }
          }
        }

        &--items{
          .item{
            margin-bottom: 24px;
            > a{
              font-family: $font-regular;
              font-size: 22px;
              line-height: 32px;
              color: $purpleDark;
              text-decoration: none;
              position: relative;
              border-bottom: 2px solid $purpleDark;

              // &:after{
              //   transition: all 150ms;
              //   content: "";
              //   width: 100%;
              //   height: 2px;
              //   background: #F68D6F;
              //   opacity: 0.4;
              //   position: absolute;
              //   bottom: -2px;
              //   left: 0;
              // }

              span{
                position: relative;
              }

              &:hover,
              &.active{
                border-bottom: 2px solid $purpleDark;
              }

            }

            .subItems{
              transition: all 150ms;
              margin-top: 0;
              opacity: 0;
              height: 0;
              overflow: hidden;
              .subItem{
                margin-bottom: 18px;
                &:last-child{
                  margin-bottom: 15px;
                }
                a{
                  font-family: $font-regular;
                  font-size: 16px;
                  line-height: 24px;
                  color: $purpleDark;
                  position: relative;
                  text-decoration: none;
                  border-bottom: 2px solid $purpleDark;

                  // &:after{
                  //   transition: all 150ms;
                  //   content: "";
                  //   width: 100%;
                  //   height: 2px;
                  //   background: #F68D6F;
                  //   opacity: 0.4;
                  //   position: absolute;
                  //   bottom: -2px;
                  //   left: 0;
                  // }

                  &:hover{
                    border-bottom: 2px solid $purpleDark;
                  }

                }
              }
              &.active{
                height:auto;
                opacity: 1;
                margin-top: 20px;
              }
            }

            &.item--expandable{
              >a{
                span{
                  &:after{
                    transition: all 150ms;
                    @include background-image("./icons/arrow-down-item-menu.svg", 15, 17);
                    position: absolute;
                    right: -27px;
                    top: 50%;
                    margin-top: -7.5px;
                    rotate: -90deg;
                    pointer-events: none;
                  }
                }
                &.active{
                  span{
                    &:after{
                      // transform: rotate(180deg);
                      // right: -29px;
                    }
                  }
                }
              }
            }

          }
        }


      }
    }

    > .inner-wrapper-sticky{
      transition: top 0.2s ease-in-out, background 0.2s;

      &.nav-up {
          top: -149px !important;
      }
    }
    &.is-affixed{
      .inner-wrapper-sticky{
        background-color: #ffffff!important;
        box-shadow: 0px 3px 30px 1px rgba(0, 0, 0, .1);

        .header__wrapper{
          @media (max-width: 992px){
            margin-top: 0;
          }
          @media (max-height: 700px){ // If viewport is less than 700px tall
            height: 100px;
            margin-top: 0;
          }
          .header__wrapper--logo{
            @media (max-height: 700px){ // If viewport is less than 700px tall
              top: 24px;
            }

            @media (max-width: 992) , (max-height: 700px){ // If viewport is less than 992px wide and 700px tall
              top: 30px;
            }
          }
        }
      }
    }
  }
