.newsletter-macro{
    margin-top: 60px;
    margin-bottom: 24px;
    margin-left: calc(35.714% + 15px);
    width: calc(57.1428% - 16px);
    @media(max-width: 992px) {
        margin-top: 40px;
        margin-bottom: 28px;
        margin-left: 0;
        width: 100%;
    }

    .form-group{
        margin-bottom: 0;
    }

    .error-message{
        width: 100%;
        margin-top: 16px;
        margin-bottom: 0;
        margin-left: 0;
        color: $red;

        @media(max-width: 992px) {
            margin-top: 10px;
        }
    }

    .terms{
        margin-top: 24px;

        label{
            p{
                width: 100%;
                margin-left: 0;
            }
        }

        &.invalid {
            label {
                color: #C25839;
                p {
                    color: #C25839;

                    a {
                        color: #C25839 !important;
                    }
                }
            }
        }
    }

    .button-wrapper{
        .btn{
            // padding: 20px 40px;
            // font-size: 21px;
            // line-height: 28px;
            // border-radius: 62px;
        }
    }
}
