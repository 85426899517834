.color-blocks{
  display: flex;
  flex-direction: row;
  margin-bottom: 97px;

  @media (max-width: 992px) {
    margin-bottom: 47px;
  }

  .container{
    @media (max-width: 1616px) {
      padding: 0 !important;
    }

    @media (max-width: 1200px) {
      max-width: 100%;
    }

    .wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .color-blocks__block{
        position: relative;
        width: 33.33%;
        overflow: hidden;

        @media (max-width: 992px) {
          width: 33.33%;
        }

        @media (max-width: 768px) {
          width: 50%;
        }

        .block-content{
          position: relative;
          height: 100%;
          padding: 97px 36px 85px;
          text-align: center;
          z-index: 0;

          @media (max-width: 992px) {
            padding: 7.3vw 20px;
            line-height: 16px;
          }

          &::before{
            content: "";
            position: absolute;
            display: block;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -1;
          }

          .title{
            max-width: 290px;
            min-height: 62px;
            margin-right: auto;
            margin-bottom: 20px;
            margin-left: auto;
            color: #493B55;
            font-size: 26px;
            font-family: "SuisseIntl-SemiBold", sans-serif;
            line-height: normal;

            @media (max-width: 992px) {
              display: flex;
              flex-direction: column;
              justify-content: center;
              min-height: 32px;
              margin-bottom: 25px;
              font-size: 14px;
            }
          }

          .description{
            display: inline;
            color: #493B55;
            font-size: 24px;
            line-height: 30px;
            text-decoration: none;
            border-bottom: 1px solid $orange;

            @media (max-width: 992px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }

        &:nth-child(1){
          background-color: #C9E6D4;

          .block-content{
            &::before{
              background-image: url(../images/blob-shape-1.svg);
              width: 502px;
              height: 436px;
              bottom: -320px;
              left: -280px;

              @media (max-width: 992px) {
                width: 208px;
                height: 180px;
                bottom: -135px;
                left: -110px;
              }
            }
          }
        }

        &:nth-child(2){
          background-color: #DBEEE2;
        }

        &:nth-child(3){
          background-color: #FDEBB0;

          .block-content{
            &::before{
              background-image: url(../images/blob-shape-2.svg);
              width: 262px;
              height: 256px;
              bottom: -40px;
              left: -200px;

              @media (max-width: 992px) {
                width: 108px;
                height: 105px;
                bottom: 10px;
                left: -77px;
              }
            }
          }
        }

        &:nth-child(4){
          background-color: #C3E7EB;
        }

        &:nth-child(5){
          background-color: #F9BBC2;

          .block-content{
            &::before{
              background-image: url(../images/blob-shape-3.svg);
              width: 461px;
              height: 401px;
              bottom: -342px;
              left: 0px;

              @media (max-width: 992px) {
                width: 190px;
                height: 165px;
                bottom: -140px;
                left: calc(50% - 95px);
              }
            }

            .description{
              border-width: 2px;

              @media (max-width: 992px) {
                border-width: 1px;
              }
            }
          }
        }

        &:nth-child(6){
          background-color: #F7987A;

          .block-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 54px;
            padding-bottom: 50px;

            @media (max-width: 992px) {
              justify-content: center;
              padding-top: 36px;
              padding-bottom: 30px;
            }

            .title{
              position: relative;
              min-height: auto;
              margin-bottom: 36px;
              color: white;
              font-size: 20px;
              font-weight: 400;
              text-align: left;
              line-height: 100%;

              @media (max-width: 992px) {
                margin-bottom: 20px;
                font-size: 10px;
                line-height: 100%;
              }

              &::before{
                content: "";
                background-image: url(../images/cloud-shape.svg);
                position: absolute;
                top: -32px;
                left: -35px;
                display: block;
                width: 197px;
                height: 97px;
                background-size: contain;
                background-repeat: no-repeat;
                pointer-events: none;

                @media (max-width: 992px) {
                  top: -15px;
                  left: -14px;
                  width: 91px;
                  height: 45px;
                }
              }
            }

            .description{
              font-size: 20px;
              font-weight: 400;
              text-align-last: left;
              border-bottom: none;
              line-height: normal;

              @media (max-width: 992px) {
                font-size: 10px;
              }

              span{
                position: relative;

                &::after{
                  content: "";
                  position: absolute;
                  bottom: -5px;
                  left: -5px;
                  width: calc(100% + 10px);
                  height: 5px;
                  background-image: url(../images/crooked-line.svg);
                  background-repeat: no-repeat;
                  background-size: contain;

                  @media (max-width: 992px) {
                    display: none;
                  }
                }
              }
            }
          }

          a{
            margin-top: 20px;
            padding: 18px 56px 18px;
            border-radius: 100px;
            color: #000000;
            background-color: #B1D6BD;
            border: none;
            font-size: 26px;
            font-weight: 400;
            line-height: 100%;
            text-decoration: none;

            @media (max-width: 1200px) {
              font-size: 20px;
            }

            @media (max-width: 992px) {
              margin-top: 8px;
              padding: 7px 18px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
