.videoYT.macro{
    margin-top: 80px;
    margin-left: calc(35.714% + 15px);
    width: calc(57.1428% - 16px);
    @media(max-width: 992px){
        margin-left: 0;
        margin-top: 40px;
        width: 100%;
    }

    .featuredImageIngress__image,
    .featuredImageIngress__video{
        max-width: 100%;
    }
    .featuredImageIngress__video{
        .playVideo{
            width: 152px;
            height: 148px;
            margin-top: -72px;
            margin-left: -76px;
            @media(max-width: 992px){
                width: 112px;
                height: 108px;
                margin-top: -56px;
                margin-left: -54px;
            }
        }
    }

    &[data-portal-component-type="part"]{
        width: 100%;
        max-width: 1400px;
        margin: 40px auto;
        padding: 0 40px;

        @media(max-width: 1200px){
            max-width: 845px;
            padding: 0 24px;
        }

        @media(max-width: 992px){
            padding: 0 30px;
        }
    }
}
