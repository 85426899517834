.error404{
    height: auto;
    min-height: calc( 100vh - 175px);
    display: flex;
    justify-content: center;
    @media (max-width: 992px) {
        min-height: calc( 100vh - 145px);
    }

    &__content{
        padding: 73px 0 248px 0;
        text-align: center;
        @media (max-width: 992px) {
            padding: 60px 0 205px 0;
        }
        > img{
            width: 100%;
            max-width: 400px;
            height: auto;
            margin-bottom: 80px;
            @media (max-width: 992px) {
                width: 152px;
                margin-bottom: 32px;
            }
        }
        h1{
            font-family: $font-light;
            font-size: 28px;
            line-height: 48px;
            text-align: center;
            letter-spacing: 0.24px;
            color: #121212;
            margin-bottom: 28px;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
                width: 100%;
                max-width: 260px;
                margin: 0 auto 46px auto;
            }
        }
        &--btn{
            a{
                @include button-primary;
            }
        }
        .desktopShow{
            display: inline-block;
            @media (max-width: 992px) {
                display: none;
            }
        }
        .mobileShow{
            display: none;
            @media (max-width: 992px) {
                display: inline-block;
            }
        }
    }
}
