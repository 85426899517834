.banner{
  margin-top: -20px; // next to header
  margin-bottom: 200px;
  @media(max-width: 992px){
    margin-bottom: 89px;
    margin-top: 0;
  }
  &__wrapper{

    .container{
      &.images{
        @media(max-width: 992px){
          padding: 0;
        }
      }
    }

    h1{
      font-family: $font-regular;
      font-size: 140px;
      line-height: 128px;
      letter-spacing: -2.5px;
      color: $brandPurple;
      margin-bottom: 50px;
      margin-top: 16px;
      margin-left: 10.5%;

      @media (max-width:1680px){
        font-size: 7.15vw;
        line-height: 6.435vw;
        letter-spacing: -2.12px;
      }
      @media (max-width:1200px){
        font-size: 78px;
        line-height: 85px;
        letter-spacing: -1.5px;
      }

      @media (max-width: 992px){
        font-size: 48px;
        line-height: 55px;
        text-align: center;
        letter-spacing: -1.5px;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 32px;
      }

    }
    &--buttonWrapper{
      margin-left: 10.5%;
      @media (max-width: 992px){
        margin-left: 0;
      }

      .content{
        @media (max-width: 992px){
          text-align: center;
        }
        .lineBlock{
          display: block;
          width: 100%;
          clear: both;
        }
        .button{
          text-decoration: none;
          padding: 25px 42px 19px 42px;
          border-radius: 68px;
          // font-family: $font-sporting-grotesque-regular;
          font-size: 24px;
          line-height: 28px;
          display: block;
          margin-bottom: 30px;
          width: fit-content;

          @media (max-width:1680px){
            font-size: 1.3108vw;
            line-height: 1.6683vw;
            letter-spacing: -0.88px;
            padding: 20px 36px 16px;
          }

          @media (max-width: 1200px){
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.6px;
            margin-bottom: 12px;
            padding: 20px 28px 14px 28px;
          }

          @media (max-width: 992px){
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.6px;
            margin-bottom: 12px;
            padding: 20px 28px 14px 28px;
          }

          &.brandPurple{
            background: $brandPurple;
          }
          &.brandRed{
            background: $brandRed;
          }
          div{
            padding-right: 45px;
            position: relative;
            text-align: left;
            @media (max-width: 992px){
              padding-right: 33px;
            }
            span{
              position: relative;
              &:after{
                @include background-image("./icons/arrow-CTA.svg", 28, 18);
                position: absolute;
                right: -45px;
                top: 50%;
                margin-top: -12px;
                @media (max-width: 992px){
                  @include background-image("./icons/arrow-CTA.svg", 20, 14);
                  right: -33px;
                  margin-top: -9px;
                }
              }
            }
          }
          &.btn-secondary {
            div span::after {
              background-image: url("../images/./icons/arrow-CTA-dark.svg");
            }
          }
        }
      }

    }

    &--images{
      position: relative;
      width: 80%;
      margin-left: auto;

      @media (max-width: 1600px){
        width: inherit;
      }

      &:before{
        content: url("../images/form.svg");
        width: 1060px;
        height: auto;
        left: 130px;
        top: -300px;
        position: absolute;
        @media (max-width: 992px){
          display: none;
        }
      }
      @media (max-width: 992px){
        overflow: hidden;
        margin-bottom: 32px;
      }
      .img img{
        @media (max-width: 992px){
          height: 100%;
          object-fit: cover;
        }
      }
      .landscape1,
      .portrait1,
      .landscape2{
        float: right;
      }
      .portrait1{
        width: 100%;
        max-width: 37.5%;
        @media (max-width: 992px){
          float: left;
          margin-top: 29px;
          max-width: 47.5%;

          .img.aspectRatio--360x500 {
            padding-top: calc((235 / 178) * 100%);
          }
        }
      }
      .landscape1{
        width: 100%;
        max-width: 48.4%;
        margin-right: 8%;
        margin-top: 127px;
        @media (max-width: 992px){
          margin-top: 0;
          margin-right: 0;
          max-width: 47.2%;
          .img.aspectRatio--460x313 {
            padding-top: calc((126 / 177) * 100%);
          }
        }
      }

      .landscape2{
        width: 100%;
        max-width: 68.5%;
        margin-right: 10.5%;
        margin-top: 60px;
        @media (max-width: 992px){
          margin-top: 32px;
          margin-right: 0;
          max-width: 39.2%;
          .img.aspectRatio--660x448 {
            padding-top: calc((106 / 147) * 100%);
          }
        }
      }
    }
  }

  .showMobile{
    display: none;
    @media (max-width: 992px){
      display: block;
    }
  }

  .hideMobile{
    display: block;
    @media (max-width: 992px){
      display: none;
    }
  }
}

.banner-h {
  margin-bottom: 80px;
  @media(max-width: 992px) {
    margin-bottom: 40px;
  }
}
.mobile-toright{
  @media(max-width: 992px) {
    margin-right: -30px;
  }
}
.bannerPortrait{
  margin-bottom: 260px;
  position: relative;
  @media(max-width: 1200px){
    margin-bottom: 200px;
  }
  @media(max-width: 992px){
    margin-bottom: 89px;
  }
  &__wrapper{

   .portrait1{
      width: 29.5%;
      float: left;
      position: relative;
      .btnWrapper{
        .button{
          text-align: center;
          text-decoration: none;
          position: absolute;
          color: $brandPurple;
          // font-family: $font-sporting-grotesque-regular;
          transition: all 100ms;
          transform: scale(1);
          border-radius: 40%;

          &:hover{transform: scale(1.03);}

          @media(max-width: 1550px){
            transform: scale(.9);
            &:hover{transform: scale(0.93);}
          }
          @media(max-width: 1390px){
            transform: scale(.8);
            &:hover{transform: scale(0.83);}
          }
          @media(max-width: 1200px){
            transform: scale(.6);
            &:hover{transform: scale(0.63);}
          }


          &.lightGreen{
            @include background-image("./form-botton.svg", 231.39, 199.74);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.88px;
            padding: 0 30px;
            top: 72.6%;
            left: 35%;

            @media(max-width: 1550px){
              top: 70.6%;
              left: 33%;
            }
            @media(max-width: 1390px){
              top: 68.6%;
              left: 31%;
            }
            @media(max-width: 1200px){
              top: 59.6%;
              left: 20%;
            }

          }
          &.orange{
            @include background-image("./form-botton2.svg", 225, 220);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
            line-height: 32px;
            text-align: center;
            letter-spacing: -0.88px;
            padding: 0 40px;
            top: 89.6%;
            left: 5%;

            @media(max-width: 1550px){
              top: 87.6%;
              left: 3%;
            }
            @media(max-width: 1390px){
              top: 85.6%;
              left: 1%;
            }
            @media(max-width: 1200px){
              top: 76.6%;
              left: -12%;
            }

          }
        }
      }
   }

   h1{
    position: absolute;
    overflow: hidden;
    margin-top: 4.5%;
    z-index: 2;
    width: 47.5%;
    height: 87%;
    left: 50%;
    transform: translateX(-50%);
    font-family: $font-regular;
    font-size: 208px;
    line-height: 200px;
    text-align: center;
    letter-spacing: -2.5px;
    color: $brandPurple;
    text-align: center;
    @media(max-width: 1550px){
      font-size: 180px;
      line-height: 180px;
    }
    @media(max-width: 1390px){
      font-size: 150px;
      line-height: 150px;
    }
    @media(max-width: 1200px){
      font-size: 120px;
      line-height: 120px;
    }
  }

   .portrait2{
      width: 29.5%;
      float: right;
      margin-top: 20%;
      margin-top: 19.2%;
    }
  }
}
