@mixin font-face($name, $file) {
  @font-face {
    font-family: "#{$name}";
    src: url("../fonts/#{$file}.woff") format("woff");
    // src: url("../fonts/#{$file}.eot");
    // src: url("../fonts/#{$file}.eot?#iefix") format("embedded-opentype"),
    //   url("../fonts/#{$file}.woff") format("woff"),
    //   url("../fonts/#{$file}.ttf") format("truetype"),
    //   url("../fonts/#{$file}.svg?#webfont") format("svg");
  }
}

@include font-face("SuisseIntl-Regular", "SuisseIntl-Regular-WebS");
@include font-face("SuisseIntl-Book", "SuisseIntl-Book-WebS");
@include font-face("SuisseIntl-Light", "SuisseIntl-Light-WebS");
@include font-face("SuisseIntl-Medium", "SuisseIntl-Medium-WebS");
@include font-face("SuisseIntl-SemiBold", "SuisseIntl-SemiBold-WebS");
@include font-face("Sporting-Grotesque-Regular", "sportinggrotesque-regular-webfont");

$font-regular: "SuisseIntl-Regular", sans-serif;
$font-book: "SuisseIntl-Book", sans-serif;
$font-light: "SuisseIntl-Light", sans-serif;
$font-medium: "SuisseIntl-Medium", sans-serif;
$font-semiBold: "SuisseIntl-SemiBold", sans-serif;
$font-sporting-grotesque-regular: "Sporting-Grotesque-Regular", sans-serif;

// Font sizes
$p-sm: .875rem; // 14
$p: 1rem; // 16
$p-m: 1.3125rem; // 21
$p-lg: 1.5625rem; // 25

// 12 13(.8125) *14 15(.9375) *16 17(1.0625) 18 19 20(1.25) *21 22(1.375) 24 *25 28(1.75) 29 32

html,
body {
  font-size: 16px;
  hyphens: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: $font-regular;
}

.hyphenate {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
