.blogPageHeader{
    margin-bottom: 120px;
    @media(max-width: 920px){
        margin-bottom: 48px;
    }
    &__image{
        max-width: 539px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 80px;
        text-align: center;
        @media(max-width: 920px){
            margin-bottom: 48px;
            max-width: 260px;
        }
        img{
            max-width: 539px;
            display: inline-block;
            @media(max-width: 920px){
                max-width: 70%;
            }
        }
    }
    &__title{
        margin-bottom: 80px;
        text-align: center;
        @media(max-width: 920px){
            margin-bottom: 48px;
        }

        h1{
            font-family: $font-regular;
            font-size: 208px;
            line-height: 200px;
            text-align: center;
            letter-spacing: -2.5px;
            color: $brandPurple;
            @media(max-width: 920px){
                font-size: 64px;
                line-height: 55px;
                letter-spacing: -1.5px;
            }
            img{
                @media(max-width: 920px){
                    width: 72px;
                    height: auto;
                }
            }
        }

    }
    &__ingress{
        p{
            font-family: $font-light;
            font-size: 28px;
            line-height: 48px;
            text-align: center;
            letter-spacing: 0.24px;
            color: #121212;
            text-align: center;
            @media(max-width: 920px){
                font-size: 17px;
                line-height: 28px;
                width: 100%;
                max-width: 91%;
                margin: 0 auto;

                br{
                    display: none;
                }
            }
        }
    }
}


.blogCategory{

    z-index: 1001;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    overflow-x: auto;
    right: -100vw;
    -ms-overflow-style: none;
    transition: all 300ms;
    &::-webkit-scrollbar {
      display: none;
    }

    &__wrapper{
        background: #FFF3E8;
        width: 616px;
        height: 100vh;
        float: right;
        padding: 140px 120px 140px 96px;
        overflow: auto;
        @media (max-width: 768px) {
            padding: 85px 30px 85px 30px;
        }

        @media(max-width: 992px){
            width: 100%;
        }

        .btnClose{
            position: fixed;
            z-index: 10;
            top: 40px;
            right: -100vw;
            transition: all 350ms;
            @media(max-width: 992px){
              top: 35px;
            }
            img{
              width: 40px;
              height: auto;
              @media(max-width: 992px){
                width: 17.5px;
              }
            }
        }

        &--items{

            .item{
                margin-bottom: 26px;

                @media(max-width: 992px){
                    margin-bottom: 24px;
                }

                a{
                    @include link-default;
                    font-family: $font-book;
                    text-decoration: none;
                    font-size: 28px;
                    line-height: 44px;
                    letter-spacing: -0.16px;
                    color: #121212;
                    position: relative;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    -webkit-hyphens: auto;
                    -moz-hyphens: auto;
                    -ms-hyphens: auto;
                    hyphens: auto;
                    transition: all 150ms linear;

                    @media(max-width: 992px){
                        font-size: 22px;
                        line-height: 32px;
                    }

                }
            }
        }
    }

    &.active{
        right: 0;
        .btnClose{
            right: 40px;
            @media(max-width: 992px){
                right: 35px;
            }
        }
        .overlay{
            animation: backgroundFade 300ms 300ms both;
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    @keyframes backgroundFade {
        from {
            background: rgba($color: #000000, $alpha: 0);
        }

        to {
            background: rgba($color: #000000, $alpha: .5);
        }
    }


}


.blogListPosts{

    &__itemsWrapper{
        display: flex;
        align-items: flex-end;
        margin-bottom: 160px;

        @media(max-width: 992px){
            display: block;
            align-items: inherit;
            margin-bottom: 48px;
        }

        .item{
            width: 100%;
            max-width: 33.2%;
            @media(max-width: 992px){
                max-width: 100%;
                overflow: hidden;
                margin-bottom: 48px;
            }

            .content{
                margin-right: 20%;
                @media(max-width: 992px){
                    max-width: 87%;
                    margin-right: 0;
                }
            }
            a{
                text-decoration: none;
            }
            .imgWrapper{
                display: flex;
                align-items: flex-end;
                margin-bottom: 24px;
                @media(max-width: 992px){
                    margin-bottom: 16px;
                }
                img{
                    width: 100%;
                    transition: all 250ms;
                }
            }
            .info{
                height: 110px;
                @media(max-width: 992px){
                    height: auto;
                }
                .date{
                    margin-bottom: 8px;
                    font-family: $font-light;
                    font-size: 18px;
                    line-height: 28px;
                    display: flex;
                    align-items: center;
                    color: #ACACAC;
                    @media(max-width: 992px){
                        font-size: 15px;
                        line-height: 22px;
                        letter-spacing: 0.24px;
                    }
                }
                .title{
                    font-family: $font-book;
                    font-size: 25px;
                    line-height: 36px;
                    letter-spacing: -0.24px;
                    color: #121212;

                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media(max-width: 992px){
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
            }
            &.blogSpin{
                .content{
                    position: relative;
                    &:after{
                        @include background-image("./blog-icon.svg", 162, 162);
                        position: absolute;
                        right: -81px;
                        top: 16%;
                        -webkit-animation: spin 12500ms infinite linear;
                        animation: spin 12500ms infinite linear;
                        @media(max-width: 768px ){
                            @include background-image("./blog-icon.svg", 100, 100);
                            right: -50px;
                            display: none;
                        }
                        display: none;
                    }

                    @-webkit-keyframes spin {
                        0% {
                            -webkit-transform: rotate(0deg);
                            transform: rotate(0deg);
                        }
                        100% {
                            -webkit-transform: rotate(359deg);
                            transform: rotate(359deg);
                        }
                    }
                    @keyframes spin {
                        0% {
                            -webkit-transform: rotate(0deg);
                            transform: rotate(0deg);
                        }
                        100% {
                            -webkit-transform: rotate(359deg);
                            transform: rotate(359deg);
                        }
                    }


                }
            }
            &:hover{
                img{
                    opacity: .9;
                }
            }
        }

        &.odd{
            .item{
                @media(max-width: 992px){
                    &:nth-child(odd){
                        .content{
                            float: right;
                        }
                    }
                }
            }
        }

        &.even{
            .item{
                @media(max-width: 992px){
                    &:nth-child(even){
                        .content{
                            float: right;
                        }
                    }
                }
            }
        }

        &.spin{
            .item{
                &.blogSpin{
                    .content{
                        &:after{
                            display: inline-block!important;
                            @media(max-width: 768px ){
                                display: inline-block!important;
                            }
                        }
                    }
                }
            }
        }
    }

    &__itemLandscaspeWrapper{
        margin-bottom: 160px;

        @media(max-width: 992px ){
            margin-bottom: 48px;
        }

        a{
            text-decoration: none;
        }

        .item{

            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media(max-width: 992px){
                display: block;
                align-items: inherit;
                justify-content: inherit;
            }

            .imgWrapper{
                width: 100%;
                max-width: 52%;
                @media(max-width: 992px){
                    max-width: 100%;
                    margin-bottom: 16px;
                }
                img{
                    width: 100%;
                    transition: all 250ms;
                }
            }

            .info{
                width: 100%;
                max-width: 31.5%;

                @media(max-width: 992px){
                    max-width: 100%;
                    margin-top: 0;
                }

                .date{
                    font-family: $font-light;
                    font-size: 18px;
                    line-height: 28px;
                    color: #ACACAC;
                    margin-bottom: 12px;

                    @media(max-width: 992px){
                        font-size: 15px;
                        line-height: 22px;
                        letter-spacing: 0.24px;
                    }
                }
                .title{
                    font-family: $font-regular;
                    font-size: 32px;
                    line-height: 44px;
                    letter-spacing: -0.16px;
                    color: #121212;
                    margin-bottom: 12px;

                    @media(max-width: 992px){
                        font-size: 20px;
                        line-height: 28px;
                        margin-bottom: 6px;
                    }
                }
                .content{
                    font-family: $font-light;
                    font-size: 21px;
                    line-height: 36px;
                    letter-spacing: 0.24px;
                    color: #2C2D2E;

                    @media(max-width: 992px){
                        font-size: 15px;
                        line-height: 22px;
                    }

                }
            }
        }

        &.imgLeft{
            .item{
                .imgWrapper{
                    margin-right: 9.65%;
                    @media(max-width: 992px ){
                        margin-right: 0;
                    }
                }
            }
        }

        &.imgRight{
            .item{
                flex-direction: row-reverse;
                @media(max-width: 992px ){
                    flex-direction: inherit;
                }
                .imgWrapper{
                    margin-left: 9.65%;
                    @media(max-width: 992px ){
                        margin-left: 0;
                    }
                }
            }
        }

        &.bg-sand{
            &.imgRight{
                .imgWrapper{
                    position: relative;
                    z-index: 0;
                    &:before{
                        z-index: -1;
                        content: url("../images/form-list-blog.svg");
                        width: 100%;
                        max-width: 60.3%;
                        height: auto;
                        left: -15%;
                        top: 20%;
                        position: absolute;
                        @media(max-width: 992px){
                            width: 100%;
                            max-width: 77.74%;
                            left: inherit;
                            right: -7%;
                            top: -6%;
                        }
                    }
                }
            }
        }




    }


    &__showMore{
        text-align: center;
        margin-bottom: 200px;
        margin-top: -60px;

        @media(max-width: 992px){
            margin-top: 32px;
            margin-bottom: 100px;
        }

        a{
            font-family: $font-regular;
            font-size: 21px;
            line-height: 36px;
            letter-spacing: 0.24px;
            color: #121212;
            display: inline-block;
            text-decoration: none;
            @include link-default;

        }
    }

}
