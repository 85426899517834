// OLD version of the studievelger page.
// Replaced by program-list.scss
.studievelgerPageHeader{
    margin-bottom: 32px;
    margin-top: 77px;
    @media(max-width: 991px) {
        margin-bottom: 20px;
        margin-top: 30px;
    }
    h1{
        font-family: $font-book;
        font-size: 72px;
        line-height: 88px;
        letter-spacing: -0.88px;
        color: $brandPurple;

        @media(max-width: 991px) {
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.24px;
        }
    }
}

.contentScroll{
    margin-bottom: 181px;
    @media(max-width: 992px) {
        margin-bottom: 100px;
    }
    .container{
        .scrollFilter{
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            @media(max-width: 992px) {
                overflow-y: initial;
            }
        }
        .inner-wrapper-sticky {
            left: inherit !important;
        }
    }
}

.filters-bar {
    display: flex;
    margin-bottom: 48px;
    @media(max-width: 991px) {
        flex-direction: column;
        margin-bottom: 12px;
    }
    &__tags{
        .empty-tag{
            font-family: $font-light;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.24px;
            color: #121212;
            padding: 7px 16px;
            border: 1px solid $grey;
            border-radius: 8px;
            display: inline-block;
            margin-right: 16px;
            margin-bottom: 12px;
            background: none;
            &:focus {
                outline: none;
            }
            @media(max-width: 991px) {
                font-size: 15px;
                line-height: 20px;
                padding: 8px 12px;
            }
        }
        .hide-tag{
            display: none !important;
        }
        .remove-tag{
            font-family: $font-light;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.24px;
            color: #121212;
            padding: 7px 36px 7px 16px;
            border: 1px solid $orange;
            border-radius: 8px;
            margin-right: 16px;
            margin-bottom: 12px;
            background-color: transparent;
            position: relative;
            display: inline-block;
            @media(max-width: 991px) {
                font-size: 15px;
                line-height: 20px;
                padding: 8px 32px 8px 12px;
                margin-right: 12px;
            }
            &:after {
                content: '';
                width: 14px;
                height: 14px;
                background-image: url(../images/icons/x-remove.svg);
                position: absolute;
                background-size: contain;
                right: 14px;
                top: 14px;
                @media(max-width: 991px) {
                    width: 12px;
                    height: 12px;
                    right: 12px;
                    top: 12px;

                }

            }
            &:focus {
                outline: none;
            }
        }

    }
    &__results-number{
        text-align: right;
        font-size: 20px;
        line-height: 36px;
        color: #121212;
        font-family: $font-medium;
        margin-left: auto;
        min-width: 200px;
        @media(max-width: 991px) {
            font-size: 13px;
            line-height: 20px;
            margin-top: 8px;
            margin-left: 0;
            text-align: left;
        }

    }
}
.filter-studies {
    padding-top: 25px;

    @media(max-width: 991px) {
        overflow-y: auto;
        padding-top: 60px;
        padding-bottom: 120px;
        padding-left: 25px;
        padding-right: 35px;
    }
    .card{
        .card-header{
            padding: 0 0 22px;
            @media(max-width: 991px) {
                padding-bottom: 24px;
            }
        }
        .card-body {
            padding: 0 0 25px;
        }
    }
    &__close{
        font-family: $font-light;
        font-size: 13px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #939393;
        padding-left: 20px;
        margin-bottom: 24px;
        cursor: pointer;

        @media(min-width: 992px) {
            display: none;
        }
    }
    &__label{
        font-size: 17px;
        line-height: 28px;
        letter-spacing: 0.24px;
        margin-bottom: 12px;
        @media(max-width: 991px) {
            display: none;
        }
    }
    &__btn-bar{
        text-align: center;
        display: none;
        width: 100%;
        padding: 25px 0 40px;
        .btn-primary{
            font-family: $font-regular;
            font-size: 17px;
            line-height: 28px;
            letter-spacing: 0.24px;
            padding: 14px 90px;
            border-radius: 38px;
            &:focus{
                box-shadow: none!important;
            }
            span {
                color: #63CDD4;
            }
        }
        @media(max-width: 991px) {
            display: block;
            background-color: #FFF3E8;
            position: fixed;
            bottom: 0;
            left: -100vw;
            transition: all 150ms ease-in-out;
            &.active{
                left: 0;
            }
        }
    }
    @media(max-width: 991px) {
        background-color: #fff;
        position: fixed;
        width: 100vw;
        height: 100%;
        top: 0;
        left: -100vw;
        z-index: 10;
        overflow: auto;
        transition: all 150ms ease-in-out;
        &.active{
            left: 0;
        }
    }
    input[type=checkbox]+label,
    input[type=radio]+label {
        font-family: $font-light;
        font-size: 21px;
        line-height: 36px;
        letter-spacing: 0.24px;
        color: #121212;
        margin-bottom: 16px;
        @media(max-width: 991px) {
            font-size: 17px;
            line-height: 28px;
        }
    }

}
