.landingPageHeader{
    &-toleft{
        h1 {
            font-family: $font-book;
            font-size: 108px;
            line-height: 112px;
            letter-spacing: -0.88px;
            color: $brandPurple;
            margin-left: -40px;
            margin-bottom: 60px;
            overflow-wrap: normal;
            word-wrap: normal;
            -webkit-hyphens: none;
            -moz-hyphens: none;
            -ms-hyphens: none;
            hyphens: none;
            @media(max-width: 991px) {
                font-size: 48px;
                line-height: 55px;
                letter-spacing: -0.5px;
                margin-left: 0;
                margin-bottom: 20px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
        &__intro {
            font-family: $font-light;
            font-size: 25px;
            line-height: 40px;
            letter-spacing: 0.24px;
            color: #121212;
            margin-bottom: 60px;
            @media(max-width: 991px) {
                font-size: 17px;
                line-height: 28px;
                margin-bottom: 40px;
            }
        }
    }
}

.subLandingPageHeader{
    &-sideinfo {
        margin: 100px 0 80px;
        @media(max-width: 991px) {
            margin: 30px 0 20px;
        }
        h1{
            font-family: $font-book;
            font-size: 72px;
            line-height: 88px;
            letter-spacing: -0.88px;
            color: $brandPurple;
            overflow-wrap: normal;
            word-wrap: normal;
            -webkit-hyphens: none;
            -moz-hyphens: none;
            -ms-hyphens: none;
            hyphens: none;
            @media (max-width:1680px) {
                font-size: 4.289vw;
                line-height: 5.2433vw;
            }
            @media(max-width: 991px) {
                font-size: 32px;
                line-height: 40px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
        .back-link {
            margin-bottom: 48px;
            @media(max-width: 991px) {
                margin-bottom: 24px;
            }
        }
        &__intro {
            font-family: $font-light;
            font-size: 25px;
            line-height: 40px;
            letter-spacing: 0.24px;
            color: #121212;
            margin-top: 10px;
            // @media (max-width:1680px) {
            //     font-size: calc(11px + 1.01vw);
            //     line-height: 2.859vw;
            // }
            @media(max-width: 991px) {
                font-size: 17px;
                line-height: 28px;
                margin-top: 20px;
            }
        }
    }

    &--sand {
        .back-link {
            margin-bottom: 48px;
            @media(max-width: 991px) {
                margin-bottom: 24px;
            }
        }

    }
}



.title{
    &-toleft{
        h1 {
          color: $brandPurple;
          margin-left: -40px;
          margin-bottom: 60px;
          overflow-wrap: normal;
          word-wrap: normal;
          -webkit-hyphens: none;
          -moz-hyphens: none;
          -ms-hyphens: none;
          hyphens: none;
          @media(max-width: 991px) {
            margin-left: 0;
            margin-bottom: 20px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
          }
        }
        &__intro {
          font-size: 1.75rem; //  18
          margin-bottom: 60px;
          @media(max-width: 991px) {
            font-size: 1.0625rem;
            margin-bottom: 40px;
          }
        }
    }
    &-sideinfo {
        margin: 100px 0 80px;
        @media(max-width: 991px) {
            margin: 30px 0 28px;
        }
        h1{
            color: $brandPurple;
            font-size: 4.5rem; // 72px
            overflow-wrap: normal;
            word-wrap: normal;
            -webkit-hyphens: none;
            -moz-hyphens: none;
            -ms-hyphens: none;
            hyphens: none;
            @media(max-width: 991px) {
                font-size: 2rem; // 32px
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
             }
        }
        .back-link {
            margin-bottom: 48px;
            @media(max-width: 991px) {
                margin-bottom: 24px;
            }
        }
        &__intro {
            font-size: 1.75rem; //  18
            margin-top: 15px;
            @media(max-width: 991px) {
                font-size: 1.0625rem; //   17
                margin-top: 20px;

            }
        }
    }
    &-intro {
        margin-bottom: 60px;
        @media(max-width: 991px) {
            margin-bottom: 28px;
        }
        h1 {
            font-size: 4.5rem; // 72px
            color: $brandPurple;
            margin-bottom: 60px;
            overflow-wrap: normal;
            word-wrap: normal;
            -webkit-hyphens: none;
            -moz-hyphens: none;
            -ms-hyphens: none;
            hyphens: none;
            @media(max-width: 991px) {
                font-size: 2rem; // 32px
                margin-bottom: 24px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
        &__intro {
            font-family: $font-light;
            font-size: $p-m;
            margin-top: 15px;
            margin-bottom: 60px;
            @media(max-width: 991px) {
                margin-top: 0;
                margin-bottom: 40px;
            }
            .sub-title{
                font-family: $font-regular;
                margin-bottom: 12px;
            }
            @media(max-width: 991px) {
                font-size: 1.0625rem; //   17
            }
        }
    }
    &--sand {
        margin: 100px 0 60px;
        @media(max-width: 991px) {
            margin: 30px 0 28px;
        }
        .back-link {
            margin-bottom: 48px;
            @media(max-width: 991px) {
                margin-bottom: 24px;
            }
        }
        h3{
            color: $brandPurple;
        }
        &:before{
            z-index: -1;
            content: '';
            background-image: url("../images/form-title.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 60%;
            max-width: 60%;
            height: 90%;
            right: 10px;
            top: 0%;
            position: absolute;
            @media(max-width: 992px){
                background-image: url("../images/form-title-m.svg");
                width: 100%;
                height: 100%;
                max-width: 98.74%;
                right: 0;

                background-position: right;
            }
        }
    }
}
