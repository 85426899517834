
@mixin button-primary {
    font-family: $font-regular;
    font-size: 1.125rem;
    line-height: 1.75rem;
    border-radius: 40px;
    transition: transform 0.3s;
    color: #fff;
    background-color: $purpleDark;
    border: 2px solid $purpleDark;
    padding: 8px 28px;
    border-radius: 24px;
    transition: transform 0.3s;
    @media (max-width: 992px) {
        font-size: .9375rem;
    }
    &:not(:disabled):not(.disabled){
        &:hover, &:active, &:focus, &:focus-within {
            font-family: $font-medium;
            background-color: $purpleDark;
        }
    }
    &:first-letter {
        text-transform: uppercase;
    }
}
@mixin button-secondary {
    color: $purpleDark;
    font-family: $font-regular;
    font-size: 1.125rem;
    line-height: 1.75rem;
    border-radius: 40px;
    transition: transform 0.3s;
    background-color: #fff;
    border: 2px solid $purpleDark;
    padding: 8px 28px;
    border-radius: 24px;
    transition: transform 0.3s;
    @media (max-width: 992px) {
        font-size: .9375rem;
    }
    &:not(:disabled):not(.disabled){
        &:hover, &:active, &:focus, &:focus-within {
            font-family: $font-medium;
            color: $purpleDark;
            background-color: #fff;
        }
    }
    &:first-letter {
        text-transform: uppercase;
    }
}

@mixin link-default {
    text-decoration: none;
    border-bottom: 2px solid $link-blue;
    &:hover, &:active, &:focus, &:focus-within {
        font-family: $font-semiBold;
    }
}

.btn {
    padding: 8px 28px;
    border-radius: 22px;
    transition: transform 0.3s;
    &:first-letter {
        text-transform: uppercase;
    }
    &-primary{
        color: #fff;
        @include button-primary;
    }
    &-secondary {
        @include button-secondary;
    }
    &-brand-red{
        background-color: $brandRed;
        border-color: $brandRed;
        color: #fff;
        &:not(:disabled):not(.disabled){
            &:hover, &:active, &:focus, &:focus-within {
                color: #fff;
                background-color: $brandRed;
                border-color: $brandRed;
            }
        }
    }
    span {
        transition: transform 0.3s;
    }
    &--loading{
        position: relative;
        &:not(:disabled):not(.disabled){

            &:hover,&:active, &:focus,&:hover:active {
                background: $purpleDark;
                cursor: default;
                box-shadow: none;
            }
        }
        span {
            display: block;
            opacity: 0;
            transform: translateY(16px) scale(.7) translateZ(0);
            transition: transform 0.3s, opacity 0.3s;
        }
        svg {
            height: 37px;
            width: 37px;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -18px 0 0 -18px;
            fill: none;
            stroke:  #fff;
            // stroke-linecap: square;
            stroke-width: 1.2;
            /* opacity: var(--svg-o, 0); */
            transform:  rotate(-90deg) ;
            circle {
                &:first-child {
                    stroke-opacity: 0.3;
                }
                &:last-child {
                    stroke: #fff;
                    stroke-dasharray: 38px;
                    stroke-dashoffset: 114px;
                    -webkit-animation: load 1.4s cubic-bezier(0.42, -0.01, 1, 1) infinite 0.3s;
                    animation: load 1.4s cubic-bezier(0.42, -0.01, 1, 1) infinite .3s;
                }
            }

        }

    }
}
.btn-remove{
    color: transparent;
    width: 40px;
    height: 40px;
    background-image: url(../images/icons/x-remove.svg);
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    &:hover{
        color: transparent;
    }
}
.btn-float {
    font-family: $font-sporting-grotesque-regular;
    text-align: center;
    color: #fff;
    font-size: $p-m;
    border-radius: 50%;
    background-color: $darkGreen;
    width: 140px;
    height: 140px;
    position: fixed;
    top: 50%;
    right: 45px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    z-index: 1000;
    &:hover {
        color: #fff;
    }
    @media (max-width: 765px) {
        font-size: 1.0625rem; //17
        height: 120px;
        width: 120px;
        right: 0;
    }
    span {
        font-family: $font-light;
        font-size: $p-sm;
        font-weight: 300;
        display: block;
        @media (max-width: 765px) {
            font-size: 0.75rem; //12
        }
    }
    &.light {
        background-color: $lightGreen;
    }
    &.purple {
        background-color: $brandPurple;
    }
    &+ .btn-float{
        top: calc(50% - 142px);
        @media (max-width: 765px) {
            top: calc(50% - 122px);
        }
    }
}

.btn-help {
    color: $textDark1;
    font-size: .75rem;
    padding: 0;
    border-radius: 50%;
    border: none;
    background-color: $lightOrange;
    width: 20px;
    height: 20px;
}
.btn-file {
    width: 100%;
    height: 88px;
    background-color: $brandOrange;
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
    @media (max-width: 992px) {
        margin-bottom: 20px;
    }
    &:hover {
        .btn-file__progress {
            transition: 1s;
            width: 100%;
        }
    }
    &__progress{
        display: flex;
        height: 100%;
        width: 0;
        background-color: $lightOrange2;
    }
    &__content {
        padding: 0 15px;
        font-family: $font-regular;
        font-size: 21px;
        color: #121212;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
       @media(max-width: 991px) {
            font-size: 15px;
        }
        &:before {
            content: '';
            margin-right: 20px;
            display: inline-block;
            height: 23px;
            width: 19px;
            background-image: url(../images/icons/download.svg);
            background-repeat: no-repeat;
           @media(max-width: 991px) {
                height: 12px;
                width: 10px;
                min-width: 10px;
                margin-right: 12px;
                background-size: contain;
            }
        }
    }
    input {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 10;
        &:focus-within{
            &+ .btn-file__content {
                text-decoration: underline;
            }
        }
    }
    &--list {
        padding-left: 20px;
        margin-bottom: 24px;
        margin-top: -40px;
        font-size: 21px;
        line-height: 40px;
        font-family: $font-regular;
       @media(max-width: 991px) {
            margin-top: -20px;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 4px;
        }
        li {
            &+ li {
                div {
                    border-top: 1px solid $grey;
                }
            }
            div {
                padding: 24px 0;
                padding-left: 24px;
                margin-left: -20px;
                position: relative;
                padding-right: 150px;
                word-break: break-word;
                @media(max-width: 992px) {
                    padding: 15px 0;
                    padding-left: 20px;
                    padding-right: 120px;
                }
                .btn-secondary{
                    font-size: 16px;
                    line-height: 28px;
                    padding: 7px 24px;
                    border-radius: 38px;
                    position: absolute;
                    right: 0;
                    top: 24px;
                    @media(max-width: 992px) {
                        top: 50%;
                        transform: translateY(-50%);
                        padding: 5px 20px;
                        font-size: 15px;
                        line-height: 24px;
                    }
                }
                &.invalid{
                    color: red
                }
            }
        }
    }
}
@keyframes loading-orange{
    0%   {width: 0;}
    25%  {width: 100%;}
}
//      LINK

.back-link {
    a {
        font-family: $font-light;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        @media(max-width: 991px) {
            font-size: 13px;
        }

        color: #AFB2B5;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
        &:last-child {
            color: #353637;
            text-decoration: underline;
            &:hover{
                color: #353637;
            }
        }
        & + a {
            margin-left: 12px;
        }
    }
}
.link-orange
.link-blue {
    text-decoration: none;
    border-bottom: 2px solid $link-blue;
    &:hover {
        font-family: $font-semiBold;
    }
}
@keyframes load {
    from {
        stroke-dashoffset: 114px;
    }
    to {
        stroke-dashoffset: 38px;
    }
}

// MACRO Call to action button
.article__html-area a{
    &.cta-button{
        color: #fff !important;
        @include button-primary;
        margin-left: calc(35.714% + 15px);
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 20px;

        @media (max-width: 992px){
            margin-top: 20px;
            margin-bottom: 30px;
            margin-left: 0;
        }
        &--secondary {
            color: $purpleDark!important;
            @include button-secondary;
        }
    }
}
