.program-list{
    &.page{
        overflow: inherit;

        @media(max-width: 768px ){
            padding-top: 30px;
        }
    }

    &__header{
        margin-bottom: 70px;
        z-index: 0;

        @media(max-width: 768px ){
            margin-bottom: 28px;
        }

        h1{
            color: $brandPurple;
            font-size: 64px;
            line-height: normal;

            @media(max-width: 768px ){
                font-size: 36px;
            }
        }
    }

    &__header-ingress {
        font-size: 30px;
        line-height: 38px;
        margin-top: 32px;
        margin-bottom: -24px;

        @media(max-width: 768px ){
            font-size: 18px;
            line-height: normal;
            margin-top: 16px;
            margin-bottom: 0px;
        }
    }

    &__filter{
        margin-bottom: 60px;

        @media(max-width: 768px ){
            margin-bottom: 25px;
        }

        .filter-search{
            position: relative;
            margin-bottom: 60px;

            @media(max-width: 768px ){
                margin-bottom: 38px;
            }

            .search-button{
                position: absolute;
                top: 12px;
                left: 30px;
                background: none;
                border: none;

                @media(max-width: 768px ){
                    width: 18px;
                    height: auto;
                    left: 25px;
                    top: 3px;
                }

                svg{
                    @media(max-width: 768px ){
                        width: 100%;
                    }
                }
            }

            .form-control{
                min-height: 54px;
                padding-right: 60px;
                padding-left: 54px;
                border-color: #000000;
                border-radius: 10px;
                color: $brandPurple;
                font-size: 20px;
                line-height: 42px;

                @media(max-width: 768px ){
                    min-height: 38px;
                    padding: 4px 40px 4px 45px;
                    font-family: "SuisseIntl-Regular", sans-serif;
                    font-size: 16px;
                    line-height: normal;
                }

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $brandPurple;
                    opacity: 1; /* Firefox */
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: $brandPurple;
                }

                &:active, &:focus, &:focus-within {
                    border-color: inherit !important;
                    outline: revert;
                }
            }

            .clear-input{
                background: transparent;
                border: none;
                position: absolute;
                top: 17px;
                right: 40px;
                @include background-image("icons/x-remove.svg",20, 20);

                @media(max-width: 768px ){
                    top: 12px;
                    right: 20px;
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .select-wrapper{
            display: flex;
            flex-direction: row;

            @media(max-width: 768px ){
                flex-direction: column;
            }

            .filter-select{
                width: calc(36% - 20px);
                margin-right: 20px;

                @media(max-width: 768px ){
                    width: 100%;
                    margin-right: 0;
                }

                & > p{
                    margin-bottom: 7px;
                    padding-left: 20px;
                    font-size: 18px;
                    line-height: normal;
                    // button?

                    @media(max-width: 768px ){
                        font-size: 16px;
                    }
                }

                .courseSelect{
                    margin-bottom: 0;

                    &.active{
                        z-index: 2;
                    }

                    @media(max-width: 768px ){
                        margin-bottom: 20px;
                    }

                    &__btn{
                        &.active{
                            & + .courseSelect__options{
                                z-index: 0;
                            }
                        }

                        button{
                            padding: 13px 48px 13px 22px;
                            font-size: 18px;
                            background-color: $purpleDark;
                            color: #fff;

                            @media(max-width: 768px ){
                                font-size: 16px;
                                padding: 10px 42px 10px 22px;
                            }
                            @media (hover: hover) and (pointer: fine) {
                                &:hover {
                                    font-family: $font-medium;
                                }
                            }
                            p{
                                font-size: 18px;
                                line-height: normal;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                @media(max-width: 768px ){
                                    font-size: 16px;
                                }
                            }

                            span{
                                background-color: transparent;

                                &::after{
                                    content: "▼";
                                    display: block;
                                    font-size: 24px;

                                    @media(max-width: 768px ){
                                        font-size: 18px;
                                    }
                                }

                                img{
                                    display: none;
                                }
                            }
                        }
                    }

                    &__options{
                        padding: 0;
                        background: #fff;
                        color: $brandPurple;

                        ul{
                            li{
                                margin-bottom: 0;
                                font-size: 18px;

                                &:first-child{
                                    a{
                                        margin-top: 26px;
                                    }
                                }

                                &:last-child{
                                    margin-bottom: 0;

                                    a{
                                        padding-bottom: 26px;
                                        border-bottom-right-radius: 40px;
                                        border-bottom-left-radius: 40px;
                                    }
                                }

                                a{
                                    color: inherit;
                                    display: block;
                                    width: 100%;
                                    padding: 11px 24px 11px 22px;
                                    font-size: 18px;
                                    line-height: normal;
                                    &:hover {
                                        font-family: $font-semiBold;
                                        text-decoration: underline;
                                        text-decoration-color: $link-blue;
                                        text-decoration-skip-ink: none;
                                        text-underline-offset: 5px;
                                        text-decoration-thickness: 2px;
                                      }

                                    @media(max-width: 768px ){
                                        padding-top: 10px;
                                        padding-bottom: 10px;
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .results-number{
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                width: 28%;

                @media(max-width: 768px ){
                    width: 100%;
                }

                p{
                    font-size: 20px;
                    line-height: normal;

                    @media(max-width: 768px ){
                        font-size: 14px;
                        font-family: "SuisseIntl-Medium", sans-serif;
                    }
                }
            }
        }
    }

    &__list{
        margin-bottom: 60px;

        &:last-child{
            margin-bottom: 120px;
        }

        .link-list{
            list-style: none;
            margin-top: -50px;

            @media(max-width: 768px ){
                margin-top: -26px;
            }

            .list-item{
                border-bottom: 1px solid $grey2;

                a{
                    display: block;
                    padding-top: 50px;
                    padding-bottom: 36px;
                    text-decoration: none;

                    @media(max-width: 768px ){
                        padding-top: 26px;
                        padding-bottom: 26px;
                    }

                    &:hover{
                        h2{
                            border-color: transparent;
                            font-family: $font-medium;
                        }
                    }

                    .list-item__header{
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        h2{
                            max-width: calc(100% - 100px);
                        }
                        .discount{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            height: 44px;
                            padding-top: 4px;
                            padding-left: 20px;
                            width: 100px;
                            @media(max-width: 768px ){
                                height: 34px;
                                padding-top: 0;
                            }
                            img{
                                width: 26px;
                                margin-right: 7px;
                            }
                            span{
                                font-family: "SuisseIntl-Medium", sans-serif;
                                font-weight: bolder;
                                font-size: 18px;
                                line-height: 24px;
                                letter-spacing: 0.01em;
                                color: #000000;
                            }
                        }
                    }

                    h2{
                        display: inline;
                        text-decoration-line: underline;
                        text-decoration-thickness: 2px;
                        text-underline-offset: 10px;
                        text-decoration-color: $link-blue;
                        color: $textDark1;
                        font-size: 32px;
                        line-height: 46px;

                        @media(max-width: 768px ){
                            font-size: 18px;
                            line-height: 32px;
                            text-underline-offset: 6px;
                        }
                    }

                    .tags{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-top: 16px;
                        margin-bottom: -6px;
                        list-style: none;

                        @media(max-width: 768px ){
                            margin-top: 12px;
                        }

                        .tag{
                            display: flex;
                            flex-direction: row;
                            margin-right: 16px;
                            margin-bottom: 6px;
                            font-size: 16px;
                            line-height: normal;
                            color: $purpleDark;

                            @media(max-width: 768px ){
                                font-family: "SuisseIntl-Light", sans-serif;
                                margin-right: 12px;
                                font-size: 14px;
                            }

                            &:last-child{
                                margin-right: 0;

                                &::after{
                                    display: none;
                                }
                            }

                            &::after{
                                content: "|";
                                display: block;
                                margin-left: 16px;

                                @media(max-width: 768px ){
                                    margin-left: 12px;
                                }
                            }
                        }
                    }

                    .intro{
                        margin-top: 26px;
                        color: $textDark1;
                        font-size: 16px;
                        line-height: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;

                        @media(max-width: 768px ){
                            margin-top: 16px;
                            font-size: 14px;
                            line-height: 20px;
                            -webkit-line-clamp: 2;
                        }
                    }
                }
            }
        }
    }
}
