.hero{
    &__link {
        opacity: 0;
        position: absolute;
        height: 100%;
        width: calc(100% - 6.25%);
        left:  6.25%;
        z-index: 2;
        &:hover {
        }
        ~ div h3,
        ~ h3 {
            text-decoration: underline;
            text-decoration-color: #3C35F2;
            text-decoration-skip-ink: none;
            text-underline-offset: 8px;
            text-decoration-thickness: 2px;
        }

    }
    &__portrait,
    &__landscape,
    &--horizontal {
        position: relative;
        z-index: 0;
        margin-bottom: 80px;
        @media(max-width: 992px){
            margin-bottom: 60px;
        }
        .img{
            margin-bottom: 32px;
            @media(max-width: 920px){
                margin-bottom: 24px;
            }
        }
        h3{
            font-family: $font-medium;
            font-size: 32px;
            line-height: 44px;
            letter-spacing: -0.16px;
            color: #121212;
            margin-bottom: 12px;
            @media(max-width: 992px){
                font-size: 22px;
                line-height: 32px;
                margin-bottom: 10px;
            }
        }
        a{
            text-decoration: none;
        }
        p{
            font-family: $font-light;
            font-size: 21px;
            line-height: 36px;
            letter-spacing: 0.24px;
            color: #121212;
            margin-bottom: 28px;
            @media(max-width: 992px){
                font-size: 17px;
                line-height: 28px;
                letter-spacing: 0.24px;
            }
        }
        a.btnHero{
            @include button-primary;
            display: inline-block;
        }
    }
    &__landscape{
        margin-top: 38%;
        position: relative;
        @media(max-width: 992px){
            margin-top: 0;
        }
    }

    &__landscape{
        &--sand{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-landscap-sand.svg");
                width: 111.93%;
                max-width: 111.93%;
                height: auto;
                left: -20%;
                top: -21%;
                position: absolute;
                @media(max-width: 992px){
                    content: url("../images/form-hero-landscap-sand-mobile.svg");
                    width: 100%;
                    max-width: 98.74%;
                    left: inherit;
                    right: -34%;
                    top: -8.5%;
                }
            }
        }
        &--purple{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-landscap-purple.svg");
                width: 111.93%;
                max-width: 111.93%;
                height: auto;
                left: -20%;
                top: -21%;
                position: absolute;
                @media(max-width: 992px){
                    content: url("../images/form-hero-landscap-purple-mobile.svg");
                    width: 100%;
                    max-width: 98.74%;
                    left: inherit;
                    right: -34%;
                    top: -8.5%;
                }
            }
        }
    }

    &__portrait{
        &--sand{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-portrait-sand.svg");
                width: 108%;
                max-width: 108%;
                height: auto;
                left: -22%;
                top: 15%;
                position: absolute;
                @media(max-width: 992px){
                    content: url("../images/form-hero-portrait-sand.svg");
                    width: 100%;
                    max-width: 98.74%;
                }
            }
        }
        &--purple{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-portrait-purple.svg");
                width: 108%;
                max-width: 108%;
                height: auto;
                left: -22%;
                top: 15%;
                position: absolute;
                @media(max-width: 992px){
                    content: url("../images/form-hero-portrait-purple.svg");
                    width: 100%;
                    max-width: 98.74%;
                }
            }
        }
    }

    &--horizontal{
        .row {
            position: relative;
        }
        &__img {
            .img{
                margin-bottom: 0;
                @media(max-width: 992px) {
                    margin-bottom: 20px;
                }
            }

        }
        &__info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        &.right-image{
            .row{
                @media (max-width: 992px) {
                    flex-direction: column-reverse;
                }
            }
        }

    }


    &--horizontal--sand{
        .hero--horizontal__img{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-horizontal-sand.svg");
                width: 100%;
                max-width: 79%;
                height: auto;
                right: -31%;
                top: -5%;
                position: absolute;
                @media(max-width: 992px){
                    width: 100%;
                    max-width: 98.74%;
                    left: inherit;
                    right: -34%;
                    top: 0%;
                }
            }
        }
        &.right-image{
            .hero--horizontal__img{
                &:before{
                    right: inherit;
                    left: -20%;
                    transform: scaleX(-1);
                }
            }
        }
    }
    &--horizontal--purple{
        .hero--horizontal__img{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-horizontal-purple.svg");
                width: 100%;
                max-width: 79%;
                height: auto;
                right: -31%;
                top: -5%;
                position: absolute;
                @media(max-width: 992px){
                    width: 100%;
                    max-width: 98.74%;
                    left: inherit;
                    right: -34%;
                    top: 0%;
                }
            }
        }
    }


}

