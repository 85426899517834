.imageBlocks {
    margin-bottom: 100px;

    @media(max-width: 992px){
        margin-bottom: 47px;
    }

    &__header{
        margin-bottom: 85px;

        @media(max-width: 992px){
            margin-bottom: 48px;
        }

        h2{
            color: $brandPurple;
            text-align: center;
            font-family: $font-regular;
            font-size: 48px;
            line-height: 100%;
            word-break: break-word;

            @media(max-width: 992px){
                font-size: 24px;
                line-height: 100%;
            }
        }

        p{
            font-family: $font-light;
            font-size: 22px;
            line-height: 36px;
            letter-spacing: 0.24px;
            color: #121212;
            margin-top: 25px;
            margin-bottom: 0;
            text-align: center;

            @media (max-width:1680px) {
                font-size: calc(12px + 0.784vw);
                line-height: 2.38vw;
                margin-top: 23px;
            }

            @media(max-width: 992px){
                font-size: 17px;
                line-height: 28px;
                letter-spacing: 0.24px;
                margin-top: 16px;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 25%;

            @media(max-width: 992px){
                width: 50%;
                margin-bottom: 30px;
                justify-content: start;
            }

            .imgWrapper{
                width: 44%;
                margin-right: auto;
                margin-bottom: 36px;
                margin-left: auto;

                @media(max-width: 992px){
                    width: 57%;
                    margin-bottom: 25px;
                }

                img{
                    width: 100%;
                }
            }

            .info{
                width: 61%;
                margin-right: auto;
                margin-left: auto;
                text-align: center;

                h3{
                    font-family: $font-semiBold;
                    font-size: 28px;
                    color: #000000;

                    @media(max-width: 992px){
                        width: 100%;
                        font-size: 14px;
                        line-height: 100%;
                    }
                }
            }
        }
    }
}
