// CORE ELEMENTS

// Brand
$brandOrange: #fff3e8;
$brandPurple: #361E40;
$brandRed: #E97C5D;
$darkGreen: #436A5F;
$lightGreen: #7DA27F;
$lightPurple: #FAF5FE;
$red: #C25839;
$orange: #F68D6F;
$lightOrange: #FBCFB7;
$lightOrange2: #EFCDAE;
$pink: #FF5867;
$yellow: #FFF5C5;
$brandPurple-h: #655573;
$blue: #63CDD4;

$lightGrey: #E2E2E7;
$lightGrey2: #F5F5F5;
$grey: #D6D6D6;
$grey2: #AFB2B5;
$darkGrey: #5A5A5A;
// Text
$textDark1: #121212;
$textDark2: #222222;
$textDark3: #353637;
//$textDark3: #2b2b2c;
//$textDark4: #3b3b3b;
$purpleDark: #361E40;
$link-blue: #3C35F2;
$blueLight: #E8E7FD;
$yellowBG:#FDEBB0;


body {
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .page{
    overflow: hidden;
  }
  &.bgError404{
    background: $brandOrange;
  }
  &.bgBanner{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fff3e8+0,fff3e8+100&1+71,0+100 */
    background: -moz-linear-gradient(top,  rgba(255,243,232,1) 0%, rgba(255,243,232,1) 71%, rgba(255,243,232,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,243,232,1) 0%,rgba(255,243,232,1) 71%,rgba(255,243,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,243,232,1) 0%,rgba(255,243,232,1) 71%,rgba(255,243,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff3e8', endColorstr='#00fff3e8',GradientType=0 ); /* IE6-9 */
    background-size: 100% 1480px;
    background-repeat: no-repeat;
    @media(max-width: 992px){
      background-size: 100% 938px;
    }
  }

  &.bgBannerLightPurple{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#faf5fe+0,faf5fe+100&1+71,0+100 */
    background: -moz-linear-gradient(top,  rgba(250, 245, 254,1) 0%, rgba(250, 245, 254,1) 71%, rgba(250, 245, 254,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(250, 245, 254,1) 0%,rgba(250, 245, 254,1) 71%,rgba(250, 245, 254,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(250, 245, 254,1) 0%,rgba(250, 245, 254,1) 71%,rgba(250, 245, 254,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#faf5fe', endColorstr='#00faf5fe',GradientType=0 ); /* IE6-9 */
    background-size: 100% 1480px;
    background-repeat: no-repeat;
    @media(max-width: 992px){
      background-size: 100% 938px;
    }
  }

  &.overflowHidden{
    overflow: hidden;
  }


  &.bgBubble{
    &:before{
      z-index: -1;
      content: '';
      background-image: url("../images/form-title.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 60%;
      max-width: 60%;
      height: 90%;
      right: 10px;
      top: 0%;
      position: absolute;
      pointer-events: none;
      @media(max-width: 992px){
          background-image: url("../images/form-title-m.svg");
          width: 100%;
          height: 100%;
          max-width: 90%;
          right: 0;
          top: 50px;

          background-position: top right;
      }
  }
  }


}

h1 {
  font-size: 6.57rem;// 108px
  @media(max-width: 991px) {
    font-size: 3rem;// 48px
  }
}
h2 {
  font-size: 5.5rem; // 88px
}
h3 {
  font-size: 4.5rem; // 72px
  @media(max-width: 991px) {
    font-size: 2rem; // 32px
  }
}
h4 {
  font-size: 3rem; // 48px
}
h5 {
  font-size: 2rem; // 32px
 @media(max-width: 991px) {
    font-size: 1.25rem;
  }
}
h6 {
  font-size: 1.3125rem; // 21
}


a {
  color: $brandPurple;
  text-decoration: underline;
  &:hover {
    color: $brandPurple-h;
  }
}

.block-link-bottom {
  margin-top: 0px;
  margin-bottom: 200px;
  display: inline-block;
  @media(max-width: 992px){
    margin-bottom: 100px;
    padding-top: 20px;
  }
}

.link-b-orange{
  color: $brandPurple;
  text-decoration: none;
  border-bottom: 2px solid $orange;
  &:hover {
    color: $brandPurple-h;
    text-decoration: none;
  }
}
.link-b-blue{
  text-decoration: none;
  border-bottom: 2px solid $link-blue;
  &:hover, &:active, &:focus, &:focus-within {
    font-family: $font-semiBold;
  }
}
.line-grey {
  width: 100%;
  border-top: 1px solid $grey;
}
//Utilities

.divide + .detailed-info{
  margin-top: 80px;
}

.line-article {
  width: 100%;
  border-top: 1px solid $grey;
  margin-bottom: 60px;
  @media (max-width: 992px) {
    margin-bottom: 60px;
  }
  &.part{
    margin-bottom: 80px;
    @media (max-width: 992px) {
      margin-bottom: 60px;
    }
  }
}

.mb-155{
  margin-bottom: 155px;
}
.mb-35{
  margin-bottom: 35px;
}

// Autozoom fix on safari mobile
@media (max-width: 992px) {
  body.ios{
    select,
    textarea,
    // input[type="text"],
    // input[type="number"],
    // input[type="tel"],
    // input[type="password"],
    // input[type="email"],
    input {
      font-size: 16px!important;
    }
  }
}
// Autozoom fix on safari mobile
.bodyBlock {
  overflow: hidden!important;
}

.app-cookie-line{
  z-index: 999999;
}

// Loading background animation
.loading-background{
  min-width: 100px;
  min-height: 30px;
  text-indent: -9999px;
  white-space: nowrap;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animatedBackground;
  animation-timing-function: linear;
  background: #f6f7f8;
  // background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #ddd2e6 0%, #a08cb1 15%, #ddd2e6 30%);
  background-size: 1000px 1000px;
}

@keyframes animatedBackground{
  0%{
      background-position: -1000px 0
  }
  100%{
      background-position: 1000px 0
  }
}

// Cookie Declaration - NKI-605
.CookieDeclaration {
  margin-bottom: 100px;
  & > div {
      margin-left: calc(35.714% + 15px)!important;
      width: calc(57.1428% - 16px);
      @media (max-width: 992px) {
          margin-left: 0!important;
          width: 100%;
      }
      p,ul,ol,h3,h4,h5,h6{
         margin-left: 0;
         width: 100%;
      }
  }
  .CookieDeclarationTableCell{
      font-size: 14px
  }
}


// Calandly //
#calendly-modal{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  height: 100dvh;
  border: none;
  max-width: 100vw;
  max-height: 100%;
  max-height: 100dvh;
  padding: 0  0 0 10px;
  overflow: hidden;
  z-index: 9999;
  background: #ffffff;

  &::backdrop {
    display: none;
  }

  &.hide{
    display: none;
    pointer-events: none;
  }

  &.show{
    display: block;
    pointer-events: all;
  }

  .videoWrapper{
    width: 100%;
    height: 100%;
  }

  .close-calendly-modal{
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 2;
    border: none;
    background: #505960;
    transition: all 160ms;
    padding: 10px;
    border-radius: 45px;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
      transform: scale(1.1);
    }

    @media (max-width: 660px) {
      left: 10px;
      top: 10px;
      right: initial;
      border-radius: 35px;
      height: 35px;
      width: 35px;
    }

    img{
      filter: brightness(100);
      width: 16px;
      max-width: 100%;
    }

  }

}

.bg-blue-light {
  background-color: $blueLight!important;
}
