.blogPosts{
    margin-bottom: 120px;
    @media(max-width: 992px){
        margin-bottom: 52px;
    }
    .line-article{
        @media (max-width:1680px) {
            margin-bottom: 48px;
        }
    }
    &__title{
        margin-bottom: 60px;
        @media (max-width:1680px) {
            margin-bottom: 48px;
        }
        @media(max-width: 992px){
            margin-bottom: 32px;
        }
        h2{
            font-family: $font-book;
            font-size: 48px;
            line-height: 56px;
            letter-spacing: -0.44px;
            color: $purpleDark;
            @media (max-width:1680px) {
                font-size: 2.145vw;
                line-height: 2.86vw;
                letter-spacing: -0.44px;
            }
            @media(max-width: 992px){
                font-size: 28px;
                line-height: 36px;
                letter-spacing: 0;
            }
        }
    }
    &.frontPage{
        .blogPosts__title{
            margin-bottom: 80px;
            @media(max-width: 992px){
                margin-bottom: 32px;
            }
            h2{
                font-size: 60px;
                line-height: 72px;
                @media (max-width:1680px) {
                    font-size: 3.5749vw;
                    line-height: 4.289vw;
                }
                @media(max-width: 992px){
                    font-size: 28px;
                    line-height: 36px;
                }
            }
        }
    }
    &__sliderWrapper{
        &--slider{
            .slick-list{
                overflow: visible!important;
            }
            .slick-track{
                display: flex;
                align-items: flex-end;
            }
            *{
                outline: none;
            }
            margin-bottom: 80px;
            @media(max-width: 992px){
                margin-bottom: 48px;
            }
            .item{
                // width: 100%!important;
                // max-width: 33.2%!important;

                @media(max-width: 992px){
                    // max-width: 33.2%!important;
                }

                .content{
                    margin-right: 20%;
                    @media(max-width: 992px){
                        margin-right: 6.6%;
                    }
                }
                a{
                    text-decoration: none;
                }
                .imgWrapper{
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 24px;
                    @media(max-width: 992px){
                        margin-bottom: 20px;
                    }
                    img{
                        width: 100%;
                        transition: all 250ms;
                    }
                }
                .info{
                    height: 110px;
                    .date{
                        margin-bottom: 8px;
                        font-family: $font-light;
                        font-size: 18px;
                        line-height: 28px;
                        display: flex;
                        align-items: center;
                        color: #ACACAC;
                        @media(max-width: 992px){
                            font-size: 15px;
                            line-height: 22px;
                            letter-spacing: 0.24px;
                        }
                    }
                    .title{
                        font-family: $font-book;
                        font-size: 25px;
                        line-height: 36px;
                        letter-spacing: -0.24px;
                        color: #121212;

                        display: block;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @media(max-width: 992px){
                            font-size: 20px;
                            line-height: 28px;
                        }
                    }
                }
                &.blogSpin{
                    .content{
                        position: relative;
                        &:after{
                            @include background-image("./blog-icon.svg", 162, 162);
                            position: absolute;
                            right: -81px;
                            top: 16%;
                            -webkit-animation: spin 12500ms infinite linear;
                            animation: spin 12500ms infinite linear;
                            @media(max-width: 768px ){
                                @include background-image("./blog-icon.svg", 100, 100);
                                right: -50px;
                            }
                        }

                        @-webkit-keyframes spin {
                            0% {
                                -webkit-transform: rotate(0deg);
                                transform: rotate(0deg);
                            }
                            100% {
                                -webkit-transform: rotate(359deg);
                                transform: rotate(359deg);
                            }
                        }
                        @keyframes spin {
                            0% {
                                -webkit-transform: rotate(0deg);
                                transform: rotate(0deg);
                            }
                            100% {
                                -webkit-transform: rotate(359deg);
                                transform: rotate(359deg);
                            }
                        }


                    }
                }
                &:hover{
                    img{
                        opacity: .9;
                    }
                }
            }
        }
        &--button{
            text-align: center;
            a{
                @include button-secondary;
                display: inline-block;
                text-decoration: none;
            }
        }
        .slick-track{
            margin-left: unset !important;
            margin-right: unset !important;
        }
    }
}
