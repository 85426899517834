.quoteBig{
    margin-bottom: 200px;
    @media (max-width: 992px) {
        margin-bottom: 100px;
    }
    z-index: 0;
    &__content{
        h3{
            font-family: $font-regular;
            font-size: 56px;
            line-height: 64px;
            letter-spacing: -0.16px;
            color: #F68D6F!important;
            margin-bottom: 32px;
            margin-top: 79px;
            @media (max-width:1680px) {
                font-size: 3.3366vw;
                line-height: 3.8133vw;
            }
            @media (max-width: 1200px){
                font-size: 36px;
                line-height: 44px;
            }
            @media (max-width: 992px) {
                margin-top: 0;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 16px;
            }
            a{
                color: #F68D6F!important;
                text-decoration: none!important;
                border: none!important;
            }
        }
        .info{
            @media(max-width: 992px){
                max-width: 60%;
            }
            .author{
                @media (max-width: 992px) {
                    height: 22px;
                }
                h4, a{
                    font-family: $font-regular;
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: 0.24px;
                    color: #121212;
                    display: block;
                    @media (max-width: 992px){
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
                h4{
                    a{
                        text-decoration: none;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
            .btnQuote{
                margin-bottom: 30px;
                @media (max-width: 992px) {
                    height: 24px;
                }
                a{
                    font-family: $font-light;
                    text-decoration: none;
                    display: inline;
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: 0.24px;
                    color: $brandPurple;
                    position: relative;
                    @media (max-width: 992px){
                        font-size: 14px;
                        line-height: 22px;
                    }
                    &:after{
                        content: "";
                        width: 100%;
                        height: 2px;
                        background: rgba(246, 141, 111, .5);
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        @media (max-width: 992px){
                            bottom: -4px;
                            height: 1px;
                        }
                    }
                }
            }

        }
    }
    &__image{
        position: relative;
        @media(max-width: 992px){
            margin-top: -46px;
        }
        &:before{
            z-index: -1;
            content: url("../images/form-quote-big.svg");
            width: 139.35%;
            max-width: 139.35%;
            height: auto;
            left: -54%;
            top: -6.5%;
            position: absolute;

            @media(max-width: 768px){
                width: 176%;
                max-width: 176%;
                left: -83px;
                top: -83px;
            }
        }

        .eyeWrapper{
            position: absolute;
            top: 61.5%;
            transform: translateY(-50%);
            left: 0;
            margin-left: -70px;
            @include background-image("../images/eye_star.svg", 140, 140);
            display: flex;
            align-items: center;
            justify-content: center;

            @media(max-width: 992px){
                @include background-image("../images/eye_star.svg", 100, 100);
                margin-left: -50px;
                display: flex;
            }

            @media(max-width: 768px){
                @include background-image("../images/eye_star.svg", 92, 92);
                margin-left: -46px;
                top: 66.5%;
                display: flex;
            }

            .eye{
                background-size: 100%;
                @include background-image("../images/eye_socket.svg", 60, 32);
                border-radius: 50%;
                position: relative;
                @media(max-width: 992px){
                    @include background-image("../images/eye_socket.svg", 52, 28);
                }
                @media(max-width: 768px){
                    @include background-image("../images/eye_socket.svg", 40, 21);
                }
            }

            .ball{
                width: 10px;
                height: 10px;
                background: $brandPurple;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                border-radius: 50%;
                @media(max-width: 992px){
                    width: 6.57px;
                    height: 6.57px;
                }
            }

        }

    }

    &.inside-html-area{
        .row{
            padding: 0!important;
        }
        @media (min-width:992px) {
            margin-left: calc(35.714% + 15px);
            width: calc(57.1428% - 16px);
            margin-top: 75px;
            margin-bottom: 100px;
            @media (max-width: 992px) {
                width: 100%;
                margin-left: 0;
            }

            .quoteBig__content{
                padding-right: 60px;
                @media (max-width: 992px) {
                    padding-right: initial;
                }

                h3{
                    width: 100%;
                    margin-left: 0;
                    padding-top: 0;
                    font-size: 34px;
                    line-height: 44px;
                    @media (max-width: 1440px) {
                        font-size: 32px;
                        line-height: 44px;
                    }
                    @media (max-width: 1200px) {
                        font-size: 20px;
                        line-height: 32px;
                    }
                }
                h4{
                    width: 100%;
                    margin-left: 0;
                    padding-top: 0;
                    @media (max-width: 1200px) {

                    }
                }
            }

            .container{
                padding: 0!important;
                width: 100%!important;
            }
            .row{
                margin:0!important;
                padding: 0!important;

                .offset-xs-0, .offset-sm-0, .offset-md-0, .offset-lg-1,
                .offset-xs-8, .offset-sm-8, .offset-md-8, .offset-lg-2{
                    margin-left: 0!important;
                }

                .offset-xs-0.col-xs-14.offset-sm-0.col-sm-14.offset-md-0.col-md-14.offset-lg-1.col-lg-5{
                    max-width: 100%!important;
                    width: calc(100% - 350px)!important;
                    flex: calc(100% - 350px)!important;
                    padding: 0!important;
                    @media (max-width: 1200px) {
                        width: 100%!important;
                        flex: 100%!important;
                    }
                }

                .offset-xs-8.col-xs-8.offset-sm-8.col-sm-8.offset-md-8.col-md-8.offset-lg-2.col-lg-7{
                    max-width: 100%!important;
                    width: 350px!important;
                    flex: 350px!important;
                    padding: 0!important;
                    @media (max-width: 1200px) {
                        width: 192px!important;
                        flex: 192px!important;
                    }
                }
            }
        }
    }

}

.quoteSmall{
    margin-bottom: 200px;
    @media (max-width: 992px) {
        margin-bottom: 100px;
    }
    z-index: 0;

    &__image{
        width: 100%;
        max-width: 192px;
        margin-right: 50px;
        z-index: 0;
        position: relative;
        float: right;

        @media (max-width: 992px) {
            margin-right: 0;
        }

        &:before{
            z-index: -1;
            content: url("../images/form-quote-small.svg");
            width: 200%;
            max-width: 200%;
            height: auto;
            left: -30px;
            top: -70px;
            position: absolute;
        }

    }
    &__content{
        h3{
            font-family: $font-regular;
            font-size: 56px;
            line-height: 64px;
            letter-spacing: -0.16px;
            color: #F68D6F!important;
            margin-bottom: 24px;
            @media (max-width: 1200px){
                font-size: 36px;
                line-height: 44px;
                margin-bottom: 16px;
            }
            @media (max-width: 992px){
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 16px;
            }
            a{
                color: #F68D6F!important;
                text-decoration: none!important;
                border: none!important;
            }

        }
        .info{
            h4{
                font-family: $font-regular;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.24px;
                color: #121212;
                @media (max-width: 992px){
                    font-size: 14px;
                    line-height: 22px;
                }
            }
            a{
                text-decoration: none;
                font-family: $font-light;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.24px;
                color: #121212;
                padding-bottom: 5px;
                border-bottom: thin solid rgba(246,141,111,0.5);
                &:hover{
                    border-bottom: thin solid rgba(246,141,111,1);
                }
                @media (max-width: 992px){
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }

    &.inside-html-area{
        .row{
            padding: 0!important;
        }
        @media (min-width: 992px) {
            margin-left: calc(35.714% + 15px);
            width: calc(57.1428% - 16px);
            margin-top: 75px;
            margin-bottom: 100px;
            @media (max-width: 992px) {
                width: 100%;
                margin-left: 0;
            }

            .quoteSmall__image{
                @media (max-width: 1200px) {
                    margin-right: 0;
                    padding-right: 20px;
                }
            }

            .quoteSmall__content{
                h3{
                    width: 100%;
                    margin-left: 0;
                    font-size: 34px;
                    line-height: 44px;
                    padding-top: 0;
                    @media (max-width: 1200px) {
                        font-size: 20px;
                        line-height: 32px;
                    }
                }
                h4{
                    width: 100%;
                    margin-left: 0;
                    padding-top: 0;
                    @media (max-width: 1200px) {

                    }
                }
            }


            .container{
                padding: 0!important;
                width: 100%!important;
            }
            .row{
                margin:0!important;
                padding: 0!important;

                .col-xs-10.col-lg-10{
                    max-width: 100%!important;
                    width: calc(100% - 238px)!important;
                    flex: calc(100% - 238px)!important;
                    padding: 0!important;
                    @media (max-width: 1200px) {
                        width: calc(100% - 192px)!important;
                        flex: calc(100% - 192px)!important;
                    }
                }

                .col-xs-6.col-lg-5{
                    max-width: 100%!important;
                    width: 238px!important;
                    flex: 238px!important;
                    padding: 0!important;
                    @media (max-width: 1200px) {
                        width: 192px!important;
                        flex: 192px!important;
                    }
                }
            }
        }
    }

}

.quoteSmallImageText{
    margin-top: 30px;
    margin-bottom: 30px;

    @media (max-width: 992px) {

    }

    &__image{
        width: 100%;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;

        @media (max-width: 992px) {

        }
        img {
            max-width: 260px;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 50%;
            max-width: 100%;
        }

    }
    &__content{
        color: $purpleDark;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        h3{
            font-family: $font-regular;
            font-style: italic;
            font-weight: 450;
            font-size: 34px;
            line-height: 44px;
            color: $purpleDark;
            @media (max-width: 992px){
                font-size: 18px;
                line-height: 23px;
            }
            a{
                color: inherit!important;
                text-decoration: none!important;
                border: none!important;
                font-family: $font-regular!important;
            }
        }

    }
    &__name {
        font-style: italic;
        color: $purpleDark;
        font-weight: 300;
        font-size: 18px;
        margin-top: 12px;
        &::before {
            content: ' -';
        }
    }

    &.inside-html-area{
        .row{
            padding: 0!important;
        }
        @media (min-width: 992px) {
            margin-left: calc(35.714% + 15px);
            width: calc(57.1428% - 16px);
            @media (max-width: 992px) {
                width: 100%;
                margin-left: 0;
            }

            .quoteSmallImageText__content{
                padding-right: 20px;
                @media (max-width: 992px) {
                    padding-right: initial;
                }

                h3{
                    width: 100%;
                    margin-left: 0;
                    padding-top: 0;
                    @media (max-width: 1200px) {
                        font-size: 18px;
                        line-height: 23px;
                    }
                }
            }


            .container{
                padding: 0!important;
                width: 100%!important;
            }
            .row{
                margin:0!important;
                padding: 0!important;

                .offset-xs-0, .offset-lg-2, .offset-xl-3 {
                    margin-left: 0!important;
                }

                .offset-xs-0.col-xs-11.offset-lg-2.col-lg-8.offset-xl-3.col-xl-6{
                    max-width: 100%!important;
                    width: calc(100% - 238px)!important;
                    flex: calc(100% - 238px)!important;
                    padding: 0!important;
                    @media (max-width: 1200px) {
                        width: calc(100% - 192px)!important;
                        flex: calc(100% - 192px)!important;
                    }
                }

                .col-xs-5.col-lg-4.col-xl-4{
                    max-width: 100%!important;
                    width: 238px!important;
                    flex: 238px!important;
                    padding: 0!important;
                    @media (max-width: 1200px) {
                        width: 192px!important;
                        flex: 192px!important;
                    }
                }
            }
        }
    }

}
