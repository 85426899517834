.studieradgiving{
    z-index: 1001;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    overflow-x: auto;
    right: -100vw;
    -ms-overflow-style: none;
    transition: all 300ms;
    &::-webkit-scrollbar {
      display: none;
    }

    &__wrapper{
        background: #ffffff;
        width: 688px;
        height: 100%;
        float: right;
        overflow: auto;

        @media (max-width: 768px) {
            padding: 85px 30px 85px 30px;
        }

        @media(max-width: 992px){
            width: 100%;
        }

        .contactWrapper{
            padding: 100px 100px 60px 120px;
            background: #FFF3E8;
            @media (max-width: 768px) {
                padding: 0;
                background: transparent;
            }
            .item{
                display: inline-block;
                width: 46.5%;
                max-width: 46.5%;
                margin-right: 6.1%;
                @media(max-width: 992px){
                    width: 100%;
                    max-width: unset;
                    margin-right: 0;
                    display: block;
                    margin-bottom: 30px;
                }
                .title{
                    display: block;
                    font-family: $font-light;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 32px;
                    letter-spacing: 0.24px;
                    color: $brandPurple;
                    opacity: 0.8;
                    @media (max-width: 992px) {
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 4px;
                    }
                }

                .tel{
                    a,
                    span{
                        font-family: $font-regular;
                        font-size: 21px;
                        line-height: 36px;
                        color: $brandPurple;
                        display: inline-block;
                        margin-right: 4px;
                        @media (max-width: 992px) {
                            font-size: 22px;
                            line-height: 32px;
                        }
                    }
                    a{
                        text-decoration: none;
                        @include link-default;
                    }
                }

                .schedule{
                    font-family: $font-regular;
                    font-size: 21px;
                    line-height: 36px;
                    color: $brandPurple;
                    display: inline-block;
                    @media (max-width: 992px) {
                        font-size: 22px;
                        line-height: 32px;
                    }
                }

                &:last-child{
                    margin-right: 0;
                    margin-bottom: 0;
                    .title{
                        text-align: right;
                        @media(max-width: 992px){
                            text-align: left;
                        }
                    }
                    .schedule{
                        text-align: right;
                        display: inline-block;
                        width: 100%;
                        @media(max-width: 992px){
                            text-align: left;
                        }
                    }
                }

            }


        }

        .infoWrapper{
            padding: 40px 124px 0px;
            @media (max-width: 768px) {
                padding: 44px 0 0;
            }
            p{
                font-family: $font-light;
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.24px;
                color: $brandPurple;
                opacity: 0.8;
                @media (max-width: 992px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .formWrapper{
            padding: 36px 124px 80px;
            @media (max-width: 768px) {
                padding: 60px 0 0;
                background: transparent;
            }
            .form-group{
                width: 100%;
                display: inline-block;
                margin-right: 6.5%;
                margin-bottom: 20px;
                @media (max-width: 768px) {
                    width: 100%;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 22px;
                }
                &:last-child{
                    margin-right: 0;
                    @media (max-width: 768px) {
                        margin-right: auto;
                    }
                }
                label{

                    line-height: 28px;
                    font-size: 13px;
                    line-height: 24px;
                    margin-bottom: 4px;
                    @media (max-width: 768px) {
                        font-size: 14px;
                        line-height: 25px;
                        margin-bottom: 8px;
                    }
                }

                .form-control{
                    min-height: 52px;
                    font-size: 17px;
                    line-height: 28px;

                    border-radius: 12px;
                    @media (min-width: 993px) {
                        padding: 12px 24px;
                        border-color: #DBDBDB;
                        &:active, &:focus, &:focus-within {
                            border-bottom-color: $darkGrey;
                        }
                    }


                    @media (max-width: 768px) {
                        font-size: 16px;
                        line-height: 24px;
                        min-height: 53px;
                    }
                }
                textarea.form-control{
                    padding-bottom: 8px;
                }
                .radioItem{
                    // input[type="radio"] {
                    //     display:none;
                    // }
                    // input[type="radio"] + label {
                    //     font-family: $font-regular;
                    //     font-size: 17px;
                    //     line-height: 25px;
                    //     color: #121212;
                    //     margin-bottom: 0;
                    //     @media (max-width: 992px) {
                    //         font-size: 16px;
                    //         line-height: 24px;
                    //     }
                    // }

                    // input[type="radio"] + label span {
                    //     display:inline-block;
                    //     width:24px;
                    //     height:24px;
                    //     margin:0 16px 0 0;
                    //     vertical-align:middle;
                    //     background:url("../images/icons/radio.svg")  0px top no-repeat;
                    //     cursor:pointer;
                    //     background-size: auto 24px;
                    // }

                    // input[type="radio"]:checked + label span {
                    //     background:url("../images/icons/radio.svg") -25px top no-repeat;
                    //     background-size: auto 24px;
                    // }
                    .radio-styled {
                        margin-top: 12px;
                        input[type=radio]{
                            +label{
                                font-size: 17px;
                                line-height: 25px;
                                padding-left: 40px;
                                &:after {
                                    top: 0;
                                }
                            }
                            @media (min-width: 993px) {
                                &:not(:focus) +label:after {
                                    border-color: #DBDBDB;
                                }
                                &:checked{
                                    +label:after {
                                        border-color: #F68D6F;
                                    }

                                }
                            }
                            @media (max-width: 992px) {

                                color: $brandPurple;
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                    }
                    .item{
                        display: inline-block;
                        margin-right: 24px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }


            .btnSend{
                text-align: left;
                margin-top: 20px;
                button{
                    @include button-primary;
                }
            }


        }

        .btnClose{
            padding: 12px;
            position: fixed;
            z-index: 10;
            top: 28px;
            right: -100vw;
            transition: all 350ms;
            @media(max-width: 992px){
                position: absolute;
                top: 20px;
                padding: 15px;
            }
            img{
              width: 24px;
              height: auto;
              @media(max-width: 992px){
                width: 17.5px;
              }
            }
        }

        &--content{


        }

    }
    &.active{
        right: 0;
        .btnClose{
            right: 28px;
            @media(max-width: 992px){
                right: 15px;
            }
        }
        .overlay{
            animation: backgroundFade 300ms 300ms both;
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    @keyframes backgroundFade {
        from {
            background: rgba($color: #000000, $alpha: 0);
        }

        to {
            background: rgba($color: #000000, $alpha: .5);
        }
    }
}
