// Image aspect ratio
.img {
  position: relative;
  width: 100%;
  overflow: hidden;

  img, > iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  &.aspectRatio--1x1 {
    padding-top: calc((1 / 1) * 100%);
  }
  &.aspectRatio--4x3 {
    padding-top: calc((3 / 4) * 100%);
  }
  &.aspectRatio--16x9 {
    padding-top: calc((9 / 16) * 100%);
  }
  &.aspectRatio--360x500 {
    padding-top: calc((500 / 360) * 100%);
  }
  &.aspectRatio--400x272 {
    padding-top: calc((272 / 400) * 100%);
  }
  &.aspectRatio--460x313 {
    padding-top: calc((313 / 460) * 100%);
  }
  &.aspectRatio--460x640 {
    padding-top: calc((640 / 460) * 100%);
  }
  &.aspectRatio--560x397 {
    padding-top: calc((397 / 560) * 100%);
  }
  &.aspectRatio--560x687 {
    padding-top: calc((687 / 560) * 100%);
  }
  &.aspectRatio--660x448 {
    padding-top: calc((448 / 660) * 100%);
  }
  &.aspectRatio--660x834 {
    padding-top: calc((834 / 660) * 100%);
  }
  &.aspectRatio--760x498 {
    padding-top: calc((498 / 760) * 100%);
  }
  &.aspectRatio--860x586 {
    padding-top: calc((586 / 860) * 100%);
  }
  &.aspectRatio--960x633 {
    padding-top: calc((633 / 960) * 100%);
  }
  &.aspectRatio--1360x787 {
    padding-top: calc((787 / 1360) * 100%);
  }
  &.aspectRatio--1380x840 {
    padding-top: calc((840 / 1380) * 100%);
  }
  &.aspectRatio--1440x940 {
    padding-top: calc((940 / 1440) * 100%);
  }
  &.aspectRatio--1363x789 {
    padding-top: calc((789 / 1363) * 100%);
  }
  &.aspectRatio--192x259 {
    padding-top: calc((259 / 192) * 100%);
  }
  &.aspectRatio--132x88 {
    padding-top: calc((88 / 132) * 100%);
  }
  &.aspectRatio--860x537 {
    padding-top: calc((537 / 860) * 100%);
  }
  &.aspectRatio--272x186 {
    padding-top: calc((186 / 272) * 100%);
  }
  &.aspectRatio--545x561 {
    padding-top: calc((561 / 545) * 100%);
  }
}
