$grid-columns: 16 !default;
$grid-gutter-width: 40px !default;

$grid-breakpoints: (
  xxs: 0px,
  xs: 320px, // Extra small
  sm: 576px, // Small
  md: 768px, // Medium
  lg: 993px, // Large
  xl: 1200px // Extra large
) !default;

$container-max-widths: (
  sm: 540px, // Small
  md: 720px, // Medium
  lg: 960px, // Large
  xl: 1616px // xl: 1560px // Extra large
) !default;

.container {
  @media (min-width: 1200px){
    padding-right: 48px!important;
    padding-left: 48px!important;
  }

}

.row{
  @media (max-width: 992px){
    padding: 0 20px!important;
  }
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col, .col-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs, .col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl, .col-xl-auto{
  @media (max-width: 992px){
    padding: 0 10px!important;
    .row{
      margin: 0 -30px!important;
    }
  }
}
