.studiePageHeader{
    margin-bottom: 31px;
    @media (max-width: 992px) {
        margin-bottom: 22px;
    }
    &__title{
        h1{
            font-family: $font-regular;
            font-size: 64px;
            line-height: 83px;
            color: $purpleDark;
            @media (max-width:1680px) {
                // font-size: 4.289vw;
                // line-height: 5.2433vw;
            }
            @media (max-width: 992px) {
                font-size: 36px;
                line-height: 47px;
            }
        }
    }
}

.breadcrumbsStudie{
    margin-bottom: 48px;
    @media (max-width: 992px) {
        margin-bottom: 24px;
    }
    a{
        display: inline-block;
        font-family: $font-light;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        color: #B4B4B4;
        margin-right: 12px;
        text-decoration: none;
        &:last-child{
            color: #353637;
            text-decoration: underline;
        }
        @media (max-width: 992px) {

        }
    }
}

.studiePageHeaderBasic{
    margin-bottom: 86px;
    @media (max-width: 992px) {
        margin-bottom: 44px;
    }
    &__title{
        font-family: $font-regular;
        font-size: 72px;
        line-height: 88px;
        letter-spacing: -0.88px;
        color: $brandPurple;
        @media (max-width: 992px) {
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 40px;
        }
    }
    &__item{
        .label{
            font-family: $font-light;
            font-size: 28px;
            line-height: 44px;
            color: #B3B3B3;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
            &.label-select{
                @media (max-width: 992px) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
        .item,
        .select{
            display: block;
            font-family: $font-regular;
            font-size: 28px;
            line-height: 44px;
            color: #121212;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
        }
        .item{
            position: relative;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        }
        .item.help{
            padding-right: 35px;
            span{
                position: relative;
                .help {
                    padding: 0;
                    border-radius: 50%;
                    border: none;
                    background: #FBCFB7;
                    @include background-image("./icons/icon-question.svg", 20, 20);
                    position: absolute;
                    bottom: 7px;
                    right: -32px;
                    cursor: pointer;
                    &:active,
                    &:focus{
                        outline: 0;
                    }
                    @media (max-width: 992px) {
                        bottom: 3px;
                        @include background-image("./icons/icon-question.svg", 16, 16);
                        right: -22px;
                    }
                }
            }

        }
    }
}

.courseType{
    // margin-bottom: 34px;
    margin-bottom: 50px;
    position: relative;
    @media (max-width: 992px) {
        margin-bottom: 44px;
    }

    .offset-lg-1.col-lg-7{
        @media (max-width: 992px) {
            min-height: 90px;
        }
    }

    &__background{
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translateX(-50%);

        @media (max-width: 992px) {
            width: 100vw;
            max-width: 100vw;
            padding: 0;
        }

        .row, .row > div{
            height: 100%;
        }
        .row > div{
            background: $blueLight;
        }
    }

    .btnFloatWrapper{
        z-index: 0;
    }
    .btn-desktop{
        @media (max-width: 992px) {
            display: none;
        }
    }
    .btn-mobile{
        display: none;
        @media (max-width: 992px) {
            display: flex;
        }
    }

    &__column{
        padding-top: 43px;
        padding-bottom: 17px;
        @media (min-width: 993px) {
            display: flex;
            flex-direction: column;
        }
        @media (max-width: 992px) {
            padding-top: 30px!important;
            padding-bottom: 30px!important;
        }
        &:first-child{
            padding-bottom: 43px;
        }
        .btn-desktop {
            margin-top: auto;
        }
        &.offset-lg-1.col-lg-5{
            @media (max-width: 992px) {
                padding: 0!important;
            }
        }
        &.desktop{
            @media (max-width: 992px) {
                display: none;
            }
        }
        &.mobile{
            display: none;
            flex-wrap: wrap;
            @media (max-width: 992px) {
                display: flex;
            }
            .courseType__item{
                width: 50%;
                &:nth-child(1),
                &:nth-child(2){
                    width: 100%;
                }
                &.select,
                &.studietidSection{
                    margin-bottom: 0;
                }
            }
        }
    }


    .col-lg-6.col-xs-8.courseType__column.desktop{
        @media (max-width: 1200px) {
            flex: 0 0 31.5%!important;
            max-width: 31.5%!important;
        }
    }
    .col-lg-3.col-xs-8.courseType__column.desktop{
        @media (max-width: 1200px) {
            flex: 0 0 24.75%!important;
            max-width: 24.75%!important;
        }
    }


    .imageFeature{
        max-width: 88%;
        max-width: 260px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 80px 0;

        @media (max-width: 992px) {
           display: none;
        }
        img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1/1;
            border-radius: 50%;
        }
        // .maskImage{
        //     position: relative;
        //     width: 100%;
        //     overflow: hidden;
        //     padding-top: calc((1 / 1) * 100%);
        //     z-index: 0;
        //     @media (max-width: 992px) {
        //         padding-top: calc((162 / 414) * 100%);
        //     }
        //     .thisImage{
        //         position: absolute;
        //         top: 50%;
        //         left: 50%;
        //         width: calc(100% - 2px);
        //         height: calc(100% - 2px);
        //         object-fit: cover;
        //         transform: translate(-50%, -50%);
        //         z-index: 1;
        //     }
        //     .thisMask{
        //         z-index: 2;
        //         position: absolute;
        //         top: 50%;
        //         left: 50%;
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //         transform: translate(-50%, -50%);
        //         @media (max-width: 992px) {
        //             display: none;
        //         }
        //     }
        // }
    }

    .hideCourseItemMenu{
        @media (max-width: 992px) {
            display: none;
        }
    }
    &__item{
        margin-bottom:26px;

        @media (max-width: 992px) {
            margin-bottom: 20px;
        }
        .courseType__item:last-child{
            margin-bottom: 0;
        }
        .label-wrapper{
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        .label{
            font-family: $font-regular;
            font-size: 17px;
            line-height: 26px;
            color: $purpleDark;
            position: relative;
            @media (max-width: 992px) {
                font-size: 16px;
                line-height: 22px;
            }
            &.label-select{
                @media (max-width: 992px) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
        .percentage-icon{
            width: 26px;
            margin-left: 10px;
            @media (max-width: 1200px) {
                width: 20px;
                margin-left: 5px;
            }
            @media (max-width: 992px) {
                width: 20px;
                margin-left: 10px;
            }
        }
        .item,
        .select,
        .item a{
            display: block;
            font-family: $font-regular;
            font-size: 24px;
            line-height: 26px;
            color: #111111;
            b{
                font-weight: 400;
            }
            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 22px;
            }
        }
        .item.select{
            @media (max-width: 992px) {
                // font-size: 20px;
                // line-height: 28px;
                // padding-bottom: 9px;

            }
            span{
                display: initial;
                @include link-default;
                &:hover{
                    span{
                        border-bottom: 2px solid  rgba(246, 141, 111, 0);
                    }
                }
                span{
                    position: relative;
                    border: none;
                    &:after{
                        @include background-image("./icons/arrow-down.svg", 16, 19);
                        position: absolute;
                        right: -33px;
                        top: 8px;
                        @media (max-width: 992px) {
                            @include background-image("./icons/arrow-down.svg", 12, 14);
                            right: -21px;
                            top: 5px;
                        }
                    }

                }
            }
            .item-selected{
                cursor: pointer;
                .selectedSelect{
                    &:after{
                        content: ":";
                    }
                }
                .selectedOption{
                    &.invalid{
                        color: red;
                    }
                }
            }
        }
        .link{
            display: block;
            font-family: $font-regular;
            font-size: 16px;
            line-height: 20px;
            color: #493B55;
            @media (max-width: 992px) {
                font-size: 12px;
                margin-left: 5px;
            }
            a{
                display: inline-block;
                font-family: $font-light;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.24px;
                text-decoration-line: underline;
                color: #B4B4B4;
            }
        }
        .item{
            position: relative;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            @media (max-width: 992px) {
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
            #oldCoursePrice{
                display: inline-block;
                color: #000000;
                font-size: 20px;
                line-height: 100%;
                margin-top: 8px;
                margin-bottom: 8px;
                position: relative;
                @media (max-width: 992px) {
                    font-size: 16px;
                }
                &:after{
                    content: "";
                    width: calc(100% + 4px);
                    height: 2px;
                    background-color: #000000;
                    position: absolute;
                    top: calc(50% - 1px);
                    left: -2px;
                }
            }
            #coursePrice{
                display: block;
                font-size: 24px;
                line-height: 100%;
                margin-bottom: 3px;
                @media (max-width: 992px) {
                    font-size: 18px;
                }
            }
        }
        .item.help{
            padding-right: 35px;
            span{
                position: relative;
                .help {
                    padding: 0;
                    border-radius: 50%;
                    border: none;
                    color: #ffffff;
                    background: $purpleDark;
                    @include background-image("./icons/icon-question-new.svg", 27, 27);
                    position: absolute;
                    top: -30px;
                    right: -27px;
                    cursor: pointer;
                    &:active,
                    &:focus{
                        outline: 0;
                    }
                    @media (max-width: 992px) {
                        @include background-image("./icons/icon-question-new.svg", 20, 20);
                        top: -3px;
                        right: 9px;
                    }
                }
            }

        }

        .label.help{
            padding-right: 35px;
            position: relative;
            display: block;
            > span{
                position: relative;
                display: inline-block;
            }
            .help {
                padding: 0;
                border-radius: 50%;
                border: none;
                color: #ffffff;
                // background: $purpleDark;
                @include background-image("./icons/icon-question-new.svg", 27, 27);
                position: absolute;
                top: -3px;
                left: calc(100% + 10px);
                cursor: pointer;
                &:active,
                &:focus{
                    outline: 0;
                }
                @media (max-width: 1200px) {
                    @include background-image("./icons/icon-question-new.svg", 20, 20);
                    top: 0;
                    left: calc(100% + 5px);
                }
                @media (max-width: 992px) {
                    @include background-image("./icons/icon-question-new.svg", 20, 20);
                    top: 0;
                    left: calc(100% + 10px);
                }
            }

        }

        &.select{
            padding-right: 50px;
            @media (max-width: 992px) {
                padding-right: 20px;
            }
            .options{
                display: none;
                div{
                    display: block;
                    margin-bottom: 10px;
                    span{
                        cursor: pointer;
                        border-bottom: 2px solid rgba(246,141,111,0);
                        &.selected{
                            border-bottom: 2px solid rgba(246,141,111,1);
                        }
                    }

                }
                .subSelect{
                    // margin-bottom: 20px;
                    // &:last-child{
                    //     margin-bottom: 0;
                    // }
                    .title{
                        margin-bottom: 0;
                        display: inline-block;
                        > span{
                            border-bottom: 2px solid rgba(246,141,111,0);
                            cursor: pointer;
                            padding-right: 25px;
                        }

                        span span{
                          display: inline-block;
                          &:after{
                            @include background-image("./icons/arrow-down.svg", 13, 15);
                            right: -21px;
                            top: 12px;
                            transform: rotate(0);
                            transition: all 150ms linear;
                            @media (max-width: 992px) {
                                top:7px;
                            }
                          }
                        }
                        &.active{
                            span span{
                                &:after{
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                    .subOptions{
                        padding-left: 20px;
                        margin-bottom: 20px;
                        display: none;
                    }
                }
            }

            &.active{
                z-index: 5;
                // position: relative;
                position: absolute;
                padding: 0;
                width: 100%;
                max-width: 350px;
                @media (max-width: 992px) {
                    padding: 0;
                }
                @media (max-width: 575px) {
                    max-width: 100%;
                }

                &:before{
                    content: "";
                    position: absolute;
                    width: calc(100% + 80px);
                    height: calc(100% + 80px);
                    background: #ffffff;
                    top: -40px;
                    left: -40px;
                    // z-index: -1;
                }

                .overlaySelect{
                    position: fixed;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(18, 18, 18, 0.5);
                    top: 0;
                    left: 0;
                    z-index: -2;
                }

                .item-selected{
                    display: none;
                }

                *{
                    font-size: 21px;
                    line-height: 36px;
                    @media (max-width: 992px) {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
                .options{
                    display: block;
                }
            }
        }

    }
    .col-xs-8.col-lg-8{
        @media (max-width: 992px) {
            padding: 0;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    // CALL TO ACTION BUTTON (course page)
    // .btn-cta{
    //     position: relative;
    //     display: block;
    //     margin-top: 10px;
    //     padding: 14px 60px 14px 32px;
    //     border-radius: 30px;
    //     font-size: 18px;
    //     font-weight: 400;
    //     text-transform: uppercase;

    //     &::after{
    //         content: "";
    //         display: block;
    //         position: absolute;
    //         top: calc(50% - 8px);
    //         right: 28px;
    //         width: 18px;
    //         height: 16px;
    //         background-image: url(../images/./icons/arrow-email.svg);
    //         background-repeat: no-repeat;
    //         background-size: auto;
    //     }

    //     &.btn-brand-red{
    //         color: $brandPurple;

    //         &::after{
    //             background-image: url(../images/./icons/arrow-email-purple.svg);
    //         }
    //     }
    // }
    &--yellow {
        .courseType__background .row > div{
            background-color: $yellowBG;
        }
        ~ .accordionContainer .accordion {
            .card-header {
                background-color: $yellowBG;
            }
        }

    }

}

.featuredImageIngress{
    &__image,
    &__video{
        margin-bottom: 80px;
        width: 100%;
        max-width: calc(93.5% - 15px);
        margin-right: auto;
        margin-left: auto;
        @media (max-width: 992px) {
            margin-bottom: 40px;
            width: 110%;
            max-width: inherit;
        }
        &.basic{
            max-width: calc(88.1% - 15px);
            @media (max-width: 992px) {
                max-width: inherit;
                width: 110%;
            }
        }
    }
    &__video{
        position: relative;
        iframe{
            height: 100%;
        }
        .playVideo{
            background: none;
            border: none;
            @include background-image("./btn-bubble-youtube.svg", 182, 178);
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -89px;
            margin-left: -91px;
            @media (max-width: 992px) {
                @include background-image("./btn-bubble-youtube.svg", 91, 89);
                margin-top: calc(-89px / 2);
                margin-left: calc(-91px / 2);
            }
            &:focus{
                outline: none;
            }
        }
        .thumbnail{
            cursor: pointer;
        }
        .closeVideo{
            background: none;
            border: none;
            background-color: rgba($color: #ffffff, $alpha: 0.7);
            @include background-image("icons/close-menu.svg", 50, 50);
            position: absolute;
            border-radius: 50%;
            background-size: 25px;
            top: 50px;
            right: 50px;
            display: none;
            @media (max-width: 992px) {
                width: 30px;
                height: 30px;
                background-size: 10px;
                top: 20px;
                right: 20px;
            }
            &:hover{
                background-color: rgba($color: #ffffff, $alpha: 1);
            }
            &:focus{
                outline: none;
            }
        }

        .img iframe{
            opacity: 0;
        }

        .img iframe, .thumbnail, .playVideo{transition: all 500ms;}

        .thumbnail,
        .playVideo{
            opacity: 1;
        }

        &.active{
            .img iframe{
                opacity: 1;
            }
            .thumbnail,
            .playVideo{
                opacity: 0;
                pointer-events: none;
            }
            .closeVideo{
                display: block;
            }
        }

    }
    &__ingress{
        margin-bottom: 30px;
        @media (max-width: 992px) {
            margin-bottom: 30px;
        }
        h2,p{
            font-family: $font-regular;
            font-weight: 400;
            font-size: 30px;
            line-height: 36px;
            color: #000000;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
        }
    }
}


.readMoreStudie{
    margin-top: -38px;
    margin-bottom: 82px;
    @media (max-width: 992px) {
        margin-top: 0px;
        margin-bottom: 42px;
    }
    .more,
    .minus{
        @include link-default;
        font-family: $font-light;
        font-size: 25px;
        line-height: 40px;
        letter-spacing: 0.24px;
        color: $brandPurple;
        text-decoration: none;
        display: inline-block;
        @media (max-width: 992px) {
            font-size: 17px;
            line-height: 28px;
        }
    }

    .minus{
        display: none;
    }

    &.active{
        .more{
            display: none;
        }
        .minus{
            display: inline-block;
        }
    }

}

.article{
    + .readMoreStudie.active{
        margin-top: -70px;
        @media (max-width: 992px) {
            margin-top: -20px;
        }
    }
}

.relatedContent.studie{
    @media (max-width: 992px){
        .blogPosts__sliderWrapper--slider {
            margin-bottom: 0;
        }
    }
}

.container-course {
    @media (min-width: 993px) {
        max-width: 1382px;
        margin: auto;
    }
}
