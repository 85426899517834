.accordion {
    border-top: 1px solid $brandPurple;
    margin-bottom: 206px;
    @media (max-width: 992px) {
        margin-bottom: 100px;
    }
    .card {
      border-radius: 0!important;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid $brandPurple;
      &:not(:last-of-type) {
        border: 0;
        border-bottom: 1px solid $brandPurple;
      }
      .card-body {
        padding: 0 0 18px 0;
        margin: 30px 0 0 0;
        // font-family: $font-light;
        font-size: 22px;
        line-height: 29px;
        // letter-spacing: 0.24px;
        color: #000000;
        // background: rgba(211, 235, 220, 0.2);
        @media (max-width: 992px) {
        //   padding: 0 0 22px 0;
        //   margin: -8px 0 0 7%;
        }
        .accordionContent{
            margin-bottom: 30px;
            position: relative;
            @media (max-width: 992px) {
                margin-top: 20px;
                margin-bottom: 6px;
            }
        }
        p {
            font-family: $font-regular;
            font-size: 22px;
            line-height: 29px;

            color: #000000;
            margin-bottom: 24px;
            // &:last-child{
            //     margin-bottom: 0;
            // }
            @media(max-width: 992px){
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 16px;
            }
            a{
                color: #000000;
                display: inline;
                position: relative;
                @include link-default;
            }
        }

        .linksBooks{
            ul, ol{
                padding-left: 20px;
                margin-bottom: 20px;
                li{
                    font-family: $font-regular;
                    font-size: 21px;
                    line-height: 36px;
                    letter-spacing: 0.24px;
                    color: #000000;
                    padding-left: 10px;
                    margin-bottom: 8px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    @media(max-width: 992px){
                        font-size: 15px;
                        line-height: 26px;
                    }

                    a{
                        color: #000000;
                        span{
                            position: relative;
                            &:after{
                            @include background-image("./icons/arrow-links-books.svg", 14, 14);
                            position: absolute;
                            right: -23px;
                            top: 50%;
                            margin-top: -5px;
                            @media (max-width: 992px){
                                @include background-image("./icons/arrow-links-books.svg", 9, 9);
                                right: -17px;
                                margin-top: -3px;
                            }
                            }
                        }
                    }
                    span.book_isbn {
                        display: block;
                        line-height: 20px;
                        font-size: 80%;
                    }
                }
            }
        }

        .box-orange-article{
            // margin-bottom: 0;
            p{
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
      }
      &-header {
            padding: 24px 0;
            background-color: transparent;
            border: none;
            @media(max-width: 992px){
                padding: 20px 0;
            }
            h2,h3 {
                font-family: $font-regular;
                font-size: 21px;
                line-height: 36px;
                display: block;
                width: 100%;
                @media(max-width: 992px){
                    font-size: 17px;
                    line-height: 28px;
                }
                button {
                    color: #000000;
                    text-decoration: none;
                    border: 0;
                    background: transparent;
                    padding: 0;
                    display: block;
                    width: 100%;
                    text-align: left;
                    padding-right: 100px;
                    @media(max-width: 992px){
                        text-align: left;
                        padding-right: 40px;
                    }
                    &:hover,
                    &:focus {
                        text-decoration: none;
                        outline: 0;
                    }
                    &:after {
                        @include background-image("icons/icon-minus.svg", 16, 2);
                        position: absolute;
                        right: 12px;
                        top: 45px;
                        @media(max-width: 992px){
                            top: 29px;
                            right: 0;
                        }
                    }
                    &.collapsed {
                        font-weight: 500;
                        &:after {
                            @include background-image("icons/icon-plus.svg", 16, 16);
                            position: absolute;
                            right: 12px;
                            top: 38px;
                            @media(max-width: 992px){
                                top: 22px;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &.studie{
        width: 100%;
        @media(max-width: 992px){
            width: 100vw;
            // margin-left: 50%;
            // transform: translateX(-50%);
        }
        .card {
            overflow: visible;
            &-header {
                padding: 30px 44px;
                border: none;
                background: $blueLight;
                margin-bottom: 0;
                position: relative;
                & + div {
                }
                @media(max-width: 992px){
                    padding: 20px 0;
                    font-size: 24px;
                    line-height: 31px;
                    // width: 87%;
                }
                h2 {
                    font-family: $font-regular;
                    font-size: 36px;
                    line-height: 42px;
                    color: #000000;
                    display: inline-block;
                    padding-right: 0;
                    position: relative;

                    @media(max-width: 992px){
                        display: block;
                        max-width: 720px;
                        margin: 0 auto;
                        padding: 0 30px;
                        font-size: 24px;
                        line-height: 31px;
                    }

                    button.arrowIcon {
                        color: #000000;
                        &:after {
                            display: none;
                        }
                        &.collapsed {
                            &:after {
                            display: none;
                            }
                        }

                        span{
                            // position: relative;
                            @media (max-width: 992px) {
                                // position: inherit;
                            }
                            &:before{
                                @include background-image("./icons/arrow-accordion-studie-new.svg", 14, 27);
                                position: absolute;
                                right: 0;
                                top: 10px;
                                transform: rotate(180deg);
                                @media (max-width: 992px) {
                                    @include background-image("./icons/arrow-accordion-studie-new.svg", 10, 20);
                                    right: 30px;
                                }
                            }
                        }
                        &.collapsed{
                            span{
                                &:before{
                                    transform: rotate(0deg);
                                }
                            }
                        }

                    }
                }
            }

            .card-body {
                // margin-left: calc(35.714% + 15px);
                // margin-right: calc(6.314% + 15px);
                margin-left: 44px;
                margin-right: 90px;
                padding: 0;

                @media (max-width: 992px) {
                    padding: 0;
                    margin: 0;
                    margin-top: 0;
                    margin-left: 0;
                    margin-right: 0;
                }

                .accordionContent{
                    @media (max-width: 992px) {
                        display: block;
                        max-width: 720px;
                        margin-left: auto;
                        margin-right: auto;
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                }

                // p{
                //     &:last-child{
                //         @media (max-width: 992px) {
                //             margin-bottom: 40px;
                //         }
                //     }
                // }
                ul, ol{
                    margin-bottom: 20px;
                    @media (max-width: 992px) {
                        margin-bottom: 40px;
                    }

                    & + .box-orange-article{
                        margin-bottom: 0;
                        @media (max-width: 992px) {
                            margin-top: 0;
                        }
                    }

                }
                // .box-orange-article{
                //     @media (max-width: 992px) {
                //         margin-top: 0px;
                //     }
                //     p{
                //         &:last-child{
                //             @media (max-width: 992px) {
                //                 margin-bottom: 0px;
                //             }
                //         }
                //     }
                // }
                &.card-body-accordion{
                    margin-left: 44px;
                    margin-right: 44px;
                    @media (max-width: 992px) {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }

                .accordion{
                    border: none;

                    &.SUB-accordion{
                        // margin-bottom: 30px;
                        margin-top: -30px;
                        @media (max-width: 992px) {
                            // margin-bottom: 300px;
                            margin-top: 0;
                        }

                        .card {
                            overflow: visible;
                            border-bottom: 1px solid $brandPurple;

                            .card-header{
                                & + div {
                                    background: transparent;
                                }
                            }

                            &:first-child{
                                .card-header{
                                    @media (max-width: 992px) {
                                        &:after,
                                        &:before{
                                            border-color: transparent;
                                        }
                                    }
                                    h3{
                                        button{
                                            // padding-top: 0;

                                            @media (max-width: 992px) {
                                                // padding-top: 0;
                                            }
                                        }
                                    }
                                }
                            }

                            &:last-child{
                                border-bottom: none;
                                @media (max-width: 992px) {
                                    // border-bottom: 1px solid #D6D6D6;
                                }

                                .card-body{
                                    // margin-bottom: 40px;

                                    @media (max-width: 992px) {
                                        // margin-bottom: 25px;
                                    }
                                }
                            }

                            .card-body{
                                // margin-left: calc(33.3% + 15px);
                                // margin-bottom: 80px;
                                margin-top: 0;
                                margin-left: 0;
                                margin-right: 0px;
                                @media (max-width: 992px) {
                                    margin-left: 0;
                                    margin-right: 0;
                                    // margin-bottom: 50px;
                                }

                                .accordionContent__html-area{
                                    padding: 0;
                                    overflow: visible;

                                    @media(max-width: 992px) {
                                        > *{
                                            display: block;
                                            max-width: 720px;
                                            margin-left: auto;
                                            margin-right: auto;
                                            padding-left: 30px;
                                            padding-right: 30px;
                                        }
                                    }

                                    p, ul, ol, h3, h4, h5, h6, .courseTypeAccordion{
                                        // margin-left: calc(33.3% + 15px);
                                        width: 100%;
                                        // margin-left: 0;
                                        margin: 0 auto;
                                        @media(max-width: 992px) {
                                            width: 100%;
                                        }
                                    }

                                    .box-orange-article{
                                        // margin-left: calc(33.3% + 15px);
                                        // width: calc(68% - 16px);
                                        width: 100%;
                                        @media(max-width: 992px) {
                                            width: 100%;
                                            margin-left: 0;
                                        }
                                    }
                                }
                            }
                            &:first-child{
                                .card-header{
                                    h3 {
                                        button {
                                            &:after {
                                                // top: 9px;
                                                @media(max-width: 992px){
                                                //    top: 17px;
                                                }
                                            }
                                            &.collapsed {
                                                &:after {
                                                    // top: 9px;
                                                    @media(max-width: 992px){
                                                    //    top: 12px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .card-header {
                            padding: 0;
                            background-color: transparent;
                            border: none;
                            @media(max-width: 992px){
                                padding: 0;
                            }
                            // &:after,
                            // &:before{
                            //     background:rgba(211, 235, 220, 0.2);
                            // }


                            h3 {
                                font-family: $font-regular;
                                font-size: 26px;
                                line-height: 34px;
                                color: #000000;
                                position: relative;
                                @media(max-width: 992px){
                                    font-size: 18px;
                                    line-height: 23px;
                                    // width: 87%;

                                    display: block;
                                    max-width: 720px;
                                    margin: 0 auto;
                                    padding: 0 30px;

                                }
                                button {
                                    padding: 30px 30px 30px 0;

                                    @media(max-width: 992px){
                                        padding: 20px 40px 20px 0;
                                    }
                                    small{
                                        font-family: $font-regular;
                                        font-size: 26px;
                                        line-height: 34px;
                                        @media(max-width: 992px){
                                            font-size: 18px;
                                            line-height: 23px;
                                        }
                                    }
                                    &:after {
                                        @include background-image("./icons/arrow-accordion-studie-new.svg", 14, 27);
                                        position: absolute;
                                        right: 0;
                                        top: 40px;
                                        transform: rotate(180deg);
                                        @media (max-width: 992px) {
                                            @include background-image("./icons/arrow-accordion-studie-new.svg", 10, 20);
                                            right: 30px;
                                            top: 24px;
                                        }
                                    }
                                    &.collapsed {
                                        &:after {
                                            top: 34px;
                                            transform: rotate(0deg);
                                            @media (max-width: 992px) {
                                                top: 24px;
                                            }
                                        }
                                    }
                                }
                                span{
                                    &:before{
                                        display: none;
                                    }
                                }
                            }
                        }

                        .tippy-box[data-theme~='NKI'] {
                            @media (max-width: 768px) {
                                width: calc(71vw - 58px);
                            }
                            @media (max-width: 575px) {
                                width: calc(90vw - 58px);
                                margin-left: calc(1vw + 20px);
                            }
                        }

                        .setCourseToCartButton{
                            margin-bottom: 42px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            button{
                                position: relative;
                                display: inline-block;
                                width: auto;
                                height: auto;
                                padding: 20px 58px;
                                border-radius: 40px;
                                white-space: nowrap;
                                transform: none;
                                max-width: 292px;
                                height: 65px;
                                font-size: 18px;
                                line-height: 18px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 18px;
                                letter-spacing: normal;
                                line-height: normal;


                                .errorInfo{
                                    position: absolute;
                                    right: 50%;
                                    transform: translateX(calc(50% - 265px));
                                    bottom: calc(100% - 30px);
                                    white-space: normal;
                                    text-align: left;
                                    width: 100vw;
                                    max-width: 623px;
                                    z-index: 1;
                                    @media(max-width: 1250px) {
                                        transform: translateX(calc(50% + 22px));
                                    }
                                    @media(max-width: 992px) {
                                        position: fixed;
                                        top: initial;
                                        transform: initial;
                                        right: initial;
                                        bottom: 0;
                                        max-width: 100vw;
                                        width: 100vw;
                                    }
                                    .box-orange{
                                        padding: 30px 35px;
                                        @media(max-width: 992px) {
                                            padding: 50px 20px 20px 20px;
                                        }
                                        p,a{
                                            font-size: 16px;
                                            line-height: 26px;
                                            font-family: "SuisseIntl-Light", sans-serif;
                                            font-weight: 300;
                                            margin-bottom: 0!important;
                                        }
                                        a{
                                            padding-bottom: 0;
                                        }
                                        .btn-close{
                                            width: 16px;
                                            height: 16px;
                                            position: absolute !important;
                                            top: 20px;
                                            right: 30px;
                                            display: block;
                                            background-image: url(../images/./icons/close-tooltip.svg);
                                            background-repeat: no-repeat;
                                            background-position: center center;
                                            width: 16px;
                                            height: 16px;
                                            display: none;
                                            background-size: cover;
                                            cursor: pointer;
                                            @media(max-width: 992px) {
                                                display: inline-block;
                                            }
                                        }
                                    }
                                }

                            }
                        }

                    }

                }

            }

        }
    }
    &.faq{
        .card-body {
            margin-top: 0;
            margin-bottom: 18px;
            @media (max-width: 992px) {
                margin-top: 0;
            }
        }
    }
}
.accordion {
    &--basic{
        margin-bottom: 2px;
        .card, .card-header, .card-header button{
            background-color: transparent;
            border: none;

            &:focus {
                outline: none;
            }
        }
        .card-header button{
            padding-right: 35px;
            font-size: 28px;
            line-height: 44px;
            color: #000000;
            border-bottom-width: 2px;
            position: relative;
            text-align: left;
            word-break: break-word;
            @media(max-width: 991px) {
                font-size: 22px;
                line-height: 32px;
            }
            span {
                border-bottom: 2px solid transparent;
                span{
                    position: relative;
                    &:after{
                        content: '';
                        width: 18px;
                        height: 20px;
                        background-image: url(../images/icons/arrow-down-menu.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        display: inline-block;
                        position: absolute;
                        right: -35px;
                        bottom: 10px;
                        transition: all 150ms linear;
                        @media(max-width: 991px) {
                            width: 14px;
                            height: 16px;
                            right: -26px;
                            bottom: 8px;
                        }
                    }
                }
            }

            &[aria-expanded="true"] {

                span {
                    border-color: rgba($color: $orange, $alpha: 0.8);
                    span{
                        &:after{
                            transform: rotate(180deg);
                        }
                    }
                }

            }

            &:hover, &:focus-visible {
                border-color: $orange;
            }
        }
        .card-body {
            padding-bottom: 25px;
            padding-top: 0;
            label {
                width: 100%;

            }
        }
    }
    &--purple {
        // height: 500px;
        margin-bottom: 0!important;
        @media(max-width: 992px) {
            // height: auto;
        }
        @media(min-width: 992px) {
            border-top: none;
        }
        .card {
            &:first-child{
                .card-header h2 button {
                    // padding-top: 6px;
                    @media(max-width: 991px) {
                        padding-top: 20px;
                    }
                }
            }
            &-header {
                padding: 0;
                h2 {

                    @media(max-width: 991px) {
                        width: 100%;
                    }
                    button {
                        font-family: $font-book;
                        font-size: 21px;
                        line-height: 36px;
                        color: #000000;
                        text-align: left;
                        padding: 28px 40px 32px 40px;
                        background-color: rgba($color: $lightPurple, $alpha: 0.5);
                        transition: all 300ms linear;
                        @media(max-width: 991px) {
                            padding: 20px 0 24px;
                            background-color: transparent;
                            font-size: 17px;
                            line-height: 24px;
                            padding-right: 40px;
                        }
                        &[aria-expanded="true"]{
                            padding-bottom: 20px;
                            .btnTitle{
                                a{
                                    border-bottom: 2px solid #F68D6F;
                                    @media(max-width: 991px) {
                                        border-bottom: 2px solid rgba($color: #F68D6F, $alpha: .8);
                                    }
                                }

                            }
                        }
                        &.collapsed{
                            background-color: transparent;
                            &:hover {
                                @media (hover: hover) and (pointer: fine) {
                                    background-color: rgba($color: $lightPurple, $alpha: 0.5);
                                    .btnTitle{
                                        a{
                                            border-bottom: 2px solid #F68D6F;
                                            @media(max-width: 991px) {
                                                border-bottom: 2px solid rgba($color: #F68D6F, $alpha: .8);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.collapsed:after{
                            top: 15px;
                            @media(max-width: 991px) {
                                top: 23px;
                            }
                        }
                        &:after{
                            top: 22px;
                            @media(max-width: 991px) {
                                top: 30px;
                            }
                        }
                        a{
                            text-decoration: none;
                            font-family: $font-book;
                            font-size: 21px;
                            line-height: 36px;
                            color: #000000;
                            display: inline;
                            border-bottom: 2px solid transparent;
                            @media(max-width: 991px) {
                                font-size: 17px;
                                line-height: 24px;
                            }
                        }
                    }
                }
                .card-header__tags{
                    margin-top: 4px;
                        span {
                            font-family: $font-light;
                            font-size: 17px;
                            line-height: 28px;
                            letter-spacing: 0.24px;
                            color: #000000;
                            @media(max-width: 991px) {
                                font-size: 15px;
                                line-height: 24px;
                            }
                            + span {
                                &:before {
                                    content: '';
                                    vertical-align: middle;
                                    background-color: $textDark1;
                                    border-radius: 50%;
                                    margin: 12px;
                                    width: 4px;
                                    height: 4px;
                                    display: inline-block;
                                    opacity: 0.7;
                                    @media(max-width: 991px) {
                                        margin: 8px;
                                    }
                                }
                            }
                        }
                    }
            }
            .card-body {

                background-color: rgba($color: $lightPurple, $alpha: 0.5);
                padding: 0 100px 40px 40px;
                margin-top: 0;
                margin-left: 0;
                p {
                    font-family: $font-light;
                    font-size: 17px;
                    line-height: 28px;
                    letter-spacing: 0.24px;
                    color: #000000;
                    @media(max-width: 991px) {
                        font-size: 15px;
                        line-height: 24px;
                    }
                }
                @media(max-width: 991px) {
                    padding: 0 0 32px;
                    background-color: transparent;
                }
            }
        }
    }
    &--yellow {
        .card-header {
            background-color: $yellowBG;
        }
    }
}

.readMoreStudie{
    &+ .container + .quoteSmall{
        margin-top: 26px;
    }
}


.accordionGroup{
    border-top: 1px solid $brandPurple;
    margin-bottom: 50px;
    position: relative;

    @media(max-width: 992px){
        margin-bottom: 0!important;
        &:after,
        &:before{
            content: "";
            width: 50vw;
            height: 1px;
            background-color: $brandPurple;
            position: absolute;
            top: -1px;
        }

        &:after{
            right: 100%;
        }

        &:before{
            left: 100%;
        }
    }

    &+.btnFloatWrapper{
        margin-top: 0;
        margin-bottom: 160px;
        z-index: 0;
        @media(max-width: 992px){
            margin-top: 50px;
        }
        button{
            margin: 0 auto;
        }
    }

    @media(max-width: 992px){
        margin-bottom: 40px;
    }

    .accordion{
        border-top: none;
        // &:first-child{
        //     border-top: 1px solid #D6D6D6;
        // }
        margin-bottom: 0;

    }

}





.courseTypeAccordion{
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px #361E40 solid;
    z-index: 0;
    @media (max-width: 992px) {
        margin-top: 30px;
        padding-top: 30px;
    }
    & > .row{
        margin: 0!important;
        @media (max-width: 992px) {
            padding: 0!important;
        }
    }
    &__item{
        z-index: 1;
        margin-bottom: 30px;
        @media (max-width: 992px) {
            margin-bottom: 24px;
        }
        &.half{
            float: left;
            width: 50%;
            &:nth-child(2n+1){
                padding-right: 20px;
            }
            &:nth-child(2n){
                padding-left: 20px;
            }
        }
        .label{
            font-family: $font-light;
            font-size: 20px;
            line-height: 28px;
            // color: #000000;
            color: $purpleDark;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }

        }
        .item,
        .item a{
            display: block;
            font-family: $font-regular;
            font-size: 22px;
            line-height: 28px;
            color: #000000;
            b{
                font-weight: 400;
            }
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
        }

        .link{
            display: block;
            a{
                display: inline-block;
                font-family: $font-light;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.24px;
                text-decoration-line: underline;
                color: #000000;
            }
        }
        .item{
            position: relative;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            @media (max-width: 992px) {
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
        .item.help{
            padding-right: 35px;
            span{
                position: relative;
                .help {
                    padding: 0;
                    border-radius: 50%;
                    border: none;
                    background: #FBCFB7;
                    @include background-image("./icons/icon-question.svg", 20, 20);
                    position: absolute;
                    bottom: 7px;
                    right: -32px;
                    &:active,
                    &:focus{
                        outline: 0;
                    }
                    @media (max-width: 992px) {
                        @include background-image("./icons/icon-question.svg", 16, 16);
                        bottom: 3px;
                        right: -22px;
                    }
                }
            }

        }

        &.price{
            .label{
                @media (max-width: 992px) {
                    color: #000000;
                }
                img{
                    width: 26px;
                    @media (max-width: 992px) {
                        width: 20px;
                    }
                }
                span{
                    display: none;
                    font-size: 12px;
                    @media (max-width: 992px) {
                        display: inline-block;
                    }
                }
            }
            .item{
                span{
                    display: block;
                }

                #oldCoursePrice{
                    font-size: 18px;
                    display: inline-block;
                    position: relative;
                    @media (max-width: 992px) {
                        font-size: 15px;
                    }
                    &:after {
                        display: inline-block;
                        content: "";
                        width: calc(100% + 4px);
                        height: 2px;
                        background-color: #000000;
                        position: absolute;
                        top: calc(50% - 1px);
                        left: -2px;
                    }
                }
            }
            .link{
                a{
                    line-height: 100%;
                    border: none;
                }
                @media (max-width: 992px) {
                    display: none;
                }
            }
        }

    }

    .btnBuy{
        display: inline-block;
        text-decoration: none;
        border: none;
        @include button-primary;
        transform: translateY(-22px);
        margin-bottom: 20px;
        z-index: 2;
        @media (max-width: 1200px) {
            transform: translateY(0);
            margin-bottom: 48px;
        }
        @media (max-width: 992px) {
            margin-top: 24px;
            margin-bottom: 22px;
        }
        &:hover{
            border: none;
        }
    }

    .col-xs-12.col-lg-12{
        @media (max-width: 1200px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-xs-4.col-lg-4{
        @media (max-width: 1200px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

}

.container.accordionContainer{
    @media (max-width: 992px) {
        max-width: 100%;
        width: 100%;
        padding: 0;
    }
    .row{
        &>div{
            padding: 0!important;
        }
    }
    .btnBuy{
        z-index: 2;
    }
}
