.embeddedServiceHelpButton{
    z-index: 3 !important;

    .helpButton{
        bottom: 30px !important;
        right: 80px!important;
        left: auto !important;
        height: auto !important;

        // @media (min-width: 1780px) {
        //     right: calc((100vw - 1640px)/2);
        // }

        @media (max-width: 1780px) {
            right: 80px!important;;
        }

        @media(max-width: 1200px) {
            right: 30px!important;;
        }

        @media(max-width: 992px) {
            right: 20px!important;
        }

        button.uiButton{
            display: block;
            width: auto;
            height: auto;
            padding: 16px 32px 16px 32px;
            border-radius: 22px;
            white-space: nowrap;
            transform: none;
            font-size: 18px;
            font-weight: 400;
            // text-transform: uppercase;
            letter-spacing: normal;
            line-height: normal;
            color: #000000;
            background-color: #FDEBB0;
            max-width: 100% !important;
            box-shadow: none !important;
            min-width: 190px;
            margin-left: auto;

            @media(max-width: 992px) {
                height:48px;
                min-width: auto !important;
                padding: 15px;
                // background-image: url(./../images/icons/chat-face.svg);
                // background-repeat: no-repeat;
                // background-position: center;
                // background-size: 25px;
            }

            &::after{
                content: "";
                display: block;
                position: absolute;
                background-color: inherit;
                background-repeat: no-repeat;
                mask-image: url(./../images/icons/chat-i-yellow.svg);
                -webkit-mask-image: url(./../images/icons/chat-i-yellow.svg);
                mask-repeat: no-repeat;
                -webkit-mask-repeat: no-repeat;
                width: 30px;
                height: 26px;
                background-size: 100%;
                top: 99%;
                right: 15px;

                @media(max-width: 992px) {
                    top: 98%;
                }
            }

            &::before{
                display: none !important;
            }

            &:focus{
                .helpButtonLabel{
                    text-decoration: none !important;
                }
            }

            .helpButtonLabel{
                // @media(max-width: 992px) {
                //     display: none !important;
                // }
            }

            .embeddedServiceIcon{
                display: none !important;
            }
        }

        &--green{
            button.uiButton{
                background-color: $lightGreen;
                color: #000000;

                @media(max-width: 992px) {
                    background-image: url(./../images/icons/chat-face-black.svg);
                }

                &::after{
                    background-image: url(./../images/icons/chat-i-green.svg);
                }
            }
        }
    }
}
.dockableContainer{
    @media(max-width: 992px) {
        width: 90% !important;
    }

    .chatHeaderBranding{
        background-color: $brandPurple !important;
    }

    .formContent{
        background-color: #EBEBEB !important;
    }

    button{
        &:focus{
            text-decoration: none !important;
        }
    }

    .embeddedServiceSidebarForm.buttonWrapper{
        background: #EBEBEB !important;

        .startButton{
            border-radius: 80px !important;

            &:hover{
                background: #ea8b6e !important;
            }
        }
    }

    .endChatContainer{
        .endChatButton.closeChatButton{
            border-radius: 80px !important;

            &:hover{
                background: #ea8b6e !important;
            }
        }
    }
}

.sidebarMinimized button{
    left: 15px !important;
}

.embeddedServiceSidebar{
    z-index: 2 !important;

    &.sidebarMaximized{
        @media(max-width: 992px) {
            z-index: 10 !important;
        }
    }
}
.alternative-color-chat {
    display: contents;
    button.uiButton{
        background-color: $blueLight!important;
    }
}
