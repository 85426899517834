//grid config
@import "grid-config";

//import bootstrap grid
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/grid";

//base application
@import "base/typography";
@import "base/mixins";
@import "base/styleguide";
@import "base/image-aspect-ratio";

//components
@import "components/header";
@import "components/banner"; // OLD Banner
@import "components/banner-frontpage"; // NEW Banner
@import "components/footer";
@import "components/buttons";
@import "components/titles";
@import "components/forms";
@import "components/boxes";
@import "components/article";
@import "components/block-cards";
@import "components/statement-carousel";
@import "components/checkout";
@import "components/hero";
@import "components/list-links";
@import "components/inspiration-blocks";
@import "components/quote";
@import "components/blog-posts";
@import "components/resizeCol";
@import "components/important-message";
@import "components/log-in";
@import "components/studieradgivning";
@import "components/blog-list";
@import "components/btn-float";
@import "components/accordion";
@import "components/blog-post";
@import "components/image-carousel";
@import "components/employee";
@import "components/studie";
@import "components/search";
@import "components/studievelger";
@import "components/ticker";
@import "components/textBlocks";
@import "components/landing-sub-landing";
@import "components/search-result";
@import "components/error404";
@import "components/tooltip";
@import "components/videoYT-macro";
@import "components/image-blocks";
@import "components/chat";
@import "components/color-blocks";
@import "components/program-list";
@import "components/newsletter-macro";
