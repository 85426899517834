.banner-frontpage{
  position: relative;
  margin-top: -35px;
  background-color: #E8E7FD;

  @media (max-width: 992px) {
    margin-top: 0;
  }



  &__info{
    color: $purpleDark;
    padding-top: 98px;
    padding-bottom: 103px;

    @media (max-width: 992px) {
      padding-top: 35px !important;
      padding-bottom: 30px !important;
    }

    h1{
      max-width: 614px;
      margin-bottom: 42px;
      font-size: 96px;
      line-height: 100px;


      @media (max-width: 992px) {
        margin-bottom: 28px;
        font-size: 48px;
        line-height: 50px;
      }

      @media (max-width: 767px) {
        min-height: 100px;
        margin-bottom: 400px;
      }
      @media (max-width: 414px)  {
        margin-bottom: calc(80vw + 65px);
      }
    }

    .select-wrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 25px;
      column-gap: 46px;
      @media (max-width: 992px) {
        min-height: 89px;
      }
      .courseSelect {
        flex: 1;
        margin-bottom: 0;
      }
      .free-seats{
        color: $purpleDark;
        font-size: 26px;
        white-space: nowrap;
        text-decoration-thickness: 2px;
        text-decoration-skip-ink: none;
        max-width: max-content;
        padding-left: 23px;
        line-height: 1.2;
        white-space: break-spaces;
        flex: 1;
        @media (max-width: 992px) {
          display: block;
          font-size: 18px;
          min-width: 100%;
        }
        @media (hover: hover) and (pointer: fine) {
          &:hover {
              font-family: $font-medium;
          }
        }
      }
    }
  }

  &__image{
    overflow: hidden;

    @media (max-width: 767px) {
      position: unset;
      position: absolute;
      width: 100%;
      left: -10px;
      right: -10px;
      width: calc(100% + 20px);
      bottom: 154px;
      height: 335px;
    }
    @media (max-width: 414px)  {
      height: 80vw;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .clipImage{
      width: 100%;
      height: 100%;

      @media (max-width: 992px) {
        margin: 0 auto;
      }

      .wrapper{
        // width: 100%;
        // position: absolute;
        // top: -160px;
        // right: -50px;

        // @media (max-width: 992px) {
        //   top: -70px;
        //   right: -7%;
        // }

        // @media (max-width: 767px) {
        //   top: 170px;
        //   //right: -70px;
        //   right: calc(50% - 293px / 2);
        //   width: auto;
        // }

        .clipped {
          // width: 646px;
          // height: 720px;
          // background: $brandPurple;
          // background-size: cover;

          // mask-image: url(../images/banner-mask.svg);
          // mask-repeat: no-repeat;
          // mask-size: 646px 720px;
          // mask-position: center;

          // -webkit-mask-image: url(../images/banner-mask.svg);
          // -webkit-mask-repeat: no-repeat;
          // -webkit-mask-size: 646px 720px;
          // -webkit-mask-position: center;

          // background-position: center 160px;

          // @media(max-width: 992px){
          //   background-position: center 70px;
          // }

          // @media(max-width: 768px){
          //   background-position: center 0;
          // }

          // @media (max-width: 992px) {
          //   width: 415px;
          //   height: 465px;
          //   mask-size: 415px 465px;
          //   -webkit-mask-size: 415px 465px;
          // }

          // @media (max-width: 767px) {
          //   width: 293px;
          //   height: 305px;
          //   mask-size: 293px 305px;
          //   -webkit-mask-size: 293px 305px;
          // }

          // @media (max-width: 767px) {
          //   mask-image: url(../images/banner-mask-mobile.svg);
          //   -webkit-mask-image: url(../images/banner-mask-mobile.svg);
          // }
        }
      }
    }
  }
  &--yellow {
    background-color: #FDEBB0;
  }
}

.courseSelect{
  position: relative;
  width: 100%;
  z-index: 1;

  @media (max-width: 992px) {
    margin-bottom: 46px;
  }

  &__btn{
    width: 100%;
    max-width: 547px;
    position: relative;
    z-index: 1;

    button{
      background: $purpleDark;
      border-radius: 100px;
      display: block;
      width: 100%;
      padding: 19px 72px 18px 36px;
      border: none;
      position: relative;
      text-align: left;
      font-size: 26px;
      line-height: 100%;
      color: #fff;
      white-space: nowrap;
      @media (max-width: 992px) {
        font-size: 18px;
        padding: 12px 60px 12px 23px;
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
            font-family: $font-medium;
        }
      }

      &.active{
        @media (hover: none) {
          font-family: $font-medium;
        }
        @media (max-width: 767) {
          font-family: $font-medium;
        }
        span{
          transform: translateY(-50%) rotate(180deg);
        }
      }

      span{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 5px;
        top: 50%;
        width: 49px;
        height: 49px;
        background-color: white;
        border-radius: 100%;
        transform: translateY(-50%);
        transition: all 300ms;

        @media (max-width: 992px) {
          width: 32px;
          height: 32px;
        }

        img{
          width: 16px;
          height: 24px;

          @media (max-width: 992px) {
            width: 10px;
            height: 15px;
          }
        }
      }
    }
  }

  &__options{
    background: #fff;
    border-radius: 0 0 40px 40px;
    padding: 60px 32px 32px 32px;
    position: absolute;
    top: 31px;
    z-index: 0;
    display: none;
    width: 100%;
    max-width: 547px;

    @media (max-width: 992px) {
      top: 21px;
      padding: 44px 23px 23px 23px;
      border-radius: 0 0 22px 22px;
    }

    .items{
      list-style: none;

      li{
        margin-bottom: 30px;

        @media (max-width: 992px) {
          margin-bottom: 20px;
        }

        &:last-child{
          margin-bottom: 0;
        }

        a{
          font-size: 26px;
          line-height: 100%;
          color: $purpleDark;
          text-decoration: none;
          &:hover {
            font-family: $font-semiBold;
            text-decoration: underline;
            text-decoration-color: $link-blue;
            text-decoration-skip-ink: none;
            text-underline-offset: 5px;
            text-decoration-thickness: 2px;
          }

          @media (max-width: 992px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
