
.checkout{
    &__title {
        margin-top: 50px;
        margin-bottom: 60px;
        @media(max-width: 992px) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        &.examRegistration{
            margin-bottom: 28px;
            @media(max-width: 992px) {
                margin-bottom: 40px;
            }
        }
        &.examRegistrationRequest{
            margin-bottom: 60px;
            @media(max-width: 992px) {
                margin-bottom: 40px;
            }
        }
        &.application{
            margin-bottom: 120px;
            @media(max-width: 992px) {
                margin-top: 20px;
                margin-bottom: 40px;
            }
        }

        &.application-confirmation{
            margin-bottom: 60px;
            @media(max-width: 992px) {
                margin-top: 20px;
                margin-bottom: 40px;
            }
        }

        h1, h2, h3, h4 {
            color: $brandPurple;
            font-size: 72px;
            line-height: 88px;
            letter-spacing: -0.88px;
            font-family: $font-book;
            word-break: break-word;
            @media(max-width: 992px) {
                font-size: 32px;
                line-height: 42px;
                letter-spacing: -0.24px;
                // margin-bottom: 20px;
            }
        }
        .back-link {
          margin-bottom: 48px;
          a{
            text-decoration: none;
            text-transform: initial;
            position: relative;
            font-family: $font-regular;
            padding-left: 18px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMSAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNDUyOTQxIDUuNDM3NUwtMi4xODU1N2UtMDcgNUwwLjQ1Mjk0MSA0LjU2MjVMNS4wNDcwNiAtMi42MDIxMWUtMDdMNS45NTI5NCAwLjkzNzVMMi40NTg4MiA0LjM3NUwxMSA0LjM3NUwxMSA1LjYyNUwyLjQ1ODgyIDUuNjI1TDUuOTUyOTQgOS4wNjI1TDUuMDQ3MDYgMTBMMC40NTI5NDEgNS40Mzc1WiIgZmlsbD0iIzEyMTIxMiIvPgo8L3N2Zz4K");
            background-repeat: no-repeat;
            background-size: 11px;
            background-position: left center;
          }
          @media(max-width: 992px) {
              display: none;
          }
        }
        &__logo{
            width: 100%;
            max-width: 65%;
            float: right;
            @media(max-width: 992px) {
                max-width: 60%;
            }
            @media(max-width: 520px) {
                max-width: 65%;
            }
            img{
                width: 100%;
                padding-left: 20px;
                @media(max-width: 460px) {
                    padding-left: 5px;
                }
            }
        }
        &__intro {
            font-family: $font-light;
            font-size: 21px;
            line-height: 36px;
            letter-spacing: 0.24px;
            color: #121212;
            hyphens: auto;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            @media(max-width: 992px) {
                font-size: 15px;
                line-height: 24px;
            }
            p{
                margin-bottom: 20px;
                @media(max-width: 992px) {
                    margin-bottom: 15px;
                }
            }
        }
    }
    &__edit-info {
        font-size: 21px;
        line-height: 36px;
        a {
            font-family: $font-light;
            padding-bottom: 8px;
            @include link-default;
            @media(max-width: 992px) {
                font-size: 15px;
                line-height: 24px;
                display: inline-block;
                padding-bottom: 0;
            }
        }
        .text-right{
            @media(max-width: 992px) {
                text-align: left!important;
            }
        }
        .editBtn{
            @media(max-width: 992px) {
                margin-top: 15px;
            }
        }
        @media(max-width: 992px) {
            font-size: 17px;
            line-height: 28px;
        }
        &__type{
            color: #B4B4B4;
            font-family: $font-light;
            position: relative;
        }
        .form-group {
            margin-bottom: 0;
        }

        &__spacer{
            @media(max-width: 992px) {
                margin-top: 20px;
                width: 100%;
            }

        }

        &__select{

            .item.select{
                @media(max-width: 575px) {
                    width: initial;
                }
                .item-selected{
                    padding-right: 25px;
                }
                a{
                    font-family: $font-regular;
                    font-size: 21px;
                    line-height: 36px;
                    color: #121212;
                    border-bottom: none;
                    text-decoration: none;
                    @media(max-width: 992px) {
                        font-size: 17px;
                        line-height: 28px;
                    }
                    b{
                        font-family: $font-regular;
                        font-weight: 400;
                        position: relative;
                        span{
                            position: relative;
                            &:after {
                                background-image: url(../images/./icons/arrow-down.svg);
                                background-repeat: no-repeat;
                                background-position: center center;
                                width: 10px;
                                height: 13px;
                                content: "";
                                display: inline-block;
                                background-size: cover;
                                position: absolute;
                                right: -19px;
                                top: 8px;
                                @media(max-width: 992px) {
                                    right: -15px;
                                    top: 4px;
                                }
                            }
                        }
                    }
                }

                .options{
                    display: none;
                    position: relative;
                    span{
                        display: block;
                        a{
                            border-bottom: 2px solid rgba(246,141,111,0);
                            padding-bottom: 0;
                            font-size: 17px;
                            line-height: 28px;
                            display: inline;
                            word-break: break-word;
                            @media(max-width: 992px) {
                                font-size: 17px;
                                line-height: 20px;
                            }
                            &.selected{
                                border-bottom: 2px solid rgba(246,141,111,1);
                            }
                        }

                    }
                }

                &.active{
                    z-index: 5;
                    position: relative;
                    padding: 0;
                    width: 100%;
                    max-width: 350px;
                    @media (max-width: 992px) {
                        padding: 0;
                    }
                    @media (max-width: 575px) {
                        max-width: 100%;
                    }

                    &:before{
                        content: "";
                        position: absolute;
                        width: calc(100% + 80px);
                        height: calc(100% + 80px);
                        background: #ffffff;
                        top: -40px;
                        left: -40px;
                        // z-index: -1;
                    }

                    .overlaySelect{
                        position: fixed;
                        width: 100vw;
                        height: 100vh;
                        background: rgba(18, 18, 18, 0.5);
                        top: 0;
                        left: 0;
                        z-index: -2;
                    }

                    .item-selected{
                        display: none;
                    }

                    *{
                        font-size: 21px;
                        line-height: 36px;
                        @media (max-width: 992px) {
                            font-size: 18px;
                            line-height: 28px;
                        }
                    }
                    .options{
                        display: block;
                    }
                }










            }
        }





    }
    &__section{
        color: $textDark1;
        padding: 44px 0 48px 0;
        border-top: 1px solid $grey;
        @media(max-width: 992px) {
            padding: 0 0 24px 0;
            border-top: none;
        }
        &.student{
            padding: 60px 0 20px 0;
            @media(max-width: 992px) {
                padding: 20px 0 24px 0;
            }
        }
        &.request{
            padding: 60px 0 40px 0;
            @media(max-width: 992px) {
                padding: 20px 0 24px 0;
            }
        }
        &.section-readonly{
            padding: 44px 0 40px 0;
            @media(max-width: 992px) {
                padding: 20px 0 12px 0;
            }
        }
        &.formGroup{
            padding: 44px 0 40px 0;
            @media(max-width: 992px) {
                padding: 20px 0 12px 0;
            }

            .styled-radio{
                label[for="terms"]{
                    @media(max-width: 992px) {
                        margin-top: 30px;
                        font-size: 18px;
                    }
                }
            }
        }
        &.formGroup-confirmation{
            padding: 60px 0 50px 0;
            @media(max-width: 992px) {
                padding: 20px 0 0 0;
            }
        }
        > h5{
            font-size: 32px;
            line-height: 44px;
            letter-spacing: -0.24px;
            color: $brandPurple;
            margin-bottom: 40px;
           @media(max-width: 992px) {
                font-size: 20px;
                line-height: 28px;
                margin-top: 8px;
                margin-bottom: 20px;
            }
        }
        p {
            font-size: 1.25rem;
            font-family: $font-light;
            @media(max-width: 992px) {
                font-size: 1rem;
            }
        }
        .box-orange{
            margin-top: 0px;
            padding: 40px 50px 40px 50px;
            margin-bottom: 32px;
            @media(max-width: 992px) {
                padding: 24px;
            }
            p{
                font-family: $font-light;
                font-size: 21px;
                line-height: 36px;
                letter-spacing: 0.24px;
                color: #121212;
                @media(max-width: 992px) {
                    font-size: 17px;
                    line-height: 28px;
                }
            }
        }
        .radio-card p {
            font-size: 1.125rem;

            @media(max-width: 992px) {
                font-size: 1rem;
            }
        }
        .checkout__close__basket{
            .items{
                padding: 0 0 0 20px;
                padding-bottom: 12px;
                li {
                    font-family: $font-light;
                    font-size: 21px;
                    line-height: 36px;
                    letter-spacing: 0.24px;
                    color: #222222;
                    padding-left: 8px;
                    margin-bottom: 8px;
                    position: relative;
                    // padding-right: 20%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    &:after{
                        content: '';
                        width: 9px;
                        height: 9px;
                        position: absolute;
                        left: -20px;
                        top: 10px;
                        background-color: #222222;
                        border-radius: 9px;
                    }

                    @media(max-width: 992px) {
                        font-size: 17px;
                        line-height: 28px;
                        // padding-right: 100px;
                    }
                    div {
                        word-break: break-word;
                        &:first-child{
                            padding-right: 40px;
                        }
                    }
                    .value{
                        // position: absolute;
                        // top: 0;
                        // right: 0;
                        color: $brandPurple;
                        font-family: $font-regular;
                        white-space: nowrap;
                        s{
                            position: relative;
                            display: block;
                            text-decoration: none;
                            white-space: nowrap;
                            &:after{
                                content: "";
                                position: absolute;
                                top: calc(50% - 3px);
                                left: 50%;
                                transform: translateX(-50%);
                                background-color: $brandPurple;
                                width: calc(100% + 10px);
                                height: 3px;
                                border-radius: 3px;
                            }
                        }
                    }
                }
            }
            &__total{
                font-family: $font-book;
                font-size: 21px;
                line-height: 36px;
                letter-spacing: 0.24px;
                color: $brandPurple;
                position: relative;
                padding-right: 20%;
                margin-bottom: 88px;
                padding-top: 20px;
                border-top: 1px solid rgba($color: $brandPurple, $alpha: .3);
                @media(max-width: 992px) {
                    font-size: 17px;
                    line-height: 28px;
                    padding-right: 120px;
                    margin-bottom: 20px;
                }
                div {
                    word-break: break-word;
                }
                .value{
                    position: absolute;
                    top: 20px;
                    right: 0;
                }

            }
        }
        .form-payment-radio{
            margin-bottom: 60px;
            @media(max-width: 992px) {
                margin-bottom: 30px;
            }
            .styled-radio{
                label{
                    @media(max-width: 992px) {
                        font-size: 16px;
                    }
                }
            }
        }
        .styled-radio input[type=checkbox]+label:after, .styled-radio input[type=radio]+label:after {
            top: 2px;
        }

        .form-group{
            @media(min-width: 993px) {
                margin-bottom: 20px;
            }
        }


        .couponCode{
            &__checkbox{
                margin-bottom: 0;
                label{
                    margin-bottom: 0;
                }
                input[type=checkbox]:focus + label:after{
                    border-color: #121212;
                }
                input[type=checkbox].active:focus + label:after{
                    border-color: #F68D6F!important;
                }
            }
            &__wrapper{
                display: none;
                margin-top: 40px;
                &.active{
                    display: block;
                }
                h5{
                    font-size: 32px;
                    line-height: 44px;
                    letter-spacing: -0.24px;
                    color: $brandPurple;
                    margin-bottom: 40px;
                    @media (max-width: 992px) {
                        font-size: 20px;
                        line-height: 28px;
                        margin-top: 8px;
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .file-size-error {
            font-size: 1rem;
            color: $brandRed;
            font-family: $font-light;

            @media(max-width: 992px) {
                font-size: 0.875rem;
            }
        }
    }
    &__bottom {
        background-color: $lightPurple;
        padding-top: 60px;
        padding-bottom: 180px;
        @media(max-width: 992px) {
            padding: 60px 0 100px 0px;
            margin-top: 12px;
        }
        &.confirmation{
            padding-bottom: 120px;
            @media(max-width: 992px) {
                padding-bottom: 100px;
            }
        }
        &.total{
            padding-bottom: 120px;
            @media(max-width: 992px) {
                padding: 32px 0 100px 0px;
                margin-top: 12px;
            }
        }
        h5 {
            color: $brandPurple;
            font-family: $font-book;
            margin-bottom: 45px;
        }
        h6{
            font-size: 25px;
            line-height: 36px;
            letter-spacing: -0.24px;
            color: $brandPurple;
            margin-bottom: 20px;
            @media(max-width: 992px) {
                margin-bottom: 12px;
                font-size: 20px;
                line-height: 28px;
            }
        }
        &--htmlArea{
            margin-bottom: 52px;
            font-size: 21px;
            line-height: 36px;
            letter-spacing: 0.24px;
            color: #222222;
            font-family: $font-light;
            @media(max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
            p{
                margin-bottom: 15px;
            }
            ul,ol {
                padding-left: 20px;
                margin-bottom: 15px;
                li {
                    padding: 0 0 0 13px;
                    font-size: 21px;
                    line-height: 36px;
                    letter-spacing: 0.24px;
                    color: #222222;
                    font-family: $font-light;
                    margin-bottom: 8px;
                    @media(max-width: 992px) {
                        padding: 0 0 0 3px;
                        font-size: 17px;
                        line-height: 28px;
                        margin-bottom: 4px;
                    }
                }
            }
        }
        .btn-primary{
            @include button-primary;
            // font-family: $font-regular;
            // font-size: 21px;
            // line-height: 28px;
            // padding: 20px 40px;
            // border-radius: 62px;
            // display: block;
            // box-shadow: none!important;
            // @media(max-width: 992px) {
            //     padding: 14px 44px;
            //     font-size: 17px;
            //     line-height: 28px;
            //     border-radius: 38px;
            //     margin: 0 auto;
            // }
        }
        &__content {
            margin-bottom: 40px;
        }
        input[type=checkbox]+label {
            font-family: $font-light;
            font-size: 21px;
            line-height: 36px;
            letter-spacing: 0.24px;
            color: #121212;
           @media(max-width: 992px) {
                font-size: 15px;
                line-height: 24px;
            }
            &:after,
            &:before{
                width: 32px;
                height: 32px;
            }
        }
        .invalid {
            label {
                color: #F15B68!important;
            }
        }

    }



    &__text-info {
        font-family: $font-light;
        font-size: 21px;
        line-height: 36px;
        letter-spacing: 0.24px;
        color: #121212;
        @media(max-width: 992px) {
            font-size: 15px;
            line-height: 24px;
        }
        .box-orange {
            &:not(:last-child){
                margin-bottom: 60px;
            }
        }
        .link-b-orange{
            font-family: $font-regular;
        }
        p{
            margin-bottom: 15px;
            + textarea {
                margin-top: 20px;
            }
            @media(max-width: 992px) {
                margin-bottom: 5px;
            }
        }
        h5 {
            color: $brandPurple;
            font-family: $font-book;
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 44px;
            letter-spacing: -0.24px;
            color: $brandPurple;
            @media(max-width: 992px) {
                font-size: 20px;
                line-height: 32px;
            }
        }
        h6 {
            font-family: $font-book;
            margin-bottom: 15px;
        }
        ul {
            padding-left: 20px;
            padding-bottom: 30px;
            @media(max-width: 992px) {
                padding-bottom: 15px;
            }
            li{
                padding: 0  0 15px 4px;
                @media(max-width: 992px) {
                    padding: 0  0 5px 4px;
                }

            }
        }


        &__basket {
            padding-bottom: 60px;
            &__total {
                font-size: $p-m;
                color: $brandPurple;
                font-family: $font-book;
                padding-top: 15px;
                border-top: 1px solid $grey;
               @media(max-width: 992px) {
                    font-size: 0.9375rem; //15
                }
                div{
                    float: right;
                }
            }
            ul {
                padding-left: 20px;
                li{
                    font-size: $p-m;
                    @media(max-width: 992px) {
                        font-size: 0.9375rem; //15
                    }
                    div {
                        color: $brandPurple;
                        float: right;
                    }
                    padding: 0  0 15px 4px;

                }
            }
        }
        &.withTitle{
            margin-top: 60px;
            @media(max-width: 992px) {
                margin-top: 10px;
            }
            ul{
                padding-bottom: 32px;
                @media(max-width: 992px) {
                    padding-bottom: 20px;
                }
                li{
                    padding: 0 0 8px 10px;
                }
            }
            h6{
                font-family: $font-book;
                font-size: 21px;
                line-height: 36px;
                letter-spacing: 0.24px;
                color: #121212;
                margin-bottom: 4px;
                @media(max-width: 992px) {
                    font-size: 17px;
                    line-height: 24px;
                    margin-bottom: 8px;
                }
            }
            p{
                margin-bottom: 4px;
            }
        }
    }

    &__article{
        .divider{
            border-top: 1px solid #D6D6D6;
            margin-bottom: 76px;
            @media (max-width: 992px) {
                margin-bottom: 60px;
            }
        }
    }

}

.basket{
    &__item{
        font-family: $font-light;
        font-size: $p-m; //21px
        position: relative;
       @media(max-width: 992px) {
            font-size: 0.9375rem; //15
        }
        &__title{
            font-family: $font-regular;
            color: $brandPurple;
            font-size: 25px;
            line-height: 40px;
            letter-spacing: -0.12px;
            padding-right: 50px;
            @media(max-width: 992px) {
                margin-bottom: 10px;
                font-size: 18px;
                line-height: normal;
            }
            a{
                text-decoration: none;
            }
        }
        &__category{
            font-size: 18px;
            line-height: 28px;
            letter-spacing: 0.24px;
            color: #121212;
            opacity: 0.5;
            font-family: $font-light;
           @media(max-width: 992px) {
                font-size: 15px;
                line-height: normal;
            }
        }
        &__price {
            font-size: 21px;
            line-height: 36px;
            letter-spacing: 0.24px;
            color: #121212;
            font-family: $font-light;
            margin-top: 24px;
            display: block;
            position: relative;
            padding-right: 20%;
            word-break: break-word;
            @media(max-width: 992px) {
                font-size: 15px;
                line-height: 24px;
                padding-right: 100px;
            }
            &__value {
                color: $brandPurple;
                font-family: $font-book;
                text-align: right;
                position: absolute;
                right: 0;
                top: 0;
                s{
                    position: relative;
                    display: block;
                    text-decoration: none;
                    white-space: nowrap;
                    &:after{
                        content: "";
                        position: absolute;
                        top: calc(50% - 3px);
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: $brandPurple;
                        width: calc(100% + 10px);
                        height: 3px;
                        border-radius: 3px;
                    }
                }
            }
        }
        &--add{
            text-align: left;
            padding: 20px 40px 20px 40px;
            border-radius: 0;
            margin-bottom: 80px;
            background-color: $brandOrange;
            width: 100%;
            display: block;
            font-family: $font-light;
            font-size: 21px;
            line-height: 36px;
            letter-spacing: 0.24px;
            color: #121212;
            &:focus{
                box-shadow: none;
            }
            @media(max-width: 992px) {
                padding: 25px;
                font-size: 15px;
                line-height: 28px;
                margin-top: 20px;
                margin-bottom: 40px;
            }
            &:before {
                content: '';
                margin-right: 20px;
                vertical-align: middle;
                width: 20px;
                height: 20px;
                display: inline-block;
                background-image: url(../images/icons/plus.svg);
                background-repeat: no-repeat;
                @media(max-width: 992px) {
                    width: 15px;
                    height: 15px;
                    background-size: 15px;
                }
            }
            &:hover, &:focus-within {
                text-decoration: underline;
            }
        }
        &__box{
            padding: 44px;
            border: 1px solid rgba(72,58,84,0.2);
            margin-top: 24px;

            width: calc(71.43% - 10px);
            border-radius: 12px;
            @media(max-width: 992px) {
                padding: 15px;
                width: 100%;
            }
            h4, h5, h6 {
                // font-family: "SuisseIntl-Regular", sans-serif;
                margin-bottom: 24px;
            }
            .styled-radio {
                input[type= checkbox],
                input[type= radio] {
                    + label {
                        font-size: 1.125rem;
                        p {
                            font-size: 1.125rem;
                            @media(max-width: 992px) {
                                font-size: $p;
                            }
                        }
                        h5 {
                            font-family: "SuisseIntl-Book", sans-serif;
                            font-size: $p-m;
                            color: $brandPurple;
                            margin-bottom: 15px;
                            @media(max-width: 992px) {
                                font-size: $p;
                            }
                        }
                        &:after {
                            top: 0;

                        }
                    }
                }
                + .styled-radio {
                    margin-top: 21px;
                }
            }
        }
        .btn-remove{
            position: absolute;
            right: -8px;
           @media(max-width: 992px) {
                background-size: 15px;
            }
        }

    }
    &__total {
        &__items {
            color: $textDark2;
            padding: 0 0 0 20px !important;
            // margin-bottom: 32px!important;
            li {
                font-size: $p-m; //21px
                font-family: $font-light;
                padding: 0 0 20px 20px;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                // padding-right: 20%!important;

                &:after{
                    content: '';
                    width: 9px;
                    height: 9px;
                    position: absolute;
                    left: -20px;
                    top: 10px;
                    background-color: #222222;
                    border-radius: 9px;
                }

                &:last-child{
                    margin-bottom: 32px;
                }

                div {
                    word-break: break-word;
                    // display: flex;
                    // justify-content: space-between;

                    @media(max-width: 992px){
                        font-size: 18px;
                    }

                    &:first-child{
                        padding-right: 30px;
                        @media(min-width: 993px){
                            max-width: 80%;
                        }
                    }
                }
                .value{
                    // position: absolute;
                    // top: 0;
                    // right: 0;
                    color: $brandPurple;
                    font-family: $font-regular;
                    white-space: nowrap;

                    @media(max-width: 992px) {
                        position: relative;
                        top: initial;
                        right: initial;
                    }

                    s{
                        position: relative;
                        display: block;
                        text-decoration: none;
                        white-space: nowrap;
                        &:after{
                            content: "";
                            position: absolute;
                            top: calc(50% - 3px);
                            left: 50%;
                            transform: translateX(-50%);
                            background-color: $brandPurple;
                            width: calc(100% + 10px);
                            height: 3px;
                            border-radius: 3px;
                        }
                    }

                    &.loading-background{
                        min-height: 31px;
                        *{
                            opacity: 0;
                        }
                        @media(max-width: 992px) {
                            width: 100px;
                        }
                    }
                }
            }
        }
        &__value {
            color: $brandPurple;
            font-family: $font-regular;
            font-size: 29px;
            line-height: 100%;
            letter-spacing: 0.01em;
            color: $brandPurple;
            padding-top: 60px;
            border-top: 1px solid $grey;
            margin-bottom: 40px;
            display: flex;
            justify-content: space-between;
            position: relative;
            font-weight: bolder;
            flex-wrap: wrap;
           @media(max-width: 992px) {
                font-size: 26px;
            }

            .totalPrice-discount{
                position: absolute;
                top: -27px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                &__items{
                    .item{
                        font-size: 21px;
                        line-height: 100%;
                        letter-spacing: 0.01em;
                        color: $brandPurple;
                        position: relative;
                        margin-bottom: 15px;
                        &:last-child{
                            margin: 0;
                            &:after{
                                content: "-";
                                position: absolute;
                                right: calc(100% + 10px);
                                top: 0;
                            }
                        }
                    }
                }
            }

            .value{
                white-space: nowrap;
                &.loading-background{
                    min-height: 44px;
                    min-width: 150px;
                    *{
                        opacity: 0;
                    }
                }
            }

        }
        .btn-primary{
            display: inline-block;
            @media(max-width: 992px) {
                display: block;
            }
        }
    }
}

.mb-155{
    @media(max-width: 992px) {
        margin-bottom: 70px;
    }
}

.step2, .step3, .step4, .stepOptionalDocumentation{
    display: none;
}


// Checkout error page
#checkoutError{
    .fail__section{
        margin-bottom: 140px;

        .button__wrapper{
            margin-top: 80px;

            @media(max-width: 768px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 30px;
            }

            button{
                margin-right: 30px;
                padding: 20px 40px;
                border-radius: 62px;
                font-size: 21px;
                line-height: 28px;

                &:last-child{
                    margin-right: 0;
                }

                @media(max-width: 768px) {
                    margin-right: 0;
                    margin-bottom: 30px;
                    padding: 14px 44px;
                    font-size: 17px;
                    line-height: 28px;
                    border-radius: 38px;

                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .contact__section{
        display: none;

        .user-info{
            margin-top: 20px;

            @media(max-width: 992px) {
                margin-top: 0;
            }
        }
    }
}
