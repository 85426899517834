.btnFloatWrapper{
    position: absolute;
    right: -140px;
    height: auto;
    display: none;
    flex-direction: column;

    @media (min-width: 992px) {
        bottom: 20px;
    }

    @media (max-width: 992px) {
        position: fixed;
        right: 1px;
        height: 90vh;
        right: -120px;
    }

    &.active{
        display: flex;
        z-index: 2!important;
    }

    &.isVisible{
        position: fixed;
        top: inherit !important;
        bottom: 30px !important;
    }

    &.course{
        height: inherit!important;
        bottom: inherit!important;
        top: initial!important;
        left: initial!important;
        right: initial!important;
        bottom: initial!important;
        position: relative;
        margin-top: 64px;
        margin-bottom: 26px;

        @media (max-width: 992px) {
            margin-top: 0;
            margin-bottom: 38px;
        }

        .btnFloatWrapper__btn{
            position: relative;
            display: block;
            width: auto;
            height: auto;
            padding: 20px 46px;
            border-radius: 40px;
            white-space: nowrap;
            transform: none;
            max-width: 292px;
            height: 65px;
            font-size: 18px;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 1200px) and (min-width: 993px)  {
                max-width: 100%;
            }

            @media (max-width: 992px) {
                margin: 0 auto;
                width: 100%;
            }

            &::after{
                // content: "";
                // display: block;
                // position: absolute;
                // top: calc(50% - 8px);
                // right: 28px;
                // width: 18px;
                // height: 16px;
                // background-image: url(../images/./icons/arrow-email-purple.svg);
                // background-repeat: no-repeat;
                // background-size: auto;

                // @media (max-width: 992px) {
                //     right: 22px;
                // }
            }

            span.title{
                height: 25px;
                // color: #000000;
                font-size: 18px;
                // font-weight: 400;
                letter-spacing: normal;
                line-height: normal;


                @media (max-width: 992px) {
                    height: 21px;
                    font-size: 15px;

                }
            }
        }

    }

    &--kategori{
        position: fixed;

        @media (min-width: 992px) {
            top: calc(50vh - 70px);
            right: 20px;
            bottom: inherit;
        }

        .btnFloatWrapper__btn{
            background: #F68D6F;

            @media (min-width: 992px) {
                transform: none;
            }
        }
    }

    &__btn{
        background: #999999;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        text-align: center;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px;
        margin-top: 14px;
        transform: translateX(-160px);

        &:last-child{
            margin-top: 0;
        }
        &:active,
        &:focus{
            outline: 0;
        }
        @media (max-width: 991px) {
            width: 120px;
            height: 120px;
            margin-top: 1px;
            transform: translateX(-121px);
        }
        .title{
            font-family: $font-sporting-grotesque-regular;
            font-size: 21px;
            line-height: 24px;
            letter-spacing: -0.88px;
            color: #FFFFFF;
            display: block;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 24px;
            }
        }

        &.chat-online{
            background: #436A5F;
            .status{
                font-family: $font-light;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.24px;
                color: #A2E4BF;
                @media (max-width: 991px) {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        &.signUp{
            @include button-primary;
        }
        &.filter{
            background-color: $lightGreen;
            @media(min-width: 992px) {
                display: none;
            }
            @media (max-width: 991px) {
                font-size: .9375rem;
            }
        }

    }

    .errorInfo{
        position: absolute;
        right: 50%;
        transform: translateX(calc(50% - 265px));
        bottom: calc(100% - 30px);
        white-space: normal;
        text-align: left;
        width: 100vw;
        max-width: 623px;
        z-index: 1;
        @media(max-width: 1100px) {
            transform: translateX(calc(50%));
        }
        @media(max-width: 992px) {
            position: fixed;
            bottom: 0!important;
            top: initial;
            left: 0;
            max-width: 100%;
            left: initial;
            transform: initial;
            right: initial;
        }
        .box-orange{
            padding: 30px 35px;
            font-size: 16px;
            line-height: 26px;
            @media(max-width: 992px) {
                padding: 50px 20px 20px 20px;
            }
            a{
                padding-bottom: 0;
            }
            .btn-close{
                width: 16px;
                height: 16px;
                position: absolute !important;
                top: 20px;
                right: 30px;
                display: block;
                background-image: url(../images/./icons/close-tooltip.svg);
                background-repeat: no-repeat;
                background-position: center center;
                width: 16px;
                height: 16px;
                display: none;
                background-size: cover;
                cursor: pointer;
                @media(max-width: 992px) {
                    display: inline-block;
                }
            }
        }
    }

    // &.isVisible{
    //     .errorInfo{
    //         top: initial;
    //         bottom: calc(100% - 30px);
    //     }
    // }
    &__btn-info {
        font-family: "SuisseIntl-Regular", sans-serif;
        font-size: 17px;
        line-height: 26px;
        color: #361E40;
        position: relative;
        @media (max-width: 1200px) {
            text-align: center;
        }

        @media (max-width: 992px) {
            font-size: 16px;
            line-height: 22px;
            max-width: 285px;
            margin-inline: auto;
        }
        &::before{
            content: '';
            display: inline-block;
            width: 1em;
            height: 1em;
            margin-right: 8px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../images/icons/check.svg);
            vertical-align: middle;

        }
    }
    + .btnFloatWrapper__btn-info {
        margin-top: -10px;
        margin-bottom: 26px;
        @media (max-width: 992px) {
            margin-top: -16px;
            margin-bottom: 38px;
        }
    }
}
