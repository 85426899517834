.logIn{
    z-index: 1001;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    overflow-x: auto;
    right: -100vw;
    -ms-overflow-style: none;
    transition: all 300ms;
    &::-webkit-scrollbar {
      display: none;
    }

    &__wrapper{
        // background: #FAF5FE;
        // width: 858px;
        background: #FFF;
        width: 688px;
        height: 100%;
        float: right;
        overflow: auto;
        @media(max-width: 992px){
            width: 100%;
        }

        .btnClose{
            position: fixed;
            z-index: 10;
            top: 40px;
            right: -100vw;
            transition: all 350ms;
            @media(max-width: 992px){
                position: absolute;
                top: 35px;
            }
            img{
                //   width: 40px;
                width: 24px;

                height: auto;
                @media(max-width: 992px){
                    width: 17.5px;
                }
            }
        }

        &--content{

            .linksWrapper{
                // padding: 108px 20% 75px 16%;
                padding: 100px 121px 72px 120px;

                background: #ffffff;
                @media (max-width: 768px) {
                    padding: 85px 30px 85px 30px;
                }
                .item{
                    // margin-bottom: 45px;
                    margin-bottom: 36px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .title{
                        display: block;
                        font-family: $font-light;
                        font-weight: 300;
                        // font-size: 21px;
                        // line-height: 36px;
                        font-size: 18px;
                        line-height: 32px;
                        letter-spacing: 0.24px;
                        color: $brandPurple;
                        opacity: 0.8;
                        // margin-bottom: 4px;
                        @media (max-width: 992px) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    a{
                        font-family: $font-regular;
                        // font-size: 28px;
                        // line-height: 40px;
                        font-size: 21px;
                        line-height: 36px;
                        padding-right: 23px;
                        text-decoration: none;
                        @include link-default;
                        display: inline-block;
                        @media (max-width: 992px) {
                            font-size: 22px;
                            line-height: 32px;
                        }
                        span{
                            position: relative;
                            &:after{
                                //   @include background-image("./icons/arrow-link-log-in.svg", 15.5, 15.5);
                                @include background-image("./icons/arrow-link-log-in.svg", 12, 12);
                                position: absolute;
                                right: -23px;
                                top: 50%;
                                margin-top: -5px;
                                @media (max-width: 992px){
                                    @include background-image("./icons/arrow-link-log-in.svg", 10, 10);
                                    right: -17px;
                                    margin-top: -3px;
                                }
                            }
                        }
                    }
                }
            }
            .logInBox{
                // padding: 48px 20% 59px 16%;
                // background: #FAF5FE;
                padding: 40px 124px 60px 124px;
                background:#FFF3E8;
                @media (max-width: 768px) {
                    padding: 48px 30px 59px 30px;
                }
                .title{
                    text-align: center;
                    // font-family: $font-regular;
                    // font-size: 21px;
                    font-family: $font-medium;
                    font-size: 18px;
                    line-height: 36px;
                    letter-spacing: 0.24px;
                    color: $brandPurple;
                    display: block;
                    // margin-bottom: 32px;
                    margin-bottom: 16px;
                    @media (max-width: 768px) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .formLogin{
                    // margin-bottom: 40px;
                    // margin-bottom: 32px;

                    .form-group{
                        // width: 46.2%;
                        // display: inline-block;
                        // margin-right: 6.5%;
                        margin-bottom: 11px;
                        @media (max-width: 768px) {
                            width: 100%;
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            margin-bottom: 22px;
                        }
                        &:last-child{
                            margin-right: 0;
                            @media (max-width: 768px) {
                                margin-right: auto;
                            }
                        }
                        label{
                            // margin-bottom: 8px;
                            // line-height: 28px;
                            font-size: 13px;
                            line-height: 24px;
                            margin-bottom: 4px;
                            @media (max-width: 768px) {
                                margin-bottom: 8px;
                                font-size: 14px;
                                line-height: 25px;
                            }
                        }
                        .form-control{
                            border-radius: 12px;
                            // border: 1px solid #B1B1B1;
                            min-height: 52px;
                            font-size: 17px;
                            line-height: 28px;
                            @media (min-width: 993px) {
                                border-color: #EEE0D3;
                                &:active, &:focus, &:focus-within {
                                    border-bottom-color: $darkGrey;
                                }
                            }
                            @media (max-width: 768px) {
                                font-size: 16px;
                                line-height: 24px;
                                min-height: 53px;
                            }
                        }
                    }
                }
                .btnLogin{
                    margin-bottom: 20px;
                    margin-top: 32px;
                    text-align: center;
                    button{
                        @include button-primary;
                    }
                }
                .btnPassword{
                    text-align: center;
                    a{
                        font-family: $font-regular;
                        text-decoration: none;
                        font-size: 13px;
                        line-height: 24px;
                        color: $brandPurple;
                        @include link-default;
                        display: inline-block;
                        @media (max-width: 992px) {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }

    }
    &.active{
        right: 0;
        .btnClose{
            right: 40px;
            @media(max-width: 992px){
                right: 35px;
            }
        }
        .overlay{
            animation: backgroundFade 300ms 300ms both;
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    @keyframes backgroundFade {
        from {
            background: rgba($color: #000000, $alpha: 0);
        }

        to {
            background: rgba($color: #000000, $alpha: .5);
        }
    }
}
