.article,
.accordionContent,
.accordionGroup,
.textBlocks{
    margin-bottom: 76px;
    @media(max-width: 992px) {
        margin-bottom: 32px;
    }
    &__header{
        margin-bottom: 60px;
        @media(max-width: 992px) {
            margin-bottom: 30px;
        }
        &__info {
            .item{
                margin-bottom: 4px;
                font-family: $font-light;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.24px;
                color: #000000;
                @media(max-width: 992px) {
                    font-size: 14px;
                    line-height: 24px;
                }
                strong{
                    font-family: $font-book;
                    font-weight: normal;
                }
            }
            @media(max-width: 992px) {
                margin-bottom: 20px;
            }
        }
        &__intro{
            font-family: $font-light;
            font-size: 25px;
            line-height: 40px;
            letter-spacing: 0.24px;
            color: #121212;
            margin-top: 80px;
            @media(max-width: 992px) {
                margin-top: 40px;
                font-size: 19px;
                line-height: 32px;
            }
        }
    }
    &__html-area{
        overflow: hidden;
        @extend .hyphenate;

        a{
            text-decoration: none !important;
            color: #222 !important;
            @include link-default;
            u{
               text-decoration: none;
            }
        }

        p, ul, ol{
            font-family: $font-light;
            font-size: 21px;
            line-height:36px;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
        }

        p{
            color: #000000;
            letter-spacing: 0.24px;
        }

        ul, ol{
            color: #000000;
        }

        ul{
            &+ h1,
            &+ h2,
            &+ h3,
            &+ h4,
            &+ h5,
            &+ h6{
                padding-top: 34px;
            }
        }

        p, ul, ol, h3, h4, h5, h6, .loopify-newsletter {
            margin-bottom: 24px;
            margin-left: calc(35.714% + 15px);
            width: calc(57.1428% - 16px);
            @media(max-width: 992px) {
                margin-bottom: 28px;
                margin-left: 0;
                width: 100%;
            }
        }

        ul, ol{
            @media(max-width: 992px) {
                margin-bottom: 40px;
            }
        }

        h1, h2, h3, h4, h5, h6{
            font-family: $font-medium;
            font-size: 21px;
            line-height: 36px;
            margin-bottom: 12px;
            color: #000000;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
        }

        h1, h3, h4, h5, h6{
            padding-top: 20px;
            @media (max-width: 992px) {
                padding-top: 0px;
            }
        }

        h2{
            width: calc(35.714% - 26px);
            // float: left;
            padding-top: 36px;
            // clear: both;
            position: absolute;
            @media(max-width: 992px) {
                position: relative;
                margin-bottom: 12px;
                width: 100%;
                float: none;
                padding-top: 12px;
            }

            + p, + h3, + h4, + h5, + h6, + ul, + ol, + .imageWrapper{
                padding-top: 36px;
                @media(max-width: 992px){
                    padding-top: 0;
                }
            }

            &:first-child{
                padding-top: 0;
                + p, + h3, + h4, + h5, + h6, + ul, + ol, + .imageWrapper{
                    padding-top: 0;
                }
            }

        }

        .imageWrapper{
            margin-left: calc(35.714% + 15px);
            width: calc(57.1428% - 16px);
            @media (max-width: 992px) {
                margin-left: 0;
                width: 100%;
            }
            &+ h2 {
                margin-top: 0;
            }
            figure{
                    width: 100%;
                    height: auto;
                img{
                    width: 100%;
                    height: auto;
                    margin-bottom: 16px;
                }
                figcaption{
                    font-family: $font-light;
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: 0.24px;
                    color: #121212;
                    text-align: center;
                    display: block;
                    @media(max-width: 992px){
                        font-size: 13px;
                        line-height: 20px;
                    }
                }
            }

            .editor-align-center,
            .editor-align-justify{
                padding-top: 56px;
                margin-bottom: 80px!important;
                @media (max-width: 992px) {
                    padding-top: 36px;
                    margin-bottom: 40px!important;
                }
            }

            .editor-align-left{
                margin-right: 30px;
                margin-bottom: 15px;
                @media (max-width: 992px) {
                    margin-right: 15px;
                }
            }

            .editor-align-right{
                margin-left: 30px;
                margin-bottom: 15px;
                @media (max-width: 992px) {
                    margin-left: 15px;
                }
            }

            &+ .imageWrapper {

                .editor-align-center,
                .editor-align-justify{
                    padding-top: 0;
                    @media (max-width: 992px) {
                        padding-top: 0;
                    }
                }
            }

        }


        .tableWrapper{
            margin-left: calc(35.714% + 15px);
            width: calc(57.1428% - 16px);
            padding-top: 23px;
            margin-bottom: 50px;
            overflow-x: auto;
            @media (max-width: 992px) {
                margin-left: 0;
                width: 100%;
            }
            table{
                border: thin solid #cac9c9;
                tr{
                    border-bottom: thin solid #cac9c9;
                    &:last-child{
                        border-bottom: none;
                    }
                    td{
                        border-right: thin solid #cac9c9;
                        padding: 10px;
                        min-width: 100px;
                        &:last-child{
                            border-right: none;
                        }
                    }
                }
            }
        }



        p {
            &+ ul, &+ ol {
                margin-top: -4px;
            }
        }

        ul, ol {
            padding-left: 26px;

            li {
                padding-left: 4px;
                margin-bottom: 8px;
                @media (max-width: 992px) {
                    margin-bottom: 4px;
                }
              &:marker {
                width: 5px;
              }
            }
        }

        .line-grey {
            margin: 80px 0;
            @media(max-width: 992px){
                margin: 60px 0;
            }
            &+ h1,
            &+ h2,
            &+ h3,
            &+ h4,
            &+ h5,
            &+ h6,
            &+ p,
            &+ ul,
            &+ ol{
                padding-top: 0;
            }
            &+ h1 + *,
            &+ h2 + *,
            &+ h3 + *,
            &+ h4 + *,
            &+ h5 + *,
            &+ h6 + *,
            &+ p + *,
            &+ ul + *,
            &+ ol + *{
                padding-top: 0;
            }
        }

        &.course-status{
            p, ul, ol, h2, h3, h4, h5, h6,
            .imageWrapper,
            .tableWrapper,
            .box-orange-article{
                margin-left: 4.8%;
                @media (max-width: 992px){
                    margin-left: 0;
                }
            }
            h2, h3, h4, h5, h6{
                float: none;
            }
        }

        .loopify-newsletter{
            iframe{
                border: none;
                margin: -20px;

                @media (max-width: 992px){
                    width: calc(100% + 40px);
                }
            }
        }
    }

    &.hide{
        display: none;
    }

    .quoteBig,
    .quoteSmall{
        margin-top: 176px;
        @media(max-width: 992px){
            margin-top: 72px;
        }
    }

    &.basic{
        margin-bottom: 200px;
        @media(max-width: 992px){
            margin-bottom: 100px;
        }
    }

    &.course{
	    margin-bottom: 50px;
        @media(max-width: 992px) {
            margin-bottom: 7px;
        }
        .accordionContent{
            margin-bottom: 0!important;
        }
        .article__html-area,
        .accordionContent__html-area{
	        p, ul, ol{
                font-family: $font-regular;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                @media (max-width: 992px) {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
            p{
                padding-top: 0;
                @media (max-width: 992px) {
                    // color: rgba(0, 0, 0, 0.7);
                    padding-top: 0;
                }
            }

            ul, ol{
                @media (max-width: 992px) {
                    // color: rgba(0, 0, 0, 0.7);
                }
            }

            p, ul, ol, h3, h4, h5, h6{
                margin-bottom: 30px!important;
                margin-left: 0!important;
                width: 100%!important;
            }
            a {
                margin-left: 0!important;

            }

	        h1, h2, h3, h4, h5, h6{
                margin-bottom: 30px;
            }

	        h1,h2{
                font-family: $font-regular;
                font-weight: 400;
                font-size: 36px;
                line-height: 47px;
                color: $purpleDark;
                width: 100%;
                float: initial;
                padding-top: initial;
                clear: initial;
                position: initial;
                margin-bottom: 20px;
                @media(max-width: 992px) {
                    position: initial;
                    margin-bottom: 20px;
                    width: initial;
                    float: initial;
                    padding-top: initial;
                }
            }

            h3{
                font-size: 26px;
                font-weight: 400;
                margin-bottom: 12px!important;
            }

            h4{
                font-family: $font-semiBold;
                font-size: 22px;
                margin-bottom: 12px!important;
            }

            .imageWrapper{
                margin-left: 0;
                width: 100%;
            }

            .tableWrapper{
                margin-left: 0;
                width: 100%;
            }

            h1, h3, h4, h5, h6{
                padding-top: 0;
                @media (max-width: 992px) {
                    padding-top: 0;
                }
            }

        }

    }

}
// .accordionContent{
//     margin-bottom: 80px;
//     @media(max-width: 992px) {
//         margin-bottom: 0px;
//     }
// }
