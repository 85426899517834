.landing__links {
    margin-bottom: 160px;
    @media(max-width: 991px) {
      margin-bottom: 80px;
    }
    .row {
      @media(max-width: 991px) {
        flex-direction: column-reverse;
      }
    }
    &__img {
      @media(max-width: 991px) {
        margin-right: -30px;
        margin-bottom: 40px;
      }
    }
    &__list {
      li {
        display: block;
        margin-bottom: 15px;
        @media(max-width: 991px) {
          display: inline-block;
          width: 49%;
          float: left;
          padding-left: 10px;
        }
      }
      a {
        font-family: $font-regular;
        font-size: 25px;
        line-height: 40px;
        letter-spacing: 0.24px;
        color: #121212;
        @include link-default;
        text-decoration: none;
        hyphens: auto;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        word-break: break-word;
        &:hover {
          color: $brandPurple;
          text-decoration: none;
        }
        @media(max-width: 991px) {
        font-size: 16px;
        line-height: 22px;
      }
      }

    }
  }

.sublanding-html-area{
    margin-bottom: 60px;
    @media (max-width: 992px) {
      margin-bottom: 40px;
    }

    p.ingress {
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 16px;

        @media (max-width: 991px) {
            font-size: 17px;
            line-height: 28px;
            margin-bottom: 12px;
        }
    }
}

.line-sub-landing{
    width: 100%;
    border-top: 1px solid $grey;
    margin-bottom: 80px;
    @media (max-width: 992px) {
      margin-bottom: 60px;
    }
}

.block-link-bottom-sub-landing{
    // border-top: 1px solid $grey;
    width: 100%;
    // padding-top: 60px;
    padding-bottom: 130px;
    @media (max-width: 992px) {
        // padding-top: 30px;
        padding-bottom: 75px;
    }
    a{
        font-family: $font-light;
        font-size: 25px;
        line-height: 40px;
        letter-spacing: 0.24px;
        color: $brandPurple;
        text-decoration: none;
        @include link-default;
        @media (max-width: 992px) {
        font-size: 17px;
        line-height: 28px;
        }
        &:hover{
        text-decoration: none;
        }
    }
}
