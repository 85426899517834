footer{
  background-color: $purpleDark;
  a {
    &:hover {
      font-family: $font-semiBold;
    }
  }

  .footerTop{
    padding: 60px 0 80px 0;

    @media (max-width: 992px){
      padding: 35px 0 35px 0;
    }

    &__col{
      @media (max-width: 992px){
        margin-top: 40px;
      }
      h2{
        font-family: $font-regular;
        // font-size: 25px;
        // line-height: 44px;
        font-size: 21px;
        line-height: 36px;
        letter-spacing: -0.24px;
        color: #FFFFFF;
        margin-top: 36px;
        @media (max-width: 992px){
          font-size: 16px;
          line-height: 24px;
          margin-top: 25px;
          margin-bottom: 4px;
        }
        &:first-child{
          margin-top: 0;
        }
      }

      div{
        display: block;
        &:nth-child(3){
          margin-top: 26px;
        }
        @media (max-width: 992px){
          &:nth-child(3){
            margin-top: 20px;
          }
        }
      }

      p,
      p a,
      div a,
      .link{
        // font-size: 25px;
        // line-height: 44px;
        font-size: 21px;
        line-height: 36px;
        letter-spacing: -0.24px;
        color: #FFFFFF;
        text-decoration: none;
        @media (max-width: 992px){
          font-size: 16px;
          line-height: 24px;
        }
      }

      div a,
      .link{
        font-family: $font-regular;
        border-bottom: 2px solid #fff;
        margin-top: 10px;
        display: inline-block;
        padding-right: 27px;
        @media (max-width: 992px){
          margin-top: 10px;
          padding-right: 21px;
        }
        &:hover {
          font-family: $font-semiBold;
        }
        span{
          position: relative;
          &:after{
            @include background-image("./icons/arrow-link.svg", 12, 12);
            position: absolute;
            right: -23px;
            top: 50%;
            margin-top: -5px;
            @media (max-width: 992px){
              @include background-image("./icons/arrow-link.svg", 9, 9);
              right: -17px;
              margin-top: -3px;
            }
          }
        }
      }

      &.colLink{
        @media (max-width: 992px){
          margin-top: 30px;
        }

        .link{
          margin-top: 0;
        }

        .email{
          position: relative;

          @media (max-width: 992px){
           max-width: 270px;
          }

          input{
            display: block;
            width: 100%;
            font-family: $font-regular;
            // font-size: 25px;
            // line-height: 44px;
            font-size: 21px;
            line-height: 36px;
            letter-spacing: -0.24px;
            color: #FFFFFF;
            background: none;
            border: none;
            border-bottom: 2px solid #fff;
            padding-right: 35px;
            padding-bottom: 8px;

            @media (max-width: 992px){
              font-size: 16px;
              line-height: 24px;
              padding-bottom: 6px;
            }

            &:focus{
              outline: none;
            }

            &::-webkit-input-placeholder {
              color: #ffffff;
            }

            &:-ms-input-placeholder {
              color: #ffffff;
            }

            &::placeholder {
              color: #ffffff;
            }
          }

          .button {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 44px;
            width: 35px;
            position: absolute;
            top: 0;
            right: 0;

            @media (max-width: 992px){
              height: 30px;
            }

            button{
              display: block;
              background: none;
              border: none;
              @include background-image("./icons/arrow-email.svg", 21, 18);

              @media (max-width: 992px){
                @include background-image("./icons/arrow-email.svg", 15, 13);
                margin-bottom: 5px;
              }

              &:focus{
                outline: none;
              }
            }
          }

          .error-message{
            display: none;
            margin-top: 10px;
            color: $red;
          }

          &.error{
            input{
              color: $red;
              border-color: $red;
            }

            .error-message{
              display: block;
            }
          }
        }
      }

      .terms{
        margin-top: 34px;
        margin-bottom: 40px;
        @media (max-width: 992px){
          margin-top: 25px;
           margin-bottom: 0;
        }
        input{
          width: 18px;
          height: 18px;
          cursor: pointer;
        }

        input[type=checkbox]+label:before,
        input[type=checkbox]+label:after,
        input[type=checkbox]+.labelText:before,
        input[type=checkbox]+.labelText:after {
          width: 18px;
          height: 18px;
          border-radius: 0;
        }
        input[type=checkbox]+label:before,
        input[type=checkbox]+.labelText:before{
          opacity: 1!important;
          background-color: transparent;
          border: 1px solid #ffffff;
        }
        input[type=checkbox]+label:after,
        input[type=checkbox]+.labelText:after {
          transform: scale(.6);
          background-image: url(../images/icons/check-light.svg);
        }
        label, label p, label span, .labelText, .labelText p{
          pointer-events: none;
          font-size: 18px;
          line-height: 22px;
          padding-left: 0;
          text-indent: 30px;
          letter-spacing: -0.24px;
          text-decoration: none;
          @media (max-width: 992px){
            display: block;
            font-size: 16px;
            line-height: 22px;
            margin-top: 0px;
            margin-bottom: 4px;
          }

          a {
            pointer-events: all;
            margin: 0;
            display: inline;
            padding: 0;
            margin-top: 0;
            font-size: 18px;
            color: inherit;
            line-height: inherit;
            letter-spacing: inherit;

            @media (max-width: 992px){
                font-size: 16px;
            }

            span {
                display: none;
            }
          }
        }

        &.invalid {
          label, label p, .labelText, .labelText p {
            color: $red;
          }
        }
      }
    }
  }

  .footerBottom{
    padding: 60px 0 80px 0;

    @media (max-width: 992px){
      padding: 0 0 120px 0;
    }

    .row{
      &.hideMobile{
        @media (min-width: 993px){
          display: flex;
        }
      }
    }

    &__col{
      h2{
        font-family: $font-regular;
        // font-size: 16px;
        // line-height: 32px;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 4px;

        letter-spacing: 0.24px;
        color: #FFFFFF;
      }

      p{
        font-family: $font-light;
        // font-size: 16px;
        // line-height: 32px;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.24px;
        color: #FFFFFF;
      }
    }

    &__linksWrapper{
      margin-top: 28px;

      @media (max-width: 992px){
        display: flex;
        flex-wrap: wrap;
        gap: 20px 30px;
        margin-top: 0px;
      }

      a{
        text-decoration: none;
        font-family: $font-light;
        // font-size: 16px;
        // line-height: 32px;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.24px;
        color: #FFFFFF;
        border-bottom: 1px solid #fff;
        display: inline-block;
        margin-right: 40px;
        &:last-child{
          margin: 0;
        }
        &:hover {
          font-family: $font-regular;
        }

        @media (max-width: 992px){
          margin: 0px;
        }
      }
    }

    &__logo{
      width: 62px;
      float: right;
      margin-top: 20px;
    }

  }

  .showMobile{
    display: none;
    @media (max-width: 992px){
      display: block;
    }
  }

  .hideMobile{
    display: block;
    @media (max-width: 992px){
      display: none;
    }
  }



}
